/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import { merge, codeSortHelper } from "../../utils/functions"

import { Card, Grid, Button, makeStyles, Modal, Fade, TextField } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"
import EditIcon from "@material-ui/icons/Edit"

import * as userInfoActions from "../../actions/userInfoActions"
import _ from "lodash"

const ItGroupSummary = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const classes = useStyles()
  const userType = useSelector((state) => _.get(state, "userInfo.settings.user.userType", ""))
  const itCodeGroups = useSelector((state) => state.userInfo.settings.itCodeGroups)
  const isFetching = useSelector((state) => _.get(state, "userInfo.isFetching", false))
  const [selectedItCode, setSelectedItCode] = React.useState()
  const [open, setOpen] = React.useState(false)
  const [itGroupName, setItGroupName] = React.useState("")
  const [data, setData] = React.useState()
  React.useEffect(() => {
    setData(props.selectedRow
      ? [
          ...props.selectedRow.itCodes.allowed.map((rowData) => ({
            ...rowData,
            tableData: {
              checked: true
            }
          })),
          ...props.selectedRow.itCodes.denied.map((rowData) => ({
            ...rowData,
            tableData: {
              checked: false
            }
          }))
      ].sort(codeSortHelper)
      : [])
  }, [props.selectedRow])
  
  function updateItCodes() {
    const itCodes = data.reduce((acc, e) => {
      if (e.tableData.checked === true) {        
        return acc.concat(e.code);        
      }
      return acc;      
    }, [])

    dispatch(userInfoActions.setItGroup({ token: auth.token }, props.itGroups[props.selectedRow.tableData.id], itCodes))
  }

  function checkRow(rowData) {
    if (rowData.length === 0) {
      setData(data.map(e => ({...e, tableData: {...e.tableData, checked: false}})))
    } else {
      setData(merge(data, rowData, (d, r) => d.code === r.code))
    }
  }

  function onDisableGroupButton() {
    dispatch(userInfoActions.disableItGroup({ token: auth.token }, itCodeGroups[props.selectedRow.tableData.id]))
  }

  function onEnableGroupButton() {
    dispatch(userInfoActions.enableItGroup({ token: auth.token }, itCodeGroups[props.selectedRow.tableData.id]))
  }

  function handleClose() {
    setOpen(false)
  }

  function buttonsDisabled() {
    if (itCodeGroups && itCodeGroups.length > 0) {
      return false
    }

    return true
  }

  function updateItGroupName() {
    const itCodes = data.reduce((acc, e) => {
      if (e.tableData.checked === true) {        
        return acc.concat(e.code);        
      }
      return acc;      
    }, [])

    let itGroup = {
      _id: itCodeGroups[props.selectedRow.tableData.id]._id,
      name: itGroupName,
      itCodes: itCodes,
      active: itCodeGroups[props.selectedRow.tableData.id].active,
    }

    dispatch(userInfoActions.setItGroup({ token: auth.token }, itGroup, itCodes))
    setOpen(false)
    setItGroupName("")
  }

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Change IT Group Name</h2>
            <p id="transition-modal-description">
              <TextField
                onChange={(e) => setItGroupName(e.target.value)}
                value={itGroupName}
                label={"It Group Name"}
                variant="outlined"
                margin="dense"
                fullWidth
              ></TextField>
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                style={{ margin: "5px" }}
                onClick={updateItGroupName}
              >
                Change
              </Button>
              <Button variant="contained" size="medium" color="primary" onClick={handleClose} style={{ margin: "5px" }}>
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Grid item xs={7} lg={5}>
        <Card className={classes.summaryCardActive}>
          {props.itGroups && props.itGroups && props.selectedRow && itCodeGroups && itCodeGroups.length > 0 && (
            <MaterialTable
              icons={tableIcons}
              title="IT Codes"
              isLoading={isFetching}
              columns={[
                { title: "Description", field: "name" },
                // { title: "active", field: "active" }
                // { title: "ID", field: "id", hidden: "true" }
                /* { title: 'Allowed', field: 'allowed' , type: 'boolean'}, */
              ]}
              options={{
                minBodyHeight: "calc(100vh - 224px)",
                maxBodyHeight: "calc(100vh - 224px)",
                pageSize: 10,
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedItCode && selectedItCode.tableData.id === rowData.tableData.id ? "#EEE" : "#FFF",
                }),
                selection: true,
              }}
              data={data ? data : []}
              onRowClick={(event, rowData) => {
                setSelectedItCode(rowData)
              }}
              onSelectionChange={(rowData) => {
                checkRow(rowData)
              }}
            />
          )}
        </Card>
        <Grid container direction="row" justify="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
          <Card style={{ width: "100%", height: "100%" }} className={classes.actionButtonsCard}>
            <Grid container direction="row" justify="space-between" alignItems="center">
              {props.selectedRow &&
                (userType == "SERVICE_MANAGER" || userType == "ADMIN") &&
                itCodeGroups[props.selectedRow.tableData.id] &&
                itCodeGroups[props.selectedRow.tableData.id].active && (
                  <Grid item xs={4} style={{ textAlign: "left", paddingLeft: 15 }}>
                    {props.selectedRow && (
                      <Button color="primary" onClick={onDisableGroupButton}>
                        <LockOutlinedIcon
                          style={{
                            marginRight: 5,
                            fontSize: 20,
                            marginBottom: 2,
                          }}
                        ></LockOutlinedIcon>
                        Disable IT Group
                      </Button>
                    )}
                  </Grid>
                )}
              {props.selectedRow &&
                (userType == "SERVICE_MANAGER" || userType == "ADMIN") &&
                itCodeGroups[props.selectedRow.tableData.id] &&
                !itCodeGroups[props.selectedRow.tableData.id].active && (
                  <Grid item xs={6} style={{ textAlign: "left", paddingLeft: 15 }}>
                    {props.selectedRow && (
                      <Button color="primary" onClick={onEnableGroupButton}>
                        <CheckOutlinedIcon
                          style={{
                            marginRight: 5,
                            fontSize: 20,
                            marginBottom: 2,
                          }}
                        ></CheckOutlinedIcon>
                        Enable IT Group
                      </Button>
                    )}
                  </Grid>
                )}
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {props.selectedRow && (userType == "SERVICE_MANAGER" || userType == "ADMIN") && (
                  <Button color="primary" onClick={() => setOpen(true)} disabled={buttonsDisabled()}>
                    <EditIcon
                      style={{
                        marginRight: 10,
                        fontSize: 20,
                        marginBottom: 2,
                      }}
                    ></EditIcon>
                    EDIT GROUP NAME
                  </Button>
                )}
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {props.selectedRow && (userType == "SERVICE_MANAGER" || userType == "ADMIN") && (
                  <Button color="primary" onClick={updateItCodes} disabled={buttonsDisabled()}>
                    Update IT Codes
                    <ArrowForwardIosIcon style={{ marginLeft: 5, fontSize: 20, marginBottom: 2 }}></ArrowForwardIosIcon>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden",
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px",
    },
  },
  actionButtonsCard: {
    paddingTop: 7,
    minHeight: 51,
    height: "100%",
    width: "100%",
    textAlign: "right",
  },
  summaryCard: {
    height: "calc(100vh - 106px)",
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)",
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: "25%",
    minHeight: "25%",
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiTextField-root": {
      margin: "0px",
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& .MuiInputBase-root": {
      marginTop: "12px",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
}))

export default ItGroupSummary
