/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"
import GoogleMap, { fitBounds } from 'google-map-react';
import Marker from './MapMarker';

import { locationLogic } from "../../businesslogic"
import _ from 'lodash';



export default function GoogleMapBox(props) {
  const dispatch = useDispatch()
  const [allowedFavourites, setAllowedFavourites] = React.useState([])
  const [allowedLocations, setAllowedLocations] = React.useState([])
  const quotation = useSelector((state) => state.quotation)
  const locations = useSelector((state) => state.locations.locations)
  const favouriteLocations = useSelector((state) => state.userInfo.settings.user.favouriteLocations)
  const userAllowedLocations = useSelector((state) => state.userInfo.settings.user.allowedLocations)
  const disallowedLocations = useSelector((state) => _.get(_.find(state.userInfo.settings.meta, { "key": "disallowedLocations" }), "value", []))
  const locationSearchResult = useSelector((state) => state.locations.data)
  const favouriteLocationList = useSelector((state) => state.userInfo.settings.user.favouriteLocations)
  const allowOneWay = useSelector((state) => _.get(_.find(state.userInfo.settings.meta, { "key": "allowOneWay" }), "value", false))
  const defaultZoom = 12
  const defaultCenterZoomObj = { center: { lat: -37.831197768654874, lng: 144.9589691110972 }, zoom: defaultZoom }

  const ref = useRef(null)
  const [mapCenterZoom, setMapCenterZoom] = React.useState(defaultCenterZoomObj)

  const [mapSize, setMapSize] = React.useState({ width: 0, height: 0 })



  useEffect(() => {
    if (props.viewingLocation) {
      const newCenterZoomObj = { center: { lat: props.viewingLocation.latitude, lng: props.viewingLocation.longitude }, zoom: defaultZoom }
      setMapCenterZoom(newCenterZoomObj)
    } else if (props.showFavoriteLocations) {
      calcFavLocationsBounds()
    } else {
      calcSearchLocationsBounds()
    }

  }, [props.viewingLocation])

  useEffect(() => {

    if (!props.showFavoriteLocations) return

    favouriteLocationList
      ? setAllowedFavourites(
        locationLogic.favouriteLocations(locations, favouriteLocations, userAllowedLocations, disallowedLocations)
      )
      : null

    calcFavLocationsBounds()

  }, [favouriteLocationList, props.showFavoriteLocations, mapSize])

  useEffect(() => {

    if (props.showFavoriteLocations) return

    locationSearchResult
      ? setAllowedLocations(
        locationLogic.tenClosestLocations(locationSearchResult, userAllowedLocations, disallowedLocations, props.excludeAptLocations)
      )
      : setAllowedLocations([])

    calcSearchLocationsBounds()

  }, [locationSearchResult, props.showFavoriteLocations, mapSize, props.excludeAptLocations])


  const calcFavLocationsBounds = () => {
    const newAllowedFavorites = locationLogic.favouriteLocations(locations, favouriteLocations, userAllowedLocations, disallowedLocations)
    if (_.size(newAllowedFavorites) > 1) {
      const bounds = {
        nw: {
          lat: _.max(newAllowedFavorites.map((l) => l.latitude)),
          lng: _.min(newAllowedFavorites.map((l) => l.longitude))
        },
        se: {
          lat: _.min(newAllowedFavorites.map((l) => l.latitude)),
          lng: _.max(newAllowedFavorites.map((l) => l.longitude))
        }
      };

      const { center, zoom } = fitBounds(bounds, mapSize)
      let newCenterZoomObj = { center, zoom }
      Object.assign(newCenterZoomObj, fitBounds(bounds, mapSize))

      setMapCenterZoom(newCenterZoomObj)
    } else if (_.size(newAllowedFavorites) == 1) {
      const newCenterZoomObj = { center: { lat: newAllowedFavorites[0].latitude, lng: newAllowedFavorites[0].longitude }, zoom: defaultZoom }
      setMapCenterZoom(newCenterZoomObj)
    } else {
      setMapCenterZoom(defaultCenterZoomObj)
    }
  }

  const calcSearchLocationsBounds = () => {
    const newAllowedLocations = locationLogic.tenClosestLocations(locationSearchResult, userAllowedLocations, disallowedLocations, props.excludeAptLocations)

    if (!_.isEmpty(newAllowedLocations)) {
      const bounds = {
        nw: {
          lat: _.max(newAllowedLocations.map((l) => l.latitude)),
          lng: _.min(newAllowedLocations.map((l) => l.longitude))
        },
        se: {
          lat: _.min(newAllowedLocations.map((l) => l.latitude)),
          lng: _.max(newAllowedLocations.map((l) => l.longitude))
        }
      };

      const { center, zoom } = fitBounds(bounds, mapSize)
      let newCenterZoomObj = { center, zoom }
      Object.assign(newCenterZoomObj, fitBounds(bounds, mapSize))

      setMapCenterZoom(newCenterZoomObj)
    } else {
      setMapCenterZoom(defaultCenterZoomObj)
    }
  }

  function onSelectLocation(e) {
    if (allowOneWay) {
      dispatch(
        bookingsActions.setQuotation({
          pickupLocation: quotation.pickUpSearch ? e.currentTarget.value : quotation.pickupLocation,
          dropoffLocation: quotation.dropOffSearch ? e.currentTarget.value : quotation.dropoffLocation,
          pickUpSearch: false,
          dropOffSearch: false,
        })
      )
    } else {
      dispatch(
        bookingsActions.setQuotation({
          pickupLocation: e.currentTarget.value,
          dropoffLocation: e.currentTarget.value,
          pickUpSearch: false,
          dropOffSearch: false,
        })
      )
    }

    // eslint-disable-next-line react/prop-types
    props.setShowSearchLocation(false)
    // dispatch(locationActions.clearLocations())
  }

  const onBoundsChange = ({ center }) => {
    setMapCenterZoom({ ...mapCenterZoom, center })
  }

  //console.log("bounds here mapCenterZoom", mapCenterZoom)


  const _distanceToMouse = (markerPos, mousePos, markerProps) => {
    const x = markerPos.x;
    const y = markerPos.y - 30;
    const distanceKoef = 1;
    return distanceKoef * Math.sqrt((x - mousePos.x) * (x - mousePos.x) + (y - mousePos.y) * (y - mousePos.y));
  }


  const Markers = props.showFavoriteLocations ?
    allowedFavourites
      .map((location, index) => (
        <Marker
          // required propsss
          id={`marker-${index}`}
          lat={location.latitude}
          lng={location.longitude}
          key={location.locationCode}
          zIndex={1}
          location={location}
          onSelectLocation={onSelectLocation}
          viewingLocation={props.viewingLocation}
          setViewingLocation={props.setViewingLocation}
          setHighlightedLocation={props.setHighlightedLocation}
          highlightedLocation={props.highlightedLocation}
          lowerThanCenter={+location.latitude > +mapCenterZoom.center.lat}

        // any user props
        />
      )) : allowedLocations
        .map((location, index) => (
          <Marker
            // required props
            id={`marker-${index}`}
            lat={location.latitude}
            lng={location.longitude}
            key={location.locationCode}
            zIndex={1}
            location={location}
            onSelectLocation={onSelectLocation}
            viewingLocation={props.viewingLocation}
            setViewingLocation={props.setViewingLocation}
            setHighlightedLocation={props.setHighlightedLocation}
            highlightedLocation={props.highlightedLocation}
            lowerThanCenter={+location.latitude > +mapCenterZoom.center.lat}
            style={{
              position: "absolute",
              left: "-75px",
              top: "-150px"
            }}
          />
        ))

  return (
    <div ref={ref} id='google-map-box' style={{ height: '100%', width: '100%' }}>
      <GoogleMap
        center={[mapCenterZoom.center.lat, mapCenterZoom.center.lng]}
        zoom={mapCenterZoom.zoom}
        id='google-map-box2'
        bootstrapURLKeys={{ key: "AIzaSyCWBv9PQTn_fb3qCJteZ3SkfbGgtn5_-Ho" }}
        style={{ height: "100%", width: "100%" }}
        onGoogleApiLoaded={({ map, maps }) => {
          setMapSize({ width: ref.current.clientWidth - 50, height: ref.current.clientHeight - 50 })
        }}
        hoverDistance={30}
        distanceToMouse={_distanceToMouse}
        onChange={onBoundsChange}
      >
        {/* <Pin
          lat={mapCenterZoom.center.lat}
          lng={mapCenterZoom.center.lng}
          text={'address'}
        /> */}
        {Markers}
      </GoogleMap>
    </div>
  )
}