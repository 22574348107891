import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function quotationRducer(
  state = initialState.quotation,
  action
) {
  switch (action.type) {
    case types.SET_QUOTATION:
      return {
        ...state,
        ...action.payload,
        cleared: false
      }
    case types.CLEAR_QUOTATION:
      // eslint-disable-next-line no-case-declarations
      const quotation = {
        ...state,
        ...initialState.quotation,
        claimnumber: "",
        policynumber: ""
      }
      delete quotation.customerAcrissCode
      delete quotation.customerAcrissCategory
      delete quotation.customerAcrissType
      delete quotation.customerAcrissTransmission
      delete quotation.customerAcrissFueldAndAir
      delete quotation.insurerAcrissCode
      delete quotation.insurerAcrissCategory
      delete quotation.insurerAcrissType
      delete quotation.insurerAcrissTransmission
      delete quotation.insurerAcrissFueldAndAir
      return {
        ...quotation
      }
    case types.LOGOUT_REQUEST:
      return {
        ...initialState.quotation
      }
    default:
      return state
  }
}
