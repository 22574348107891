import {acrissCategories, acrissTypes, acrissTransmision, acrissFuelAndAir} from '../constants/ACRISS'

const _ = require('lodash');


export function capitaliseWord(ReservationStatus) {
    ReservationStatus = ReservationStatus.slice(0,1).toUpperCase() + ReservationStatus.slice(1, ReservationStatus.length)

    return ReservationStatus
}

export function metaConverter(objects) {
  return objects.map(e => {
    const metaObject = e.meta === null || e.meta === undefined ? {} : Object.fromEntries(e.meta.map(m => [m.key, m.value]))
    return {
        code: e.code, 
        name: e.name,  
        ...metaObject
    }
})
}

export function acrissDecoder(acrissCode) {
    if(acrissCode){
        let acrissArray = [...acrissCode]
        let acrissObject = {
            Category: _.filter(acrissCategories, {Code: acrissArray[0]})[0],
            Type: _.filter(acrissTypes, {Code: acrissArray[1]})[0],
            Transmission: _.filter(acrissTransmision, {Code: acrissArray[2]})[0],
            FuelAndAir: _.filter(acrissFuelAndAir, {Code: acrissArray[3]})[0]
        }

        return acrissObject
    }else{
        return null
    }
}

export const converter = (data) => {
    if (!_.isObject(data)) {
      return data
    }
    const result = _.mapValues(data, (d, key) => {
      if (key === "meta" && d != null) {
        const newArrValue = _.map(d, (d2) => {
          const newValue = _.mapValues(d2, (data2, k2) => {
            return k2 === "value" ? JSON.parse(data2) : data2
          })
          return newValue
        })
        return newArrValue
      } else if (_.isArray(d)) {
        const newArrValue = _.map(d, (d2) => {
          return converter(d2)
        })
        return newArrValue
      }
      else {
        return converter(d)
      }
    })
    return result
}

export function merge(a, b, predicate = (a, b) => a === b) {
  const c = [...a]; // copy to avoid side effects
  // add all items from B to copy C if they're not already present
  b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
  return c;
}

export function codeSortHelper(a, b) {
  if(a.code < b.code) {
    return -1
  }
  if(a.code > b.code) {
    return 1
  }
  return 0
}
