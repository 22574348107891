/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography, Tabs, Tab, Grid, Box, Button, Breadcrumbs, Link, MenuItem } from "@material-ui/core"
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'
import Collapse from '@mui/material/Collapse';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Switch from '@mui/material/Switch';
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import StarIcon from "@material-ui/icons/Star"
import StarBorderIcon from "@material-ui/icons/StarBorder"
import CircularProgress from "@material-ui/core/CircularProgress"
import * as locationActions from "../../actions/locationsActions"
import * as bookingsActions from "../../actions/bookingsActions"
import * as userInfoActions from "../../actions/userInfoActions"
import * as userActions from "../../actions/userActions"
import { locationLogic } from "../../businesslogic"
import { styled } from '@mui/material/styles'
import { makeStyles } from "@material-ui/core/styles"
import _ from "lodash"

import {
  AU_SEARCHADDRESS,
  NZ_SEARCHADDRESS,
} from "../../constants/locations";

import {
  DataGrid,
  useGridApiRef,
  GridActionsCellItem,
  gridClasses
} from "@mui/x-data-grid";

function a11yProps(index) {
  return {
    id: `tab-${index}`,
  }
}

function TabPanel(props) {
  // eslint-disable-next-line react/prop-types
  const { children, value, index, ...other } = props

  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      <Box p={0}>{children}</Box>
    </Typography>
  )
}


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.highlighted`]: {
    backgroundColor: 'lightGrey',
    '&:hover': {
      backgroundColor: 'lightGrey',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.row}.viewing`]: {
    backgroundColor: 'lightBlue',
    '&:hover': {
      backgroundColor: 'lightBlue',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  }
}));

const useStyles = makeStyles({
  groupBtnSelected: {
    backgroundColor: "#fff2bf !important",
    borderColor: "lightGrey",
    width: "50%",
    marginTop: "8px",
    height: "40px"
  },
  groupBtnUnSelected: {
    width: "50%",
    marginTop: "8px",
    height: "40px"
  },
  input: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        borderColor: 'black',
      },
    },
  },
  switch: {
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: "#ffcc00",

    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: "#ffcc00",
    },
  }

})



const LocationSearch = (props) => {
  const locations = useSelector((state) => state.locations.locations)
  const suggestedLocations = useSelector((state) => state.locations.addressSuggestions)
  const locationSearchResult = useSelector((state) => state.locations.data)
  const favouriteLocationList = useSelector((state) => state.userInfo.settings.user.favouriteLocations)
  const [allowedFavourites, setAllowedFavourites] = React.useState([])
  const [allowedLocations, setAllowedLocations] = React.useState([])
  // const allowOneWay = useSelector((state) => _.get(_.find(state.userInfo.settings.meta, { "key": "allowOneWay" }), "value", false))
  const quotation = useSelector((state) => state.quotation)
  const auth = useSelector((state) => state.auth)
  const [selectLocationTab, setSelectLocationTab] = useState(0)
  const [street, setStreet] = useState("")
  const [suburb, setSuburb] = useState("")
  const [state, setState] = useState("")
  const [country, setCountry] = useState("AU")
  const [postcode, setPostcode] = useState("")
  const [postcodeSearch, setPostcodeSearch] = useState(false)
  const [stateFieldError, setStateFieldError] = useState(false)
  const dispatch = useDispatch()
  const favouriteLocations = useSelector((state) => state.userInfo.settings.user.favouriteLocations)
  const userAllowedLocations = useSelector((state) => state.userInfo.settings.user.allowedLocations)
  const disallowedLocations = useSelector((state) => _.get(_.find(state.userInfo.settings.meta, { "key": "disallowedLocations" }), "value", []))
  const searching = useSelector((state) => state.locations.isFetching)
  const currentUser = useSelector((state) => state.userInfo.settings.user)

  const [suggestions, setSuggestions] = useState([]);
  const [inputValueDownShift, setInputValueDownShift] = useState("");


  const classes = useStyles()


  useEffect(() => {
    setAllowedFavourites(
      locationLogic.favouriteLocations(locations, favouriteLocations, userAllowedLocations, disallowedLocations)
    )
  }, [favouriteLocationList])

  useEffect(() => {
    setAllowedLocations(
      locationLogic.tenClosestLocations(locationSearchResult, userAllowedLocations, disallowedLocations, props.excludeAptLocations)
    )
  }, [locationSearchResult, selectLocationTab, props.excludeAptLocations])

  useEffect(() => {
    setSuggestions(suggestedLocations);
  }, [suggestedLocations]);

  const handleClick = (e) => {
    e.preventDefault()
    props.setShowSearchLocation(false)
  }

  const handleSelectLocationTabChange = (e, newValue) => {
    e.preventDefault()
    setSelectLocationTab(newValue)
    props.setShowFavoriteLocations(newValue == 0)
  }

  function searchLocations(e) {
    if (postcodeSearch && state == "") {
      setStateFieldError(true)
      e.preventDefault()

    } else {
      e.preventDefault()

      let locationObject = {
        street: postcodeSearch ? '' : street,
        suburb: postcodeSearch ? '' : suburb,
        state: state,
        country: country,
        postcode: postcode,
        token: auth.token,
      }
      dispatch(locationActions.getLocations(locationObject))
    }
  }





  const handleRowHovered = (event) => {
    const rowId = event.currentTarget?.dataset?.id;
    props.setHighlightedLocation(_.find(locations, { 'locationCode': rowId }))
    console.log('_.find(locations{locationCoderowId})', _.find(locations, { 'locationCode': rowId }))
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );

  };

  const handleRowLeaved = (event) => {

    const rowId = event.currentTarget?.dataset?.id;
    props.setHighlightedLocation(null)
    document.dispatchEvent(
      new CustomEvent(`row${rowId}HoverChange`, { detail: { hovered: true } })
    );

  };

  function onLocationClick(selectedRow) {
    props.setViewingLocation(selectedRow)
    return
    // if (!allowOneWay) {
    //   dispatch(
    //     bookingsActions.setQuotation({
    //       pickupLocation: selectedRow.locationCode,
    //       dropoffLocation: selectedRow.locationCode,
    //       pickUpSearch: false,
    //       dropOffSearch: false,
    //     })
    //   )
    // } else {
    //   dispatch(
    //     bookingsActions.setQuotation({
    //       pickupLocation: quotation.pickUpSearch ? selectedRow.locationCode : quotation.pickupLocation,
    //       dropoffLocation: quotation.pickUpSearch
    //         ? selectedRow.locationCode
    //         : quotation.dropOffSearch
    //           ? selectedRow.locationCode
    //           : quotation.dropoffLocation,
    //       pickUpSearch: false,
    //       dropOffSearch: false,
    //     })
    //   )
    // }

    // dispatch(locationActions.clearLocations())

    // props.setShowSearchLocation(false)
  }

  function getFavourites(lCode) {
    if (favouriteLocationList.includes(lCode)) {
      return <StarIcon style={{ margin: 0, padding: 0, color: "#ffcc00" }} />
    }

    return <StarBorderIcon style={{ margin: 0, padding: 0, color: "#ffcc00" }} />
  }

  function handleOpen(rowData) {
    console.log('handleOpen(rowData) ', rowData)
    if (favouriteLocationList.includes(rowData.locationCode)) {
      dispatch(
        userInfoActions.setFavouriteLocation(
          { token: auth.token },
          _.difference(favouriteLocationList, [rowData.locationCode])
        )
      )
    } else {
      dispatch(
        userInfoActions.setFavouriteLocation(
          { token: auth.token },
          _.concat(favouriteLocationList, [rowData.locationCode])
        )
      )
    }
  }

  function handleClickFavBtn(rowData) {
    if (favouriteLocationList.includes(rowData.locationCode)) {
      dispatch(
        userActions.setUser(
          {
            token: auth.token
          },
          {
            ...currentUser,
            favouriteLocations: _.difference(favouriteLocationList, [rowData.locationCode])
          }
        )
      )
    } else {
      dispatch(
        userActions.setUser(
          {
            token: auth.token
          },
          {
            ...currentUser,
            favouriteLocations: _.concat(favouriteLocationList, [rowData.locationCode])
          }
        )
      )
    }
  }

  const handleAddressChange = (e) => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    // if(e?.type == 'change'){
    //   setStreet("")
    //   setSuburb("")
    //   setPostcode("")
    //   setState("")
    // }

    if (e?.target?.value?.length > 4 && e?.target?.value?.length % 4 == 0) {
      dispatch(
        locationActions.searchAddress({
          token: auth.token,
          address: e.target.value,
          countryCode: country,
          method: country == "NZ" ? NZ_SEARCHADDRESS : AU_SEARCHADDRESS,
        })
      );
    }
  };

  const selectedAddress = (data) => {
    console.log('selectedAddress data', data)
    setStreet(data.address.AddressLine)
    setInputValueDownShift(data.address.AddressLine)
    setSuburb(data.address.Locality)
    setPostcode(data.address.Postcode)
    setState(data.address.State)
    return
  };

  return (
    <div
      style={{
        width: "100%",
        display: props.showSearchLocation ? "block" : "none",
      }}
    >
      <Tabs
        value={selectLocationTab}
        onChange={handleSelectLocationTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
      >
        <Tab label="Favorites" {...a11yProps(0)} />
        <Tab label="Search" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={selectLocationTab} index={0} >
        <StyledDataGrid
          ref={useGridApiRef()}
          columns={[
            { title: "Location Name", field: "locationName", flex: 3, },
            { title: "Location Code", field: "locationCode", flex: 1, },
            {
              headerName: "Actions",
              field: "actions",
              getActions: (params) => [
                <GridActionsCellItem
                  icon={getFavourites(params.row.locationCode)}
                  label="Favourite Location"
                  description="Favourite Location"
                  className="textPrimary"
                  tooltip="Favourite Location"
                  onClick={() => handleClickFavBtn(params.row)}
                  color="inherit"
                  key={params.row.locationCode}
                />
              ],
              flex: 1,
              type: "actions",
              align: "center",
              headerAlign: "center",
              minWidth: 200
            },
          ]}
          rows={allowedFavourites.map((x) => Object.assign({
            ...x,
            id: x.locationCode
          }, x))}
          // actions={[
          //   (rowData) => ({
          //     icon: () => getFavourites(rowData.locationCode),
          //     tooltip: <Typography>Favourite Location</Typography>,
          //     onClick: (event) => handleOpen(rowData, event),
          //   }),
          // ]}
          getRowClassName={(params) => {
            return params.row.locationCode == props.viewingLocation?.locationCode ?
              'viewing' : params.row.locationCode == props.highlightedLocation?.locationCode ?
                'highlighted' : ''
          }}
          slotProps={{
            row: {
              onMouseEnter: handleRowHovered,
              onMouseLeave: handleRowLeaved
            }
          }}
          icons={tableIcons}
          onRowClick={(selectedRow, event) => {
            onLocationClick(selectedRow.row)
          }}
          style={{ marginTop: "20px", minHeight: "60vh" }}
        />

      </TabPanel>
      <TabPanel value={selectLocationTab} index={1}>
        <form>
          <Grid container item spacing={1} justify="center" xs={12} style={{ margin: "20px 0px" }}>
            <Grid item xs={6}>
              <ToggleButtonGroup
                value={country}
                exclusive
                style={{ width: "100%", marginTop: "8px" }}
                onChange={(e) => setCountry(e.target.value)}
              >
                <ToggleButton className={country == "AU" ? classes.groupBtnSelected : classes.groupBtnUnSelected} value="AU">Australia</ToggleButton>
                <ToggleButton className={country == "NZ" ? classes.groupBtnSelected : classes.groupBtnUnSelected} value="NZ">New Zealand</ToggleButton>
              </ToggleButtonGroup>
            </Grid>
            <Grid item xs={6}>
              <Typography style={{ marginTop: "1.5rem", fontSize: "0.8rem" }}>
                * Country For more accurate search results
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Collapse in={!postcodeSearch}>
                <Autocomplete
                  freeSolo
                  size="small"
                  filterOptions={(options, state) => {
                    const displayOptions = options.filter((option) =>
                      option.label
                        .toLowerCase()
                        .trim()
                        .includes(state.inputValue.toLowerCase().trim()),
                    );

                    return displayOptions;
                  }}
                  disableClearable
                  options={suggestions}
                  inputValue={inputValueDownShift}
                  getOptionLabel={(option) =>
                    _.isObject(option) ? option.label : option ? option : ""
                  }
                  renderInput={(params) => (
                    <TextField
                      label="Street"
                      margin="dense"
                      variant="outlined"
                      size="small"
                      fullWidth
                      className={classes.input}
                      {...params}
                      // onChange={(e) => setStreet(e.target.value)}
                      InputLabelProps={{
                        autoComplete: "new-password",
                      }}
                    />
                  )}
                  onInputChange={(event, value) => {

                    console.log('onInputChange value', value, event)
                    handleAddressChange(event);
                    // console.log(_.get(value, "target.value", ""));
                    console.log(value);
                    if (event?.type == "click" || event == null) {
                      setInputValueDownShift(street);
                      return
                    }
                    setInputValueDownShift(value);

                  }}
                  onChange={(_, data) => {
                    selectedAddress(data);
                  }}
                />
                {/* <TextField
                  label="Street"
                  margin="dense"
                  variant="outlined"
                  value={street}
                  size="small"
                  fullWidth
                  className={classes.input}
                  // onChange={(e) => setStreet(e.target.value)}
                  onChange={(e) => handleAddressChange(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                /> */}
              </Collapse>
            </Grid>
            <Grid item xs={4}>
              <Collapse in={!postcodeSearch}>
                <TextField
                  label="Suburb"
                  margin="dense"
                  variant="outlined"
                  size="small"
                  className={classes.input}
                  value={suburb}
                  fullWidth
                  onChange={(e) => setSuburb(e.target.value)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  autoComplete="off"
                />
              </Collapse>

            </Grid>

            <Grid item xs={4}>
              <TextField

                label="Postcode"
                margin="dense"
                variant="outlined"
                value={postcode}
                size="small"
                className={classes.input}
                fullWidth
                onChange={(e) => setPostcode(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label={stateFieldError ? "Please Select State" : "State"}
                error={stateFieldError}
                margin="dense"
                variant="outlined"
                value={state}
                size="small"
                className={classes.input}
                select
                fullWidth
                onChange={(e) => { setState(e.target.value), setStateFieldError(false) }}
                InputLabelProps={{
                  shrink: true,
                }}
              >
                {/* <MenuItem key={0} value="">
                  
                </MenuItem> */}
                <MenuItem key={1} value="ACT">
                  ACT
                </MenuItem>
                <MenuItem key={2} value="NT">
                  NT
                </MenuItem>
                <MenuItem key={3} value="NSW">
                  NSW
                </MenuItem>
                <MenuItem key={4} value="QLD">
                  QLD
                </MenuItem>
                <MenuItem key={5} value="SA">
                  SA
                </MenuItem>
                <MenuItem key={6} value="TAS">
                  TAS
                </MenuItem>
                <MenuItem key={7} value="VIC">
                  VIC
                </MenuItem>
                <MenuItem key={8} value="WA">
                  WA
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch className={classes.switch} checked={postcodeSearch} onChange={() => { setPostcodeSearch(!postcodeSearch), setStateFieldError(false) }} />
                }
                label={`Postal Code Search ${postcodeSearch ? "On" : "Off"} `}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch className={classes.switch} checked={props.excludeAptLocations} onChange={() => { props.setExcludeAptLocations(!props.excludeAptLocations) }} />
                }
                label={`Exclude Airport Locations`}
              />
            </Grid>
            <Grid item xs={8}>
              <Button
                color="secondary"
                type="submit"
                fullWidth
                size="large"
                variant="contained"
                disabled={searching}
                onClick={(e) => searchLocations(e)}
                style={{ marginTop: 10 }}
              >
                {searching ? (
                  <React.Fragment>
                    <CircularProgress size={14} style={{ marginRight: 10 }} /> Searching Locations
                  </React.Fragment>
                ) : (
                  <React.Fragment>Search Locations</React.Fragment>
                )}
              </Button>
              <Typography
                style={{
                  textAlign: "center",
                  marginTop: 5,
                  fontSize: 12,
                  color: "grey",
                }}
              >
                * Locations ordered from closest to furthest
              </Typography>
            </Grid>
          </Grid>
        </form>
        {!allowedLocations && allowedFavourites && (
          <div>
            <MaterialTable
              icons={tableIcons}
              columns={[
                { title: "Location Code", field: "locationCode" },
                { title: "Location Name", field: "locationName" },
              ]}
              data={allowedFavourites.map((x) => Object.assign({}, x))}
              actions={[
                (rowData) => ({
                  icon: () => getFavourites(rowData.locationCode),
                  tooltip: <Typography>Favourite Location</Typography>,
                  onClick: (event) => handleClickFavBtn(rowData, event),
                }),
              ]}
              options={{
                showTitle: false,
                search: false,
                toolbar: false,
                pageSize: 10,
                actionsColumnIndex: -1,
              }}
              onRowClick={(event, selectedRow) => {
                onLocationClick(selectedRow)
              }}
              style={{ marginTop: "20px", border: "none", boxShadow: "none" }}
            />
            <StyledDataGrid
              ref={useGridApiRef()}
              // className={classes.dataGrid + ' ' + classes.paper}
              columns={[
                { headerName: "Location Name", field: "locationName", flex: 3, },
                { headerName: "Location Code", field: "locationCode", flex: 1, },
                {
                  headerName: "Actions",
                  field: "actions",
                  getActions: (params) => [
                    <GridActionsCellItem
                      icon={getFavourites(params.row.locationCode)}
                      label="Favourite Location"
                      description="Favourite Location"
                      className="textPrimary"
                      tooltip="Favourite Location"
                      onClick={() => handleClickFavBtn(params.row)}
                      color="inherit"
                      key={params.row.locationCode}
                    />
                  ],
                  flex: 1,
                  type: "actions",
                  align: "center",
                  headerAlign: "center",
                  minWidth: 200,
                },
              ]}
              rows={allowedFavourites.map((x) => Object.assign({
                ...x,
                id: x.locationCode
              }, x))}
              getRowClassName={(params) => {
                return params.row.locationCode == props.viewingLocation?.locationCode ?
                  'viewing' : params.row.locationCode == props.highlightedLocation?.locationCode ?
                    'highlighted' : ''
              }}
              slotProps={{
                row: {
                  onMouseEnter: handleRowHovered,
                  onMouseLeave: handleRowLeaved
                }
              }}
              icons={tableIcons}
              onRowClick={(selectedRow, event) => {
                onLocationClick(selectedRow.row)
              }}
            />
          </div>

        )}
        {allowedLocations && (
          <div>
            <StyledDataGrid
              ref={useGridApiRef()}
              // className={classes.dataGrid + ' ' + classes.paper}
              rowHeight={27}
              columns={[
                { headerName: "Location Name", field: "locationName", flex: 3, },
                { headerName: "Location Code", field: "locationCode", flex: 1, },
                {
                  headerName: "Actions",
                  field: "actions",
                  getActions: (params) => [
                    <GridActionsCellItem
                      icon={getFavourites(params.row.locationCode)}
                      label="Favourite Location"
                      description="Favourite Location"
                      className="textPrimary"
                      tooltip="Favourite Location"
                      onClick={() => handleClickFavBtn(params.row)}
                      color="inherit"
                      key={params.row.locationCode}
                    />
                  ],
                  flex: 1,
                  type: "actions",
                  align: "center",
                  headerAlign: "center",
                  minWidth: 200,
                },
              ]}
              rows={allowedLocations.map((x) => Object.assign({
                ...x,
                id: x.locationCode
              }, x))}
              slotProps={{
                row: {
                  onMouseEnter: handleRowHovered,
                  onMouseLeave: handleRowLeaved
                }
              }}
              getRowClassName={(params) => {
                return params.row.locationCode == props.viewingLocation?.locationCode ?
                  'viewing' : params.row.locationCode == props.highlightedLocation?.locationCode ?
                    'highlighted' : ''
              }}
              icons={tableIcons}
              onRowClick={(selectedRow, event) => {
                onLocationClick(selectedRow.row)
              }}
              style={{minHeight: "35vh"}}
            />
          </div>

        )}
      </TabPanel>
    </div>
  )
}



export default LocationSearch
