/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import TopBar from "../partials/TopBar"
import * as userInfoActions from "../../actions/userInfoActions"
import { Grid, TextField, Button, CircularProgress, Divider, Card, CardHeader, CardContent } from "@material-ui/core"
import { CardActions } from '@mui/material';

import _ from "lodash"

const UserProfile = () => {
    const dispatch = useDispatch()
    const auth = useSelector((state) => ({ ...state.auth }))
    const user = useSelector((state) => state.userInfo.settings.user)
    const isFetching = useSelector((state) => state.userInfo.isFetching)
    const [firstName, setFirstName] = useState(user.firstName)
    const [lastName, setLastName] = useState(user.lastName)
    const [email, setEmail] = useState(user.emailAddress)
    const [submitted, setSubmitted] = useState(false)
    const isFirstNameEmpty = _.isEmpty(firstName.trim());
    const isLastNameEmpty = _.isEmpty(lastName.trim());
    const isEmailEmpty = _.isEmpty(email.trim());
    function updateUserProfile(e) {
        e.preventDefault()
        const user = {
            firstName: firstName.trim(),
            lastName: lastName.trim(),
            emailAddress: email.trim()
        }
        setSubmitted(true)

        if (!isFirstNameEmpty && !isLastNameEmpty && !isEmailEmpty) {
            dispatch(userInfoActions.updateProfile({ token: auth.token }, user))
            setSubmitted(false)
        }
    }

    return (
        <React.Fragment>
            <TopBar />
            <Grid
                container
                style={{
                    backgrounColor: "#ccc",
                    height: "100vh",
                }}
                alignContent="center"
                justifyContent="center"
            >
                {user && (

                    <form>
                        <Card style={{ width: "550px", height: "600px", padding: "20px " }}>
                            <CardHeader title="Update Profile" />
                            <Divider />
                            <CardContent>
                                <Grid item xs={12}>
                                    <TextField
                                        id="firstName"
                                        label="First Name"
                                        margin="normal"
                                        type="text"
                                        required={true}
                                        error={isFirstNameEmpty && submitted}
                                        value={firstName}
                                        variant="outlined"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="lastName"
                                        label="Last Name"
                                        value={lastName}
                                        error={isLastNameEmpty && submitted}
                                        type="text"
                                        required={true}
                                        onChange={(e) => setLastName(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        id="email"
                                        label="Email Address"
                                        value={email}
                                        required={true}
                                        error={isEmailEmpty && submitted}
                                        type="email"
                                        onChange={(e) => setEmail(e.target.value)}
                                        margin="normal"
                                        variant="outlined"
                                        fullWidth
                                    />
                                </Grid>
                            </CardContent>
                            <CardActions sx={{justifyContent: "flex-end", padding: "16px"}}>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    size="large"
                                    variant="contained"
                                    onClick={(e) => updateUserProfile(e)}
                                    disabled={isFetching}
                                >
                                    Update Profile{" "}
                                    {isFetching && (
                                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                                    )}
                                </Button>
                            </CardActions>
                        </Card>
                    </form>
                )}
            </Grid>
        </React.Fragment >

    )
}

export default UserProfile
