import { takeLatest, put, call } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"

function getServiceManagerPromise(action) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token }` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `query {
                GetServiceManagers {
                    _id
                    username
                    emailAddress
                    staffId
                    firstName
                    lastName
                    lastLogin
                    userType
                    favouriteLocations
                    favouriteItcodes
                    meta {
                        key
                        value
                    }
                    accountUserGroups {
                        _id
                        name
                        active
                    }
                }
            }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    return fetch(uri, config);
}
export function* getServiceManagers(action) {
    try {
        const callback = () => getServiceManagerPromise(action)
        yield call(callApi, callback, [types.SERVICE_MANAGERS.GET.SUCCESS, types.SERVICE_MANAGERS.GET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}


export function* demoteManager(action) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token }` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation DemoteUser {
                DemoteUser(_id: "${action.payload._id}") {
                    _id
                }
            }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        const callback = () => fetch(uri, config)
            .then((res) => {
                if (!_.has(res, "errors")){
                    return getServiceManagerPromise(action);
                }
                return res
            })
        const callResponse = yield call(callApi, callback, [types.SERVICE_MANAGERS.DEMOTE.SUCCESS, types.SERVICE_MANAGERS.DEMOTE.FAILURE])
        if (_.has(callResponse, "errors")) {
            yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
        } else {
            yield put(appActions.showSuccessMessage("Success", "The user has been demoted"))
        }
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}
export function* watchServiceManagersRequest() {
    yield takeLatest(types.SERVICE_MANAGERS.GET.REQUEST, getServiceManagers);
    yield takeLatest(types.SERVICE_MANAGERS.DEMOTE.REQUEST, demoteManager);
}