/* eslint-disable import/no-named-as-default */
import React from "react"
import { hot } from "react-hot-loader"
import { createTheme } from "@material-ui/core/styles"
import { ThemeProvider } from "@material-ui/styles"
import CssBaseline from "@material-ui/core/CssBaseline"

import App from "./App"
import { NotificationProvider } from "./containers/NotificationCenter"

class Layout extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <NotificationProvider>
          <CssBaseline />
          <App />
        </NotificationProvider>
      </ThemeProvider>
    )
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000"
    },
    secondary: {
      main: "#ffcc00"
    }
  }
})

export default hot(module)(Layout)
