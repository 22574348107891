/* eslint-disable react/prop-types */
import React, { useEffect, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import ExtensionIcon from "@material-ui/icons/Extension"
import AddIcon from "@material-ui/icons/Add"
import RemoveIcon from "@material-ui/icons/Remove"
import CloseIcon from "@material-ui/icons/Close"
import * as bookingsActions from "../../actions/bookingsActions"
import { userLogic } from "../../businesslogic"
import { acrissDecoder } from "../../utils/functions"
import _ from "lodash"
import numeral from "numeral"
import { i18n } from "../i18n"
import { HERTZ_YELLOW, HERTZ_YELLOW_SECONDARY, BLUE, BLUE_SECONDARY } from "../../constants/style"

import {
  Typography,
  Grid,
  Paper,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  MenuItem,
  CircularProgress,
} from "@material-ui/core"

import SearchIcon from "@material-ui/icons/Search"
import EventIcon from "@material-ui/icons/Event"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"

import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"

import MomentUtils from "@date-io/moment"
import moment from "moment"

const iconMap = {
  PRAC: "/assets/icons/Premium_Emergency_Roadside_Service.png",
  BST: "/assets/icons/Booster_Seat.png",
  CST: "/assets/icons/Child_Seat_Forward_Facing.png",
  CSI: "/assets/icons/Child_Seat_Rear_Facing.png",
  TOLL: "/assets/icons/PlatePass.png",
  DRIVR: "/assets/icons/Additional_Driver.png",
  NVS: "/assets/icons/Portable_GPS.png"
}

const OptionIcon = ({code}) => {
  return iconMap[code]
    ? <img src={iconMap[code]} height="50px" /> 
    : <ExtensionIcon style={{ fontSize: "40px", height: "60px" }} />

}
const Quotation = (props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const quotation = useSelector((state) => ({ ...state.quotation }))
  const bookings = useSelector((state) => ({ ...state.bookings }))
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const locations = useSelector((state) => state.locations)
  const [days, setDays] = React.useState(1)
  const [pageLoaded, setPageLoaded] = React.useState(false)
  // const [dropOffMode, setDropOffMode] = useState("calendar")

  const itCodes = userLogic.getITCodes()

  const defaultVehicleClass = useSelector((state) => _.get(_.find(state.userInfo.settings.meta, { "key": "defaultVehicleClass" }), "value", ""))


  const labelContext = useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  useEffect(() => {
    const pd = moment(quotation.pickupDate + " " + quotation.pickupTime, "DD-MM-YYYY HH:mm")
    const dd = moment(quotation.dropoffDate + " " + quotation.dropoffTime, "DD-MM-YYYY HH:mm")
    setDays(dd.diff(pd, "days"))
    setPageLoaded(true)
  }, [])

  useEffect(() => {
    const pd = moment(quotation.pickupDate + " " + quotation.pickupTime, "DD-MM-YYYY HH:mm")
    const dd = moment(quotation.dropoffDate + " " + quotation.dropoffTime, "DD-MM-YYYY HH:mm")
    setDays(dd.diff(pd, "days"))
  }, [quotation.pickupDate, quotation.dropoffDate])

  useEffect(() => {
    if (_.get(userInfo, "settings.rentalRules.allowOneWay", false) == false) {
      dispatch(
        bookingsActions.setQuotation({
          dropoffLocation: quotation.pickupLocation,
        })
      )
    }
  }, [_.get(userInfo, "settings.rentalRules.allowOneWay", false)])

  useEffect(() => {
    if (pageLoaded) {
      const defaultVeh = bookings.rates.filter((r) => r.Class == defaultVehicleClass)
      if (defaultVeh.length >= 1) {
        handleSelectCustomerVehicle(null, {
          ...getVehicleData(defaultVehicleClass),
          Availability: "Available",
        })
        handleSelectInsurerVehicle(null, {
          ...getVehicleData(defaultVehicleClass),
          Availability: "Available",
        })
      } else {
        handleSelectCustomerVehicle(null, {
          ...getVehicleData(defaultVehicleClass),
          Availability: "Available",
        })
        handleSelectInsurerVehicle(null, {
          ...getVehicleData(defaultVehicleClass),
          Availability: "Available",
        })
      }
    }
  }, [bookings.rates])

  useEffect(() => {
    if (bookings.options != null && pageLoaded) {
      dispatch(
        bookingsActions.getReservationEstimate({
          token: auth.token,
          referenceId: bookings.referenceId,
          rateClass: quotation.customerToReceive,
          voucherClass: quotation.insurerChargedAs,
          options: quotation.options,
        })
      )
    }
  }, [bookings.options && bookings.options.delta, quotation.options, quotation.insurerChargedAs])

  const handleOnSearchAvailability = (e) => {
    e.preventDefault()
    let existingRes = {}
    if (bookings.referenceId != "" && bookings.reservationNumber != "" && bookings.reservationStatus == "open") {
      existingRes = {
        referenceId: bookings.referenceId,
      }
    }
    // exclude last three characters to extract the rateClass
    const quotationLength = quotation.vehicleClass.length
    const maxLength = quotationLength - 3 >= 1 ? quotationLength - 3 : quotationLength 
    const rateClass = quotation.vehicleClass.substring(0, maxLength)
    // TODO WHERE IS DOBOFYOUNGESTDRIVER SET?
    // note: dob needs to be minus 21 years and 2 days to make sure the right age is calculate regardless of client timezone
    dispatch(
      bookingsActions.getRatesAvailability({
        ...existingRes,
        pickupLocation: quotation.pickupLocation,
        dropoffLocation: quotation.dropoffLocation,
        pickupDateTime: moment(quotation.pickupDate + " " + quotation.pickupTime, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
        dropoffDateTime: moment(quotation.dropoffDate + " " + quotation.dropoffTime, "DD-MM-YYYY HH:mm").format(
          "YYYY-MM-DDTHH:mm"
        ),
        rateClass,
        packageCode: quotation.itcode,
        dobOfYoungestDriver: quotation.age
          ? moment().subtract(quotation.age, "years").subtract(2, "days").format("YYYY-MM-DD")
          : moment().subtract(21, "years").subtract(2, "days").format("YYYY-MM-DD"),
        token: auth.token,
      })
    )
  }
  /* const getVehicleCodeWithCountry = (vehicleCode) => {
    const pickupLocation = locations.locations.find(loc => loc.locationCode === quotation.pickupLocation)
    const { country } = pickupLocation
    const eCountry = {
      AU: "AUS",
      NZ: "NZL"
    }
    if(vehicleCode === "+C") {
      return vehicleCode;
    }
    console.log("getVehicleCodeWithCountry***", `${vehicleCode}${eCountry[country]}`)
    return `${vehicleCode}${eCountry[country]}`
  } */

  const handleSelectCustomerVehicle = (e, v) => {
    const quotationLength = v.code?.length ?? 1
    const maxLength = quotationLength - 3 >= 1 ? quotationLength - 3 : quotationLength 
    const legacyCode = v.code?.substring(0, maxLength);
    if (e != null) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (v.Availability == "Available" && v.acriss) {
      const acrissObject = acrissDecoder(v.acriss)
      let insurerObj = {}

      const customerVehicleIndex = _.findIndex(bookings.rates, {
        Class: `${legacyCode}`,
      })
      const insurerVehicleIndex = _.findIndex(bookings.rates, {
        Class: `${quotation.insurerChargedAs}`,
      })

      if (
        // - Same price rule
        // bookings.rates[customerVehicleIndex].Rental.Estimate <
        // bookings.rates[insurerVehicleIndex].Rental.Estimate

        // - Same index rule (this is the business rule they want)
        customerVehicleIndex < insurerVehicleIndex
      ) {
        insurerObj = {
          insurerChargedAs: legacyCode,
          insurerAcrissCode: v.acriss,
          insurerAcrissCategory: _.get(acrissObject, "Category.Desc", ""),
          insurerAcrissType: _.get(acrissObject, "Type.Desc", ""),
          insurerAcrissTransmission: _.get(acrissObject, "Transmission.Desc", ""),
          insurerAcrissFueldAndAir: _.get(acrissObject, "FuelAndAir.Desc", ""),
        }
      }

      if (acrissObject) {
        const itinerary = {
          ...bookings.itinerary,
          pickup: {
            location: quotation.pickupLocation,
            date: quotation.pickupDate,
            time: quotation.pickupTime
          },
          dropoff: {
            location: quotation.dropoffLocation,
            date: quotation.dropoffDate,
            time: quotation.dropoffTime
          },
          customerToReceive: legacyCode,
          customerAcrissCode: v.acriss,
          customerAcrissCategory: _.get(acrissObject, "Category.Desc", ""),
          customerAcrissType: _.get(acrissObject, "Type.Desc", ""),
          customerAcrissTransmission: _.get(acrissObject, "Transmission.Desc", ""),
          customerAcrissFueldAndAir: _.get(acrissObject, "FuelAndAir.Desc", ""),
        }
        dispatch(
          bookingsActions.setQuotation({
            ...insurerObj,
            customerToReceive: legacyCode,
            customerAcrissCode: v.acriss,
            customerAcrissCategory: _.get(acrissObject, "Category.Desc", ""),
            customerAcrissType: _.get(acrissObject, "Type.Desc", ""),
            customerAcrissTransmission: _.get(acrissObject, "Transmission.Desc", ""),
            customerAcrissFueldAndAir: _.get(acrissObject, "FuelAndAir.Desc", ""),
          })
        )

        dispatch(bookingsActions.setBookings({itinerary}))
        props.setIsFormModified(true)
        dispatch(
          bookingsActions.getOptions({
            token: auth.token,
            referenceId: bookings.referenceId,
            vehicleClass: legacyCode,
          })
        )
      }
    }
  }

  const handleSelectInsurerVehicle = (e, v) => {
    const quotationLength = v.code?.length ?? 1
    const maxLength = quotationLength - 3 >= 1 ? quotationLength - 3 : quotationLength 
    const legacyCode = v.code?.substring(0, maxLength);

    if (e != null) {
      e.preventDefault()
      e.stopPropagation()
    }
    if (v.Availability == "Available") {
      const acrissObject = acrissDecoder(v.acriss)
      if (acrissObject) {
        const itinerary = {
          ...bookings.itinerary,
          pickup: {
            location: quotation.pickupLocation,
            date: quotation.pickupDate,
            time: quotation.pickupTime
          },
          dropoff: {
            location: quotation.dropoffLocation,
            date: quotation.dropoffDate,
            time: quotation.dropoffTime
          },
          insurerChargedAs: legacyCode,
          insurerAcrissCode: v.acriss,
          insurerAcrissCategory: acrissObject.Category.Desc,
          insurerAcrissType: acrissObject.Type.Desc,
          insurerAcrissTransmission: acrissObject.Transmission.Desc,
          insurerAcrissFueldAndAir: acrissObject.FuelAndAir.Desc,
        }
        dispatch(
          bookingsActions.setQuotation({
            insurerChargedAs: legacyCode,
            insurerAcrissCode: v.acriss,
            insurerAcrissCategory: acrissObject.Category.Desc,
            insurerAcrissType: acrissObject.Type.Desc,
            insurerAcrissTransmission: acrissObject.Transmission.Desc,
            insurerAcrissFueldAndAir: acrissObject.FuelAndAir.Desc,
          })
        )

        dispatch(bookingsActions.setBookings({itinerary}))
        props.setIsFormModified(true)
      }
    }
  }

  const handleOptionActions = (e, o, inc) => {
    const opt = [...quotation.options]
    let skipDispatch = false

    const _o = opt.filter((op) => op.Code == o.Code)
    if (_o.length != 0) {
      const i = opt.indexOf(_o[0])
      if (opt[i].Quantity > 0 && (o.AllowQuantity || inc < 0)) {
        opt[i] = {
          ...opt[i],
          Quantity: opt[i].Quantity + inc,
        }
      } else {
        skipDispatch = true
      }
      if (opt[i].Quantity <= 0) opt.splice(i, 1)
    } else {
      if (inc == 1) opt.push({ Code: o.Code, Quantity: 1 })
    }

    if (!skipDispatch) {
      dispatch(
        bookingsActions.setQuotation({
          options: opt,
        })
      )
    }
  }

  const getVehicleData = (c) => {
    const veh = bookings.vehicles.filter((v) => {
      const quotationLength = v.code.length
      const maxLength = quotationLength - 3 >= 1 ? quotationLength - 3 : quotationLength 
      return v.code.substring(0, maxLength) === c
    })
    return veh.length != 0 ? veh[0] : null
  }

  function pickUpSearchClick(e) {
    e.preventDefault()
    props.onLocationSearch("pickUp")
  }

  function dropOffSearchClick(e) {
    e.preventDefault()
    props.onLocationSearch("dropOff")
  }

  // const handleDropOffMode = e => {
  //   e.preventDefault()
  //   e.stopPropagation()
  //   setDropOffMode(dropOffMode == "calendar" ? "days" : "calendar")
  // }

  const handleDateTimeChange = (d, el) => {
    dispatch(
      bookingsActions.setQuotation({
        [el]: d.format("DD-MM-YYYY"),
      })
    )
  }

  const handleFormChange = (e) => {
    dispatch(
      bookingsActions.setQuotation({
        [e.target.name || e.target.id]: e.target.id == "deliveryRequired" ? e.target.checked : e.target.value,
      })
    )
  }

  const handleDaysChange = (e) => {
    if (e.target.value >= 0 && pageLoaded) {
      setDays(e.target.value)
      dispatch(
        bookingsActions.setQuotation({
          dropoffDate: moment(quotation.pickupDate, "DD-MM-YYYY").add(e.target.value, "d").format("DD-MM-YYYY"),
        })
      )
    }
  }

  const addDaysDuration = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (pageLoaded) {
      setDays(parseInt(days) + 1)
      dispatch(
        bookingsActions.setQuotation({
          dropoffDate: moment(quotation.pickupDate, "DD-MM-YYYY")
            .add(days + 1, "d")
            .format("DD-MM-YYYY"),
        })
      )
    }
  }

  const subtractDaysDuration = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if (days > 1) {
      setDays(parseInt(days) - 1)
      dispatch(
        bookingsActions.setQuotation({
          dropoffDate: moment(quotation.pickupDate, "DD-MM-YYYY")
            .add(days - 1, "d")
            .format("DD-MM-YYYY"),
        })
      )
    }
  }

  const handleDaysBlur = () => {
    if (days == "") {
      setDays(1)
    }
  }

  function getVehicleLabel(vehicle) {
    const { meta } = vehicle;
    const acriss = meta  ? `(${meta.find(e => e.key === "acriss").value})` : "";
    return `${acriss} ${vehicle.name}`;
  }

  function getLocationCodeName(lCode) {
    const location = locations.locations ? locations.locations.filter((l) => l.locationCode.includes(lCode)) : null
    return !_.isEmpty(lCode) && !_.isEmpty(location) ? `(${lCode}) ${location[0].locationName}` : ""
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Grid container spacing={0} justify="center">
        <Grid container item spacing={1} justify="flex-end" xs={12}>
          {/* <Grid container item xs={12} lg={12} justify="flex-end">
            <FormControl component="fieldset" style={{ float: "right", clear: "both", marginTop: 8 }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      id="deliveryRequired"
                      checked={quotation.deliveryRequired}
                      onChange={handleFormChange}
                      disabled={!userLogic.getAllowPickupAndDropoff()}
                    />
                  }
                  label={getLabel("quotation.deliveryrequired", "Delivery Required")}
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Grid> */}
          <Grid item xs={6} lg={6}>
            <TextField
              id="itcode"
              name="itcode"
              select
              label={getLabel("quotation.itcode", "IT Code")}
              onChange={handleFormChange}
              value={quotation.itcode}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              {itCodes &&
                itCodes.map((i, k) => (
                  <MenuItem key={k} value={i.code}>
                    {i && i.name && i.name.toUpperCase()}
                  </MenuItem>
                ))}
            </TextField>
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              id="vehicleClass"
              name="vehicleClass"
              select
              label={getLabel("quotation.vehicleClass", "Vehicle Class")}
              onChange={handleFormChange}
              value={quotation.vehicleClass}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              {userLogic.getVehicleClasses().map((v, k) => (
                <MenuItem key={k} value={v.code}>
                  { getVehicleLabel(v) }
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={6} lg={6}>
            <TextField
              id="age"
              name="age"
              select
              label={getLabel("quotation.age", "Age of Youngest Driver")}
              onChange={handleFormChange}
              value={quotation.age}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <MenuItem key={1} value="16">
                16
              </MenuItem>
              <MenuItem key={1} value="17">
                17
              </MenuItem>
              <MenuItem key={1} value="18">
                18
              </MenuItem>
              <MenuItem key={1} value="19">
                19
              </MenuItem>
              <MenuItem key={1} value="20">
                20
              </MenuItem>
              <MenuItem key={2} value="21">
                21 +
              </MenuItem>
            </TextField>
          </Grid>
          <Grid container item xs={6} lg={6} justify="space-between">
            <TextField
              id="dayExtend"
              name="dayExtend"
              label={getLabel("quotation.days", "No. of Days")}
              className={classes.textField}
              value={days}
              onChange={handleDaysChange}
              onBlur={handleDaysBlur}
              type="number"
              variant="outlined"
              margin="dense"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton edge="start" onClick={subtractDaysDuration}>
                      <RemoveCircleOutlineIcon />
                    </IconButton>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={addDaysDuration}>
                      <AddCircleOutlineIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1} justify="center" xs={12}>
          <Grid container item xs={6} lg={3} justify="space-between">
            <DatePicker
              autoOk={true}
              margin="dense"
              allowKeyboardControl={false}
              id="pickupDate"
              label={getLabel("quotation.pickupDate", "Pickup Date")}
              disablePast
              value={moment(quotation.pickupDate, "DD-MM-YYYY").toDate()}
              onChange={(d) => {
                handleDateTimeChange(d, "pickupDate")
              }}
              inputVariant="outlined"
              fullWidth
              format="dd DD MMM YYYY"
              showTodayButton={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              id="pickupTime"
              name="pickupTime"
              select
              label={getLabel("quotation.pickupTime", "Pickup Time")}
              onChange={handleFormChange}
              value={quotation.pickupTime}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <MenuItem value="00:00">12:00 AM</MenuItem>
              <MenuItem value="00:30">12:30 AM</MenuItem>
              <MenuItem value="01:00">01:00 AM</MenuItem>
              <MenuItem value="01:30">01:30 AM</MenuItem>
              <MenuItem value="02:00">02:00 AM</MenuItem>
              <MenuItem value="02:30">02:30 AM</MenuItem>
              <MenuItem value="03:00">03:00 AM</MenuItem>
              <MenuItem value="03:30">03:30 AM</MenuItem>
              <MenuItem value="04:00">04:00 AM</MenuItem>
              <MenuItem value="04:30">04:30 AM</MenuItem>
              <MenuItem value="05:00">05:00 AM</MenuItem>
              <MenuItem value="05:30">05:30 AM</MenuItem>
              <MenuItem value="06:00">06:00 AM</MenuItem>
              <MenuItem value="06:30">06:30 AM</MenuItem>
              <MenuItem value="07:00">07:00 AM</MenuItem>
              <MenuItem value="07:30">07:30 AM</MenuItem>
              <MenuItem value="08:00">08:00 AM</MenuItem>
              <MenuItem value="08:30">08:30 AM</MenuItem>
              <MenuItem value="09:00">09:00 AM</MenuItem>
              <MenuItem value="09:30">09:30 AM</MenuItem>
              <MenuItem value="10:00">10:00 AM</MenuItem>
              <MenuItem value="10:30">10:30 AM</MenuItem>
              <MenuItem value="11:00">11:00 AM</MenuItem>
              <MenuItem value="11:30">11:30 AM</MenuItem>
              <MenuItem value="12:00">12:00 PM</MenuItem>
              <MenuItem value="12:30">12:30 PM</MenuItem>
              <MenuItem value="13:00">01:00 PM</MenuItem>
              <MenuItem value="13:30">01:30 PM</MenuItem>
              <MenuItem value="14:00">02:00 PM</MenuItem>
              <MenuItem value="14:30">02:30 PM</MenuItem>
              <MenuItem value="15:00">03:00 PM</MenuItem>
              <MenuItem value="15:30">03:30 PM</MenuItem>
              <MenuItem value="16:00">04:00 PM</MenuItem>
              <MenuItem value="16:30">04:30 PM</MenuItem>
              <MenuItem value="17:00">05:00 PM</MenuItem>
              <MenuItem value="17:30">05:30 PM</MenuItem>
              <MenuItem value="18:00">06:00 PM</MenuItem>
              <MenuItem value="18:30">06:30 PM</MenuItem>
              <MenuItem value="19:00">07:00 PM</MenuItem>
              <MenuItem value="19:30">07:30 PM</MenuItem>
              <MenuItem value="20:00">08:00 PM</MenuItem>
              <MenuItem value="20:30">08:30 PM</MenuItem>
              <MenuItem value="21:00">09:00 PM</MenuItem>
              <MenuItem value="21:30">09:30 PM</MenuItem>
              <MenuItem value="22:00">10:00 PM</MenuItem>
              <MenuItem value="22:30">10:30 PM</MenuItem>
              <MenuItem value="23:00">11:00 PM</MenuItem>
              <MenuItem value="23:30">11:30 PM</MenuItem>
            </TextField>
          </Grid>
          <Grid container item xs={6} lg={3} justify="space-between">
            <DatePicker
              autoOk={true}
              margin="dense"
              id="dropoffDate"
              label={getLabel("quotation.dropoffDate", "Dropoff Date")}
              value={moment(quotation.dropoffDate, "DD-MM-YYYY").toDate()}
              minDate={moment(quotation.pickupDate, "DD-MM-YYYY").toDate()}
              minDateMessage="Dropoff should not be before pickup"
              onChange={(d) => {
                handleDateTimeChange(d, "dropoffDate")
              }}
              fullWidth
              format="dd DD MMM YYYY"
              showTodayButton
              inputVariant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end">
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} lg={3}>
            <TextField
              id="dropoffTime"
              name="dropoffTime"
              select
              label={getLabel("quotation.dropoffTime", "Dropoff Time")}
              onChange={handleFormChange}
              value={quotation.dropoffTime}
              variant="outlined"
              margin="dense"
              fullWidth
            >
              <MenuItem value="00:00">12:00 AM</MenuItem>
              <MenuItem value="00:30">12:30 AM</MenuItem>
              <MenuItem value="01:00">01:00 AM</MenuItem>
              <MenuItem value="01:30">01:30 AM</MenuItem>
              <MenuItem value="02:00">02:00 AM</MenuItem>
              <MenuItem value="02:30">02:30 AM</MenuItem>
              <MenuItem value="03:00">03:00 AM</MenuItem>
              <MenuItem value="03:30">03:30 AM</MenuItem>
              <MenuItem value="04:00">04:00 AM</MenuItem>
              <MenuItem value="04:30">04:30 AM</MenuItem>
              <MenuItem value="05:00">05:00 AM</MenuItem>
              <MenuItem value="05:30">05:30 AM</MenuItem>
              <MenuItem value="06:00">06:00 AM</MenuItem>
              <MenuItem value="06:30">06:30 AM</MenuItem>
              <MenuItem value="07:00">07:00 AM</MenuItem>
              <MenuItem value="07:30">07:30 AM</MenuItem>
              <MenuItem value="08:00">08:00 AM</MenuItem>
              <MenuItem value="08:30">08:30 AM</MenuItem>
              <MenuItem value="09:00">09:00 AM</MenuItem>
              <MenuItem value="09:30">09:30 AM</MenuItem>
              <MenuItem value="10:00">10:00 AM</MenuItem>
              <MenuItem value="10:30">10:30 AM</MenuItem>
              <MenuItem value="11:00">11:00 AM</MenuItem>
              <MenuItem value="11:30">11:30 AM</MenuItem>
              <MenuItem value="12:00">12:00 PM</MenuItem>
              <MenuItem value="12:30">12:30 PM</MenuItem>
              <MenuItem value="13:00">01:00 PM</MenuItem>
              <MenuItem value="13:30">01:30 PM</MenuItem>
              <MenuItem value="14:00">02:00 PM</MenuItem>
              <MenuItem value="14:30">02:30 PM</MenuItem>
              <MenuItem value="15:00">03:00 PM</MenuItem>
              <MenuItem value="15:30">03:30 PM</MenuItem>
              <MenuItem value="16:00">04:00 PM</MenuItem>
              <MenuItem value="16:30">04:30 PM</MenuItem>
              <MenuItem value="17:00">05:00 PM</MenuItem>
              <MenuItem value="17:30">05:30 PM</MenuItem>
              <MenuItem value="18:00">06:00 PM</MenuItem>
              <MenuItem value="18:30">06:30 PM</MenuItem>
              <MenuItem value="19:00">07:00 PM</MenuItem>
              <MenuItem value="19:30">07:30 PM</MenuItem>
              <MenuItem value="20:00">08:00 PM</MenuItem>
              <MenuItem value="20:30">08:30 PM</MenuItem>
              <MenuItem value="21:00">09:00 PM</MenuItem>
              <MenuItem value="21:30">09:30 PM</MenuItem>
              <MenuItem value="22:00">10:00 PM</MenuItem>
              <MenuItem value="22:30">10:30 PM</MenuItem>
              <MenuItem value="23:00">11:00 PM</MenuItem>
              <MenuItem value="23:30">11:30 PM</MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid container item xs={12} spacing={1} justify="space-between">
          <Grid item xs={12} lg={6}>
            <TextField
              id="pickupLocation"
              label={getLabel("quotation.pickupLocation", "Pickup Location")}
              value={getLocationCodeName(quotation.pickupLocation)}
              className={classes.locationField}
              variant="outlined"
              margin="dense"
              fullWidth
              onClick={(e) => pickUpSearchClick(e)}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={(e) => pickUpSearchClick(e)}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <TextField
              id="dropoffLocation"
              label={getLabel("quotation.dropoffLocation", "Dropoff Location")}
              value={getLocationCodeName(quotation.dropoffLocation)}
              className={classes.locationField}
              margin="dense"
              variant="outlined"
              fullWidth
              disabled={!userLogic.getAllowOneWay()}
              InputLabelProps={{
                shrink: true,
              }}
              onClick={(e) => dropOffSearchClick(e)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton edge="end" onClick={(e) => dropOffSearchClick(e)} disabled={!userLogic.getAllowOneWay()}>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={1} justify="flex-end" xs={12}></Grid>
        <Grid container item spacing={1} justify="flex-end" xs={12}>
          <Grid style={{ marginTop: 5 }} container item xs={4} justify="flex-end">
            <Button color="primary" size="medium" variant="contained" onClick={handleOnSearchAvailability}>
              Search Availability
              {bookings.isFetchingRates && (
                <CircularProgress size={15} style={{ margin: "0 5px 0 10px", color: "#fff" }} />
              )}
            </Button>
          </Grid>
        </Grid>
        {/* row end */}
        {bookings.rates.length != 0 && (
          <React.Fragment>
            <Grid container item spacing={1} justify="flex-start" xs={12}>
              <Grid container item xs={12} justify="flex-start">
                <Typography variant="h6">Select Charges</Typography>
              </Grid>
            </Grid>
            <div
              style={{
                position: "relative",
                margin: "5px 4px 0",
                padding: "10px 0",
                height: "250px",
                display: "inline-flex",
                width: "100%",
                overflow: "auto",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "5px",
              }}
            >
              {bookings.rates
                .map((x) => Object.assign({}, x))
                .sort(
                  (a, b) =>
                    (a.Availability == "Available" ? a.Rental.Estimate : Number.MAX_VALUE) -
                    (b.Availability == "Available" ? b.Rental.Estimate : Number.MAX_VALUE)
                )
                .map((r, i, arr) => {
                  const veh = {
                    ...getVehicleData(r.Class),
                    Availability: r.Availability,
                  }

                  return (
                    <span key={i}>
                      <Paper
                        className={classes.vehicleCard}
                        style={{
                          opacity: r.Availability == "Available" ? `1` : `0.3`,
                        }}
                      >
                        <div
                          className="customerToReceive"
                          style={{
                            background: r.Class == quotation.customerToReceive ? HERTZ_YELLOW : HERTZ_YELLOW_SECONDARY,
                            width: "100px",
                            height: "40px",
                            position: "absolute",
                            top: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor: r.Availability == "Available" ? `pointer` : `default`,
                            pointerEvents: r.Availability == "Available" ? `inherit` : `none`,
                          }}
                          onClick={(e) => handleSelectCustomerVehicle(e, veh)}
                        >
                          RENTER
                          <br />
                          {numeral(Math.abs(r.Voucher.RateOnlyEstimate - r.Rental.RateOnlyEstimate)).format("$0,0.00")}
                        </div>
                        <img src={`https://images.hertz.com/vehicles/220x128/${veh.image}`} height="40px" />
                        <div style={{ fontSize: "10px" }}>Class {veh.code}</div>
                        <div style={{ fontSize: "10px" }}>{veh.title}</div>
                        <div style={{ fontSize: "10px" }}>or similar</div>
                        <div
                          className="insurerChargedAs"
                          style={{
                            background: r.Class == quotation.insurerChargedAs ? BLUE : BLUE_SECONDARY,
                            width: "100px",
                            height: "40px",
                            position: "absolute",
                            bottom: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor:
                              r.Availability == "Available" &&
                                i <= _.findIndex(arr, { Class: quotation.customerToReceive })
                                ? `pointer`
                                : `default`,
                            opacity:
                              r.Availability == "Available" &&
                                i <= _.findIndex(arr, { Class: quotation.customerToReceive })
                                ? `1`
                                : `0.3`,
                            pointerEvents:
                              r.Availability == "Available" &&
                                i <= _.findIndex(arr, { Class: quotation.customerToReceive })
                                ? `inherit`
                                : `none`,
                          }}
                          onClick={(e) => handleSelectInsurerVehicle(e, veh)}
                        >
                          COMPANY
                          <br />
                          {numeral(r.Voucher.RateOnlyEstimate).format("$0,0.00")}
                        </div>
                      </Paper>
                    </span>
                  )
                })}
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#00000080",
                  display: bookings.isFetchingRates ? "block" : "none",
                }}
              >
                <Grid item container justify="center" alignItems="center" style={{ height: "100%" }}>
                  <Grid item>
                    <CircularProgress style={{ color: "#fff" }} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </React.Fragment>
        )}
        {bookings.options && (
          <React.Fragment>
            <Grid container item spacing={1} justify="flex-start" xs={12} style={{ marginTop: 10 }}>
              <Grid container item xs={12} justify="flex-start">
                <Typography variant="h6">Optional Extras</Typography>
              </Grid>
            </Grid>
            <div
              style={{
                position: "relative",
                margin: "5px 4px 0",
                padding: "10px 0",
                height: "250px",
                display: "inline-flex",
                width: "100%",
                overflow: "auto",
                border: "1px solid rgba(0, 0, 0, 0.23)",
                borderRadius: "5px",
              }}
            >
              {bookings.options &&
                !_.isEmpty(bookings.options) &&
                bookings.options.Customer &&
                bookings.options.Customer.map((o, i) => {
                  const opt = quotation.options.filter((op) => op.Code == o.Code)
                  return (
                    <span key={i}>
                      <Paper
                        className={classes.optionCard}
                        style={{
                          background: `${opt.length}` != 0 ? HERTZ_YELLOW : HERTZ_YELLOW_SECONDARY,
                          padding: "25px 0px",
                        }}
                      >
                        <div
                          className="btnQuantityCustomer"
                          style={{
                            background: "#000",
                            width: "100px",
                            height: "20px",
                            position: "absolute",
                            top: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleOptionActions(e, o, 1)}
                        >
                          <AddIcon className="btnQuantityCustomer"/>
                        </div>
                        <div style={{ fontSize: "18px", height: "18px" }}>
                          {opt.length != 0 ? (
                            <React.Fragment>
                              <CloseIcon style={{ fontSize: "10px" }} />
                              {opt[0].Quantity}
                            </React.Fragment>
                          ) : (
                            ``
                          )}
                        </div>
                        <OptionIcon code={o.Code} />
                        <div style={{ fontSize: "14px" }}>{numeral(o.SubTotal).format("$0,0.00")}</div>
                        <div style={{ fontSize: "10px" }}>{o.Description}</div>
                        <div
                          className="btnQuantityCustomer"
                          style={{
                            background: "#000",
                            width: "100px",
                            height: "20px",
                            position: "absolute",
                            bottom: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleOptionActions(e, o, -1)}
                        >
                          <RemoveIcon className="btnQuantityCustomer"/>
                        </div>
                      </Paper>
                    </span>
                  )
                })}
              {bookings.options != {} &&
                !_.isEmpty(bookings.options) &&
                bookings.options.Voucher &&
                bookings.options.Voucher.map((o, i) => {
                  const opt = quotation.options.filter((op) => op.Code == o.Code)
                  return (
                    <span key={i}>
                      <Paper
                        className={classes.optionCard}
                        style={{
                          background: `${opt.length}` != 0 ? BLUE : BLUE_SECONDARY,
                          padding: "25px 0px",
                        }}
                      >
                        <div
                          className="btnQuantityVoucher"
                          style={{
                            background: "#000",
                            width: "100px",
                            height: "20px",
                            position: "absolute",
                            top: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleOptionActions(e, o, 1)}
                        >
                          <AddIcon className="btnQuantityVoucher"/>
                        </div>
                        <div style={{ fontSize: "18px", height: "18px" }}>
                          {opt.length != 0 ? (
                            <React.Fragment>
                              <CloseIcon style={{ fontSize: "10px" }} />
                              {opt[0].Quantity}
                            </React.Fragment>
                          ) : (
                            ``
                          )}
                        </div>
                        <OptionIcon code={o.Code} />
                        <div style={{ fontSize: "14px" }}>{numeral(o.Total).format("$0,0.00")}</div>
                        <div style={{ fontSize: "10px" }}>{o.Description}</div>
                        <div
                          className="btnQuantityVoucher"
                          style={{
                            background: "#000",
                            width: "100px",
                            height: "20px",
                            position: "absolute",
                            bottom: "0px",
                            fontSize: "10px",
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={(e) => handleOptionActions(e, o, -1)}
                        >
                          <RemoveIcon className="btnQuantityVoucher"/>
                        </div>
                      </Paper>
                    </span>
                  )
                })}
              <div
                style={{
                  position: "absolute",
                  top: "0px",
                  width: "100%",
                  height: "100%",
                  backgroundColor: "#00000080",
                  display: bookings.isFetchingOptions ? "block" : "none",
                }}
              >
                <Grid item container justify="center" alignItems="center" style={{ height: "100%" }}>
                  <Grid item>
                    <CircularProgress style={{ color: "#fff" }} />
                  </Grid>
                </Grid>
              </div>
            </div>
          </React.Fragment>
        )}
      </Grid>
    </MuiPickersUtilsProvider>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "210px",
  width: "100px",
  padding: "50px 0",
  margin: "5px",
  position: "relative",
  overflow: "hidden",
  "&:hover": {
    height: "210px",
    marginTop: "0px",
  },
  "& div.customerToReceive:hover": {
    background: HERTZ_YELLOW + " !important",
  },
  "& div.insurerChargedAs:hover": {
    background: BLUE + " !important",
  },
  "& svg.btnQuantityCustomer, svg.btnQuantityVoucher": {
    fill: "#fff"
  },
  "& div.btnQuantityCustomer:hover": {
    background: HERTZ_YELLOW + " !important",
    fill: "#000"
  },
  "& div.btnQuantityVoucher:hover": {
    background: BLUE + " !important",
  },
}

const useStyles = makeStyles((theme) => ({
  vehicleCard: cardWidget,
  optionCard: cardWidget,
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
  },
  locationField: {
    "& input": {
      cursor: "pointer",
    },
  },
}))

export default Quotation
