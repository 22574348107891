/* eslint-disable react/prop-types */
/* eslint-disable import/no-named-as-default */
import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Redirect, Link } from "react-router-dom"
import * as authActions from "../../actions/authActions"
import * as userInfoActions from "../../actions/userInfoActions"
import * as locationsActions from "../../actions/locationsActions"
import * as bookingsActions from "../../actions/bookingsActions"
import * as appActions from "../../actions/appActions"
import { i18n } from "../i18n"
import _ from "lodash"
/* import axios from "axios" */

import { Paper, TextField, Typography, Button, CircularProgress } from "@material-ui/core"
import Grid from '@mui/material/Grid'

let path = window.location.pathname
path = path.split("/")
const brand = _.toLower(path[1])

const Login = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const userInfoSuccess = useSelector((state) => state.userInfo.success)
  const locations = useSelector((state) => ({ ...state.locations }))
  const locationsSuccess = useSelector((state) => state.locations.success)
  const bookings = useSelector((state) => ({ ...state.bookings }))
  const bookingsSuccess = useSelector((state) => state.bookings.success)
  const base = location.pathname.split("/")[1].toUpperCase()
  const labelContext = useContext(i18n)
  const serviceName = _.get(_.find(userInfo.settings.meta, { "key": "serviceName" }), "value", "");

  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }
  
  const [username, setUsername] = useState("")
  const [password, setPassword] = useState("")
  /* const [ssoLoading, setSsoLoading] = React.useState(false) */

  useEffect(() => {
    if (auth.token != "" && auth.error == "") {
      dispatch(bookingsActions.getVehicleModels({ token: auth.token }))
      dispatch(locationsActions.getAllLocations({ token: auth.token }))
      // dispatch(userInfoActions.getUserSettings({ token: auth.token }))
      dispatch(userInfoActions.getServiceSettings({ token: auth.token }))
    }
  }, [auth.token])

  useEffect(() => {
    if (userInfoSuccess === false || bookingsSuccess === false || locationsSuccess === false) {
      dispatch(authActions.logout());
      dispatch(appActions.showMessage("Request Error", "Something went wrong. Please Try Again later", "danger"));
    }
  }, [userInfoSuccess, locationsSuccess, bookingsSuccess])

  const isDependenciesLoaded = () => {
    return (
      (serviceName != "" &&
        locations.locations != null &&
        bookings.vehicles.length != 0) ||
      auth.token == ""
    )
  }

  const login = () => {
    dispatch(authActions.login({ scope: "OBT_BOOKING_API", service: base.toUpperCase(), username, password }))
  }

  /* async function handleSSO() {
    setSsoLoading(true)
    const response = await axios
      .post(
        "https://agatho-dev.hertz.asiapac.io/auth",
        { scope: "OBT_BOOKING_API_SAML_URL_REQUEST", service: "RACQ" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then(function (response) {
        return response
      })
      .catch(function (error) {
        return error
      })
    window.location.replace(response.data.redirect)
  } */

  let { from } = props.location.state || { from: { pathname: `/${base}` } }

  from =
    from.pathname != "/" && from.pathname != "/login"
      ? from
      : serviceName != "" && auth.token != ""
        ? { pathname: `/${serviceName}/dashboard` }
        : { from: { pathname: "/" } }

  return isDependenciesLoaded() && auth.token != "" && from.pathname != "/" ? (
    <Redirect to={from} />
  ) : (
    <Grid
      container
      style={{
        backgrounColor: "#ccc",
        height: "100vh",
        backgroundImage: 'url("https://static.hertz.asiapac.io/portal/img/LoYLaas.jpg")',
      }}
      justifyContent="flex-end"
    >
      <Grid
        container
        item
        style={{ width: "650px", backgroundColor: "#000", opacity: "0.8" }}
        justifyContent="center"
        alignItems="center"
      >
        <Paper style={{ width: "550px", height: "600px", padding: "30px 20px", background: "#e0dada" }}>
          <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{ margin: "25px 0px" }}>
            <img src={`https://static.hertz.asiapac.io/mybookingportal/${brand}-dark-logo.png`} height="80px" />
          </Grid>
          <Typography variant="h4">Sign In</Typography>
          <Grid item xs={12}>
            <TextField
              id="username"
              label={getLabel("login.Username", "Username")}
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              id="password"
              label={getLabel("login.Password", "Password")}
              value={password}
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              variant="outlined"
              fullWidth
            />
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="flex-start">
            <Grid item>
              <Link to={`/${base}/forgotPassword`}>Forgot Password</Link>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={1} justifyContent="flex-end">
            {/* <Grid item>
              <Button
                type="submit"
                onClick={() => handleSSO()}
                color="primary"
                size="large"
                variant="contained"
              // disabled={auth.isFetching || !isDependenciesLoaded()}
              >
                Login With SSO{" "}
                {(ssoLoading || !isDependenciesLoaded()) && (
                  <CircularProgress color="secondary" size={15} style={{ marginLeft: "10px" }} />
                )}
              </Button>
            </Grid> */}
            <Grid item>
              <Button
                type="submit"
                onClick={login}
                color="secondary"
                size="large"
                variant="contained"
                disabled={auth.isFetching || !isDependenciesLoaded()}
              >
                Login{" "}
                {(auth.isFetching || !isDependenciesLoaded()) && (
                  <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                )}
              </Button>
            </Grid>
            <Grid container direction="row" justifyContent="center" alignItems="flex-start" style={{ marginTop: 50 }}>
              <img src={`/i18n/hertzlogo-dark.png`} height="50px" />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default Login
