import { takeLatest, put, call, all } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"

export function* getReservationAnalytics(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `query GetReservationAnalytics {
          GetReservationAnalytics(ReservationAnalyticsScope: WEEK) {
              allOpenReservations
              allOpenRentals
              allOverdueRentals
              reservationsCreated {
                  date
                  value
              }
              reservationsPerAgent {
                  date
                  value {
                      agent
                      value
                  }
              }
              rentalsOverdue {
                  date
                  value
              }
              rentalsStartPerStatus {
                  date
                  value {
                      status
                      value
                  }
              }
          }
      }`,
    }),
  }

  //console.log(config)

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.RESERVATION_ANALYTICS.GET.SUCCESS,
      types.RESERVATION_ANALYTICS.GET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* watchAnalytics() {
  yield all([yield takeLatest(types.RESERVATION_ANALYTICS.GET.REQUEST, getReservationAnalytics)])
}
