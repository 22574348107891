import { takeLatest, put, call } from "redux-saga/effects"
import callApi, { deleteCallApi } from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"

export function* getVehicleGroups(action) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `query GetVehicleGroups {
                GetVehicleGroups {
                    _id
                    name
                    active
                    vehicleModels {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.VEHICLE_GROUPS.GET.SUCCESS, types.VEHICLE_GROUPS.GET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* createVehicleGroup(actions) {
    const allowedString = JSON.stringify(actions.payload.vehicleGroup.vehicleModels.allowed)
    const deniedString = JSON.stringify(["*"]);
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation CreateVehicleGroups {
                CreateVehicleGroups(
                    name: "${actions.payload.vehicleGroup.name}"
                    vehicleModels: { allowed: ${allowedString}, denied: ${deniedString} }
                    active: true
                ) {
                    _id
                    name
                    active
                    vehicleModels {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")
    let callResponse = null;
    try {
        callResponse = yield call(callApi, () => fetch(uri, config), [types.VEHICLE_GROUPS.CREATE.SUCCESS, types.VEHICLE_GROUPS.CREATE.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }

    if (_.has(callResponse, "errors")) {
        yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
        yield put(appActions.showSuccessMessage("Success", `The vehicle group, ${actions.payload.vehicleGroup.name}, has been added`))
    }
}

export function* setVehicleGroup(actions) {
    const allowedString = JSON.stringify(actions.payload.vehicleGroup.vehicleModels.allowed);
    const deniedString = JSON.stringify(["*"]);
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation UpdateVehicleGroups {
                UpdateVehicleGroups(
                    _id: "${actions.payload.vehicleGroup._id}"
                    name: "${actions.payload.vehicleGroup.name}"
                    vehicleModels: { allowed: ${allowedString}, denied: ${deniedString} }
                    active: ${actions.payload.vehicleGroup.active}
                ) {
                    _id
                    name
                    active
                    vehicleModels {
                        allowed {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                        denied {
                            code
                            name
                            meta {
                                key
                                value
                            }
                        }
                    }
                }
            }   
            `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")
    let callResponse = null;
    try {
        callResponse = yield call(callApi, () => fetch(uri, config), [types.VEHICLE_GROUPS.SET.SUCCESS, types.VEHICLE_GROUPS.SET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }

    if (_.has(callResponse, "errors")) {
        yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
        yield put(appActions.showSuccessMessage("Success", `The vehicle group has been modified`))
    }
}

export function* deleteVehicleGroup(actions) {
    
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation DeleteVehicleGroups {
                        DeleteVehicleGroups(GroupIDs: ["${actions.payload.vehicleGroup._id}"]) {
                            message
                        }
                    }   
                    `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")
    let callResponse = null;
    try {
        callResponse = yield call(
            deleteCallApi,
            () => fetch(uri, config),
            [types.VEHICLE_GROUPS.DELETE.SUCCESS, types.VEHICLE_GROUPS.DELETE.FAILURE],
            "json",
            {params: actions.payload.vehicleGroup._id}
        )
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }

    if (_.has(callResponse, "errors")) {
        yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
        yield put(appActions.showSuccessMessage("Success", `The vehicle group has been deleted`))
    }
}

export function* watchVehicleGroupsRequest() {
    yield takeLatest(types.VEHICLE_GROUPS.GET.REQUEST, getVehicleGroups)
    yield takeLatest(types.VEHICLE_GROUPS.SET.REQUEST, setVehicleGroup)
    yield takeLatest(types.VEHICLE_GROUPS.CREATE.REQUEST, createVehicleGroup)
    yield takeLatest(types.VEHICLE_GROUPS.DELETE.REQUEST, deleteVehicleGroup)
}
