import * as types from "../constants/actionTypes"
import initialState from "./initialState"
import _ from "lodash"

const transformReservation = (res) => {
  return res.filter((r) => {
    return r.ReservationStatus != "filled"
  })
}

const transformRentals = (res) => {
  return res.filter((r) => {
    return r.ReservationStatus == "filled"
  })
}

export default function bookingsReducer(state = initialState.bookings, action) {
  switch (action.type) {
    case types.CLEAR_QUOTATION:
      // eslint-disable-next-line no-case-declarations
      const bookings = {
        ...state,
        ...initialState.bookings,
      }
      delete bookings.options
      return {
        ...bookings,
      }
    case types.LOCATIONS.GET.REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case types.GET_RESERVATIONS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case types.SET_RESERVATION_REQUEST:
      return {
        ...state,
        isCreatingReservation: true,
      }

    case types.MODIFY_RESERVATION_REQUEST:
      return {
        ...state,
        isEditingReservation: true,
      }

    case types.GET_RESERVATIONS_REQUEST_SUCCESS:
      return {
        ...state,
        reservations: transformReservation(action.result.data.ReservationSync),
        isFetching: false,
      }
    case types.GET_RESERVATION_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_RESERVATION_REQUEST_SUCCESS:
      {
        let reservations = state.reservations;
        const resResult = action.result.data.RetrieveReservation;
        let transformedRes = {
          ClaimNumber: resResult.ClaimNumber,
          ReferenceId: resResult.ReferenceId,
          ReservationNumber: resResult.ReservationNumber,
          ReservationStatus: resResult.ReservationStatus,
          PickUp: {
            DateTime: resResult.Pickup.DateTime,
            Location: resResult.Pickup.Location.locationCode,
          },
          DropOff: {
            DateTime: resResult.DropOff.DateTime,
            Location: resResult.DropOff.Location.locationCode,
          },
          RentalNumber: resResult.RentalNumber,
          RentalStatus: resResult.RentalStatus,
          LastModifiedTime: resResult.LastModifiedTime,
          StaffId: resResult.StaffId,
          PackageCode: resResult.PackageCode,
          Modifications: resResult.Modifications,
          DobOfYoungestDriver: resResult.DobOfYoungestDriver,
          VehicleClass: resResult.RateClass,
          VoucherClass: resResult.VoucherRate.Class
        }

        reservations = _.unionBy(reservations, [transformedRes], "ReservationNumber");
        return {
          ...state,
          reservation: action.result.data.RetrieveReservation,
          reservations: reservations,
          isDownloadingEmailTemplate: false,
          isFetching: false,
        }
      }
    case types.GET_RESERVATION_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case types.SET_RESERVATION_REQUEST_SUCCESS:
      return {
        ...state,
        confirmationNumber: action.result.data.CreateReservation.ConfirmationNumber,
        reservationConfirmed: action.result.data.CreateReservation.ReservationConfirmed,
        reservationNumber: action.result.data.CreateReservation.ReservationNumber,
        reservationStatus: action.result.data.CreateReservation.ReservationStatus,
        isCreatingReservation: false,
      }
    case types.SET_RESERVATION_REQUEST_FAILURE:
      return {
        ...state,
        isCreatingReservation: false,
      }

    case types.MODIFY_RESERVATION_REQUEST_SUCCESS:
      return {
        ...state,
        isEditingReservation: false,
        modifyReservationSuccess: true
      }
    case types.CLEAR_MODIFY_RESERVATION_SUCCESS:
      return {
        ...state,
        isEditingReservation: false,
        modifyReservationSuccess: undefined
      }

    case types.MODIFY_RESERVATION_REQUEST_FAILURE:
      return {
        ...state,
        isEditingReservation: false,
      }

    case types.RESERVATION.DELETE.REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case types.RESERVATION.DELETE.SUCCESS:
      return {
        ...state,
        cancelled: action.result.data.Success,
        isFetching: false,
      }

    case types.CLEAR_RESERVATIONS_REQUEST:
      return {
        ...state,
        reservations: null,
        isFetching: false,
      }

    case types.CLEAR_RESERVATION_REQUEST:
      return {
        ...state,
        reservation: null,
        isFetching: false,
      }

    case types.RENTAL.CLEAR.REQUEST:
      return {
        ...state,
        rental: null,
        isFetching: false,
      }

    case types.GET_VEHICLE_MODEL_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case types.GET_VEHICLE_MODEL_REQUEST_SUCCESS:
      return {
        ...state,
        vehicles: action.result.data.GetVehicleModels,
        success: true,
        isFetching: false,
      }

    case types.GET_VEHICLE_MODEL_REQUEST_FAILURE:
      return {
        ...state,
        success: false,
        isFetching: false,
      }

    case types.GET_RENTALS_REQUEST:
      return {
        ...state,
        isFetching: true,
      }

    case types.GET_RENTALS_REQUEST_SUCCESS:
      return {
        ...state,
        reservations: transformRentals(action.result.data.ReservationSync),
        rentalFilter: transformRentals(action.result.data.ReservationSync),
        isFetching: false,
      }
    case types.GET_RATESAVAILABILITY_REQUEST:
      return {
        ...state,
        referenceId: action.payload.referenceId,
        isFetchingRates: true,
      }
    case types.GET_RATESAVAILABILITY_REQUEST_SUCCESS:
      return {
        ...state,
        rates: action.result.data.GetRatesAvailability.Rates,
        isFetchingRates: false,
      }
    case types.GET_RATESAVAILABILITY_REQUEST_FAILURE:
      return {
        ...state,
        isFetchingRates: false,
        errors: action.result.errors[0].exceptions[0],
      }
    case types.GET_OPTIONS_REQUEST:
      return {
        ...state,
        isFetchingOptions: true,
      }
    case types.GET_OPTIONS_REQUEST_SUCCESS:
      return {
        ...state,
        options: {
          ...action.result.data.GetOptions.Options,
          delta: state.options != null ? state.options.delta + 1 : 0,
        },
        isFetchingOptions: false,
      }

    case types.GET_RENTAL_REQUEST:
      return {
        ...state,
        isFetching: true,
      }
    case types.GET_RENTAL_REQUEST_SUCCESS:
      {
        let currentReservations = state.reservations;
        let rentals = state.rentalFilter;
        const rentalResult = action.result.data.RetrieveRental;
        let transformed = {
          ClaimNumber: rentalResult.ClaimNumber,
          ReferenceId: rentalResult.ReferenceId,
          ReservationNumber: rentalResult.ReservationNumber,
          ReservationStatus: rentalResult.ReservationStatus,
          PickUp: {
            DateTime: rentalResult.Pickup.DateTime,
            Location: rentalResult.Pickup.Location.locationCode,
          },
          DropOff: {
            DateTime: rentalResult.DropOff.DateTime,
            Location: rentalResult.DropOff.Location.locationCode,
          },
          RentalNumber: rentalResult.RentalNumber,
          RentalStatus: rentalResult.RentalStatus,
          LastModifiedTime: rentalResult.LastModifiedTime,
          StaffId: rentalResult.StaffId,
          PackageCode: rentalResult.PackageCode,
          Modifications: rentalResult.Modifications,
          DobOfYoungestDriver: rentalResult.DobOfYoungestDriver,
          VehicleClass: rentalResult.RateClass,
          VoucherClass: rentalResult.Voucher.Class
        }

        currentReservations = _.unionBy(currentReservations, [transformed], "ReservationNumber");
        rentals = _.unionBy(rentals, [transformed], "RentalNumber");
        return {
          ...state,
          reservation: action.result.data.RetrieveRental,
          reservations: currentReservations,
          rentalFilter: rentals,
          isDownloadingEmailTemplate: false,
          isFetching: false,
        }
      }
    case types.GET_RENTAL_REQUEST_FAILURE:
      return {
        ...state,
        isFetching: false,
      }
    case types.ESTIMATE.GET.REQUEST:
      return {
        ...state,
        isFetchingEstimate: true,
      }
    case types.EXISTING_RES_ESTIMATE.GET.REQUEST:
      return {
        ...state,
        isFetchingEstimate: true,
      }
    case types.EXISTING_RES_ESTIMATE.GET.SUCCESS:
      return {
        ...state,
        estimate: action.result.data.GetReservationEstimate,
        isFetchingEstimate: false,
      }
    case types.EXISTING_RES_ESTIMATE.GET.FAILURE:
      return {
        ...state,
        isFetchingEstimate: false,
      }
    case types.ESTIMATE.GET.SUCCESS:
      return {
        ...state,
        estimate: action.result.data.GetReservationEstimate,
        isFetchingEstimate: false,
      }
    case types.ESTIMATE.GET.FAILURE:
      return {
        ...state,
        isFetchingEstimate: false,
      }
    case types.RENTAL_ESTIMATE.GET.REQUEST:
      return {
        ...state,
        isFetchingEstimate: true,
      }
    case types.RENTAL_ESTIMATE.GET.SUCCESS:
      return {
        ...state,
        estimate: action.result.data.GetRentalEstimate,
        isFetchingEstimate: false,
      }
    case types.RENTAL_ESTIMATE.GET.FAILURE:
      return {
        ...state,
        isFetchingEstimate: false,
      }
    case types.RENTAL.MODIFY.REQUEST:
      return {
        ...state,
        isFetchingModifyReservation: true,
      }
    case types.RENTAL.MODIFY.SUCCESS:
      return {
        ...state,
        modifiedRental: action.result.data.ModifyRental,
        isFetchingModifyReservation: false,
      }
    case types.RENTAL.MODIFY.FAILURE:
      return {
        ...state,
        isFetchingModifyReservation: false,
      }
    case "SETBOOKINGS":
      return {
        ...state,
        ...action.payload,
      }
    case "MODIFYRESULT":
      return {
        ...state,
        modifyIndex: action.payload.modifyIndex,
      }
    case "FAILED_EXTENSION":
      return {
        ...state,
        failedExtensions: [
          ...state.failedExtensions,
          {
            rentalNumber: action.payload.rental.RentalNumber,
            referenceId: action.payload.rental.ReferenceId,
            error: action.payload.error,
          },
        ],
      }
    case "SUCCESSFUL_EXTENSION":
      return {
        ...state,
        successfulExtensions: [
          ...state.successfulExtensions,
          {
            rentalNumber: action.payload.rental.RentalNumber,
          },
        ],
      }
    case "START_EXTENDING_RENTALS":
      return {
        ...state,
        bulkExtendFinished: false,
      }
    case "FINISH_EXTENDING_RENTALS":
      return {
        ...state,
        bulkExtendFinished: true,
      }
    case "CLEAR_FAILED_EXTENSIONS":
      return {
        ...state,
        failedExtensions: [],
      }
    case "CLEAR_SUCCESSFUL_EXTENSIONS":
      return {
        ...state,
        successfulExtensions: [],
      }
    case "CLEAR_ERRORS":
      return {
        ...state,
        errors: "",
      }
    case types.ESTIMATE.CLEAR.SUCCESS:
      return {
        ...state,
        estimate: null,
        isFetchingEstimate: false,
        isEditingReservation: false,
        originalItinerary: null,
        itinerary: null,
      }
    case types.LOGOUT_REQUEST:
      return {
        ...initialState.bookings,
      }
    case types.DOWNLOAD_CONFIRMATION_EMAIL:
      return {
        ...state,
        isDownloadingEmailTemplate: true
      }
    default:
      return state
  }
}
