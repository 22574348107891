/* eslint-disable no-unused-vars */

import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Typography } from "@material-ui/core"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import * as serviceManagerActions from "../../actions/serviceManagerActions"
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount"

export default function MaterialTableDemo() {
  const insuranceManagers = useSelector(
    state => state.serviceManagers.data
  )
  const isFetching = useSelector(
    state => state.serviceManagers.isFetching
  )


  function handleDemoteUser(rowData) {
    dispatch(
      serviceManagerActions.demoteManager(
        { 
          token: auth.token,
        },
        insuranceManagers[rowData.tableData.id]._id
      )
    )
  }

  const serviceManagers = useSelector(
    state => state.serviceManagers.data
  )

  const auth = useSelector(state => ({ ...state.auth }))
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(serviceManagerActions.getServiceManagers({ token: auth.token }))
  }, [])
  return (
    <div>
      <h2 style={{ paddingBottom: 0, marginBottom: 0, marginLeft: 5 }}>
        Company Managers
      </h2>
      <Typography
        style={{ color: "grey", fontSize: 13, marginBottom: 15, marginLeft: 5 }}
      >
        This page is used for demotion of insurance managers back into the
        regular user group.
      </Typography>
      <MaterialTable
        icons={tableIcons}
        title="Company Managers"
        isLoading={isFetching}
        columns={[
          { title: "Username", field: "username" },
          { title: "Email", field: "emailAddress" },
          { title: "First Name", field: "firstName" },
          { title: "Last Name", field: "lastName" }
        ]}
        data={
          serviceManagers ? serviceManagers.map(x => Object.assign({}, x)) : []
        }
        options={{ actionsColumnIndex: 4 }}
        actions={[
          rowData => ({
            // eslint-disable-next-line react/display-name
            icon: () => <SupervisorAccountIcon style={{ fontSize: 20 }} />,
            tooltip: <Typography>Demote to User</Typography>,
            onClick: event => handleDemoteUser(rowData, event)
          })
        ]}
      />
    </div>
  )
}
