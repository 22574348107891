import * as types from "../constants/actionTypes"
import initialState from "./initialState"

export default function analyticsReducer(state = initialState.analytics, action) {
  //console.log(action)
  switch (action.type) {
    case types.RESERVATION_ANALYTICS.GET.SUCCESS:
      return {
        ...state,
        loading: false,
        allOpenReservations: action.result.data.GetReservationAnalytics.allOpenReservations,
        allOpenRentals: action.result.data.GetReservationAnalytics.allOpenRentals,
        allOverdueRentals: action.result.data.GetReservationAnalytics.allOverdueRentals,
        reservationsCreated: action.result.data.GetReservationAnalytics.reservationsCreated,
        reservationsPerAgent: action.result.data.GetReservationAnalytics.reservationsPerAgent,
        rentalsStartPerStatus: action.result.data.GetReservationAnalytics.rentalsStartPerStatus,
        rentalsOverdue: action.result.data.GetReservationAnalytics.rentalsOverdue,
      }
    case types.RESERVATION_ANALYTICS.GET.FAILURE:
      return {
        ...state,
        loading: false,
      }
    case types.RESERVATION_ANALYTICS.GET.REQUEST:
      return {
        ...state,
        loading: true,
      }
    default:
      return state
  }
}
