import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"
import _ from "lodash"
// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  root: {
    "& label.MuiFormLabel-root": {
      backgroundColor: "#fff",
      marginLeft: "-5px",
      padding: "0 6px",
    },
  },
}))

const BookingConfirmation = () => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const bookings = useSelector((state) => ({ ...state.bookings }))
  const auth = useSelector((state) => ({ ...state.auth }))
  // const locations = useSelector(state => ({ ...state.locations }));
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const confirmationSpielTemplate = _.get(_.find(userInfo.settings.meta, { "key": "confirmationSpielTemplate" }), "value", {})
  // const [emailRecipient, setEmailRecipient] = React.useState({
  //   Hertz: false,
  //   Insurer: false,
  //   Customer: true
  // });

  // const emails = ["hertz@mail.com", "insurer@mail.com", "customer@mail.com"];

  // const handleChange = name => event => {
  //   setEmailRecipient({ ...emailRecipient, [name]: event.target.checked });
  // };

  // function getLocationName(lCode) {
  //   const location = locations.locations ? locations.locations.filter(l => l.locationCode.includes(lCode)) : null;
  //   return location ? location[0].locationName : "";
  // }

  // eslint-disable-next-line no-unused-vars
  const handleOnSend = (e) => {
    dispatch(
      bookingsActions.downloadConfirmationEmail({
        type: "createReservation",
        mode: "reservation",
        token: auth.token,
        reservationNumber: bookings.reservationNumber,
        rentalNumber: "",
        userInfo: userInfo,
      })
    )
  }

  return (
    <Grid container spacing={0} justify="center" className={classes.root}>
      <Grid container item xs={12} spacing={1} style={{textAlign: "justify"}}>
        <Typography variant="h6">{confirmationSpielTemplate.title}</Typography>
        <br />
        <div dangerouslySetInnerHTML={({__html:confirmationSpielTemplate.body})} />
      </Grid>
    </Grid>
  )
}

export default BookingConfirmation
