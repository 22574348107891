import { takeLatest, put, call } from "redux-saga/effects"
import callApi, { deleteCallApi } from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"
export function* getUserGroup(action) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: action.payload.token != "" ? `Bearer ${action.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `query GetUserGroups {
                GetUserGroups {
                    _id
                    name
                    active
                    itCodeGroups {
                        _id
                        name
                    }
                    locationGroups {
                        _id
                        name
                    }
                    vehicleModelGroups {
                        _id
                        name
                    }
                    meta {
                        key
                        value
                    }
                }
            } `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        yield call(callApi, () => fetch(uri, config), [types.USER_GROUPS.GET.SUCCESS, types.USER_GROUPS.GET.FAILURE])
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* createUserGroup(actions) {
    const { itCodeGroups, locationGroups, vehicleModelGroups, meta } = actions.payload.userGroup;
    const itGroupString = JSON.stringify(itCodeGroups);
    const locationGroupString = JSON.stringify(locationGroups);
    const vehiclGroupString = JSON.stringify(vehicleModelGroups);
    const metaString = meta.reduce((acc, e, i) => {
        if(i === meta.length - 1) {
            return acc.concat(`{key: "${e.key}", value: "${e.value}"}`)
        }
        return acc.concat(`{key: "${e.key}", value: "${e.value}"},`)
    }, "")
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation CreateUserGroup {
                    CreateUserGroup(
                        name: "${actions.payload.userGroup.name}"
                        itCodeGroups: ${itGroupString}
                        locationGroups: ${locationGroupString}
                        vehicleModelGroups: ${vehiclGroupString}
                        meta: [${metaString}]
                    ) {
                        _id
                        name
                        active
                        itCodeGroups {
                            _id
                            name
                        }
                        locationGroups {
                            _id
                            name
                        }
                        vehicleModelGroups {
                            _id
                            name
                        }
                    }
                }
            `
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        const callResponse = yield call(callApi, () => fetch(uri, config), [types.USER_GROUPS.CREATE.SUCCESS, types.USER_GROUPS.CREATE.FAILURE])
        if (_.has(callResponse, "errors")) {
            yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
        } else {
            yield put(appActions.showSuccessMessage("Success", "User group has been created"))
        }
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* setUserGroup(actions) {
    const { 
        _id,
        name,
        itCodeGroups,
        locationGroups,
        vehicleModelGroups,
        active,
        meta
    } = actions.payload.userGroup;
    const itGroupString = JSON.stringify(itCodeGroups);
    const locationGroupString = JSON.stringify(locationGroups);
    const vehiclGroupString = JSON.stringify(vehicleModelGroups);
    const metaString = meta.reduce((acc, e, i) => {
        if(i === meta.length - 1) {
            return acc.concat(`{key: "${e.key}", value: "${e.value}"}`)
        }
        return acc.concat(`{key: "${e.key}", value: "${e.value}"},`)
    }, "")
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation UpdateUserGroup {
                UpdateUserGroup(
                    _id: "${_id}"
                    name: "${name}"
                    itCodeGroups: ${itGroupString}
                    locationGroups: ${locationGroupString}
                    vehicleModelGroups: ${vehiclGroupString}
                    active: ${active}
                    meta: [${metaString}]
                ) {
                    _id
                    name
                    active
                    itCodeGroups {
                        _id
                        name
                    }
                    locationGroups {
                        _id
                        name
                    }
                    vehicleModelGroups {
                        _id
                        name
                    }
                    meta {
                        key
                        value
                    }
                }
            }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        const callResponse = yield call(callApi, () => fetch(uri, config), [types.USER_GROUPS.SET.SUCCESS, types.USER_GROUPS.SET.FAILURE])
        if (_.has(callResponse, "errors")) {
            yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
        } else {
            yield put(appActions.showSuccessMessage("Success", "User group has been modified"))
        }
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* deleteUserGroup(actions) {
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation DeleteUserGroups {
                        DeleteUserGroups(GroupIDs: ["${actions.payload.userGroup._id}"]) {
                            message
                        }
                    }   
                    `,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        const callResponse = yield call(
            deleteCallApi,
            () => fetch(uri, config),
            [types.USER_GROUPS.DELETE.SUCCESS, types.USER_GROUPS.DELETE.FAILURE],
            "json",
            {params: actions.payload.userGroup._id}
        )
        if (_.has(callResponse, "errors")) {
            yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
        } else {
            yield put(appActions.showSuccessMessage("Success", "User group has been deleted"))
        }
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* watchUserGroupsRequest() {
    yield takeLatest(types.USER_GROUPS.GET.REQUEST, getUserGroup)
    yield takeLatest(types.USER_GROUPS.SET.REQUEST, setUserGroup)
    yield takeLatest(types.USER_GROUPS.CREATE.REQUEST, createUserGroup)
    yield takeLatest(types.USER_GROUPS.DELETE.REQUEST, deleteUserGroup)
}
