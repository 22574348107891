/* eslint-disable no-useless-escape */
import { takeLatest, put, call, all } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"

// Load the full build.
function buildMutation(changed, data) {
  var iterator = function (result, value, key) {
    if (typeof value === "object") {
      if (_.isEqual(result[key], value)) {
        delete result[key]
      }
    } else {
      if (value == result[key]) {
        delete result[key]
      }
    }
  }
  if (_.has(changed, "serviceName")) {
    delete changed.serviceName
    delete changed.obtGenericEmailTemplate
    delete changed.serviceAdmins
    delete data.serviceName
    delete data.obtGenericEmailTemplate
    delete data.serviceAdmins
  }
  var diff = _.transform(data, iterator, changed)
  return JSON.stringify(diff)
}

function buildArray(data) {
  if (data) {
    const newData = data != 0 ? JSON.stringify(data).replace(/\"([^(\")"]+)\":/g, "$1:") : `[]`

    return newData
  }

  return "[]"
}



function getServiceSettingConfig(actions) {
  return {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `{     
          GetServiceSettings {
            itcodes {
                code
                name
                meta {
                    key
                    value
                }
            }
            locations {
                code
                name
                meta {
                    key
                    value
                }
            }
            meta {
              key
              value
                }
            vehicleModels {
              code
              name
              meta {
                  key
                  value
              }
            }
        }
        GetServiceManagers {
            _id
            username
            emailAddress
            staffId
            firstName
            lastName
            lastLogin
            userType
            favouriteLocations
            favouriteItcodes
            meta {
                key
                value
            }
            accountUserGroups {
                _id
                name
                active
            }
        }
        GetServiceUsers {
            _id
            username
            emailAddress
            staffId
            firstName
            lastName
            lastLogin
            userType
            favouriteLocations
            favouriteItcodes
            meta {
                key
                value
            }
            accountUserGroups {
                _id
                name
                active
            }
        }
        GetCurrentUser {
          _id
          username
          emailAddress
          staffId
          firstName
          lastName
          lastLogin
          userType
          favouriteLocations
          favouriteItcodes
          meta {
              key
              value
          }
          accountUserGroups {
              _id
              name
          }
        }
          GetITCodeGroups {
            _id
            name
            active
            itCodes {
                allowed {
                    code
                    name
                    deniedLocations
                    deniedVehicleModels
                    meta {
                        key
                        value
                    }
                }
                denied {
                    code
                    name
                    deniedLocations
                    deniedVehicleModels
                    meta {
                        key
                        value
                    }
                }
            }
        }
      }`,
    }),
  }
}
export function* getServiceSettings(actions) {
  const config = getServiceSettingConfig(actions)

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.GET_SERVICE_SETTINGS.GET.SUCCESS, types.GET_SERVICE_SETTINGS.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* getUserSettings(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `query {
          GetServiceManagers {
              _id
              username
              emailAddress
              staffId
              firstName
              lastName
              lastLogin
              userType
              favouriteLocations
              favouriteItcodes
              meta {
                  key
                  value
              }
              accountUserGroups {
                  _id
                  name
                  active
              }
          }
          GetServiceUsers {
              _id
              username
              emailAddress
              staffId
              firstName
              lastName
              lastLogin
              userType
              favouriteLocations
              favouriteItcodes
              meta {
                  key
                  value
              }
              accountUserGroups {
                  _id
                  name
                  active
              }
          }
      }
      `,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.USER_SETTINGS.GET.SUCCESS, types.USER_SETTINGS.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* createUser(actions) {
  const itGroups = buildArray(actions.payload.itGroups)
  const locations = buildArray(actions.payload.locations)
  const vehicles = buildArray(actions.payload.vehicles)
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
                OBT( UpdateServiceSettings: {
                        addUser: {
                            emailAddress: "${actions.payload.emailAddress}"
                            username: "${actions.payload.emailAddress}"
                            userType: SERVICE_USER
                            userFilters: {
                              allowedLocations: ${locations}
                              allowedItCodeGroups: ${itGroups}
                              allowedVehicleGroups: ${vehicles}
                            }
                        }}){
                    serviceRules {
                        serviceManagers {
                            username
                            emailAddress
                            staffId
                            userType
                            firstName
                            lastName
                            lastLogin
                            allowedLocations
                            allowedItCodes
                            allowedItCodeGroups
                            allowedVehicleGroups
                        }
                        serviceUsers {
                          username
                          emailAddress
                          staffId
                          userType
                          firstName
                          lastName
                          lastLogin
                          allowedLocations
                          allowedItCodes
                          allowedItCodeGroups
                          allowedVehicleGroups
                        }
                    }
                }
            }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let apiCall = null
  try {
    apiCall = yield call(callApi, () => fetch(uri, config), [
      types.USER_SETTINGS.CREATE.SUCCESS,
      types.USER_SETTINGS.CREATE.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(apiCall, "errors")) {
    yield put({
      type: types.SHOW_MESSAGE,
      payload: {
        title: "Error",
        text: apiCall.errors[0].exceptions[0] + ", " + `${actions.payload.emailAddress}`,
      },
    })
  } else {
    yield put({
      type: types.SHOW_MESSAGE,
      success: true,
      payload: {
        title: "Success",
        text: `Users were created`,
      },
    })
  }
}

function createDemoteUserBody(actions) {
  return JSON.stringify({
    query: `mutation {
          DemoteUser(_id: "${actions.payload._id}") {
              _id
          }
      }`
  });
}

function createPromoteUserBody(actions) {
  return JSON.stringify({
    query: `mutation {
          PromoteUser(_id: "${actions.payload._id}") {
              _id
          }
      }`
  });
}

export function* updateUser(actions) {
  /* const allowedLocations = buildArray(actions.payload.locations)
  const allowedItCodeGroups = buildArray(actions.payload.itGroups)
  const allowedVehicleGroups = buildArray(actions.payload.vehicles) */
  const bodies = {
    "demote_user": createDemoteUserBody(actions),
    "prmote_user": createPromoteUserBody(actions),
  }
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: bodies[actions.payload.action]
  }
  const serviceSettingConfig = getServiceSettingConfig(actions);
  const uri = url.resolve(API_SERVER_URL, "")
  let apiCall = null
  try {
    const callback = () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, serviceSettingConfig);
        }
        return res
      })
    apiCall = yield call(callApi, callback, [
      types.SERVICE_USER.SET.SUCCESS,
      types.SERVICE_USER.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(apiCall, "errors")) {
    yield put({
      type: types.SHOW_MESSAGE,
      payload: {
        title: "Error",
        text: apiCall.errors[0].exceptions[0] + ", " + `${actions.payload.emailAddress}`,
      },
    })
  } else {
    yield put({
      type: types.SHOW_MESSAGE,
      success: true,
      payload: {
        title: "Success",
        text: "User Updated",
      },
    })
  }
}

export function* updateUserProfile(actions) {
  const body = JSON.stringify({
    query: `mutation UpdateProfile {
        UpdateProfile(
            emailAddress: "${actions.payload.user.emailAddress}"
            firstName: "${actions.payload.user.firstName}"
            lastName: "${actions.payload.user.lastName}"
        ) {
            _id
            username
            emailAddress
            staffId
            firstName
            lastName
            lastLogin
            userType
            favouriteLocations
            favouriteItcodes
            meta {
              key
              value
            }
            accountUserGroups {
                _id
                name
                active
                itCodeGroups {
                    _id
                    name
                }
                locationGroups {
                    _id
                    name
                }
                vehicleModelGroups {
                    _id
                    name
                }
                meta {
                    key
                    value
                }
            }
        }
    }`
  })
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse = null;
  try {
    callResponse = yield call(callApi, () => fetch(uri, config), [types.USER_PROFILE.SET.SUCCESS, types.USER_PROFILE.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors")) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", `The profile has been updated`))
  }
}

export function* setFavouriteLocation(actions) {
  const fave =
    actions.payload.favouriteLocations.length != 0
      ? `favouriteLocations: ` + JSON.stringify(actions.payload.favouriteLocations).replace(/\"([^(\")"]+)\":/g, "$1:")
      : ``
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `  mutation {
                    OBT( 
                        UpdateUserSettings: {
                            ${fave}
                        } 
                    ){
                        success
                        user {
                            favouriteLocations
                        }
                    }
                }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.FAVOURITE_LOCATIONS.SET.SUCCESS,
      types.FAVOURITE_LOCATIONS.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setUserSettings(actions) {
  const { disallowedLocations, disallowedVehicleModels } = actions.payload.changed
  const defaultDisallowedLocations = JSON.stringify(actions.payload.settings.meta
    .find(e => e.key === "disallowedLocations")
    .value
    .map(e => e.code))
  const defaultDisallowedVehicleModels = JSON.stringify(actions.payload.settings.meta
    .find(e => e.key === "disallowedVehicleModels")
    .value
    .map(e => e.code))
  const locations = disallowedLocations === null || disallowedLocations === undefined ? defaultDisallowedLocations : JSON.stringify(disallowedLocations);
  const vehicleModels = disallowedVehicleModels === null || disallowedVehicleModels === undefined ? defaultDisallowedVehicleModels : JSON.stringify(disallowedVehicleModels);
  const body = JSON.stringify({
    query: `mutation UpdateServiceSettings {
          UpdateServiceSettings(
              locations: ${locations}
              vehicleModels: ${vehicleModels}
          ) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
                  meta {
                      key
                      value
                  }
              }
          }
      }`
  })
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body,
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.USER_SETTINGS.SET.SUCCESS, types.USER_SETTINGS.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setBusinessRules(actions) {
  const args =
    `Update` +
    `BusinessSettings` +
    `: ` +
    buildMutation(actions.payload.changed, actions.payload.settings).replace(/\"([^(\")"]+)\":/g, "$1:")

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
                OBT(${args}){
                  success 
                  businessRules {
                    termsAndConditions
                    itCodes{
                        itCode
                        name
                        riderName
                    }
                    riderPdfLocations {
                      riderName
                      pdfLocation
                    }
                    notificationDashboardTemplate
                    disallowedVehicleGroups
                    disallowedLocations
                    termsAndConditions
                    maxRentalCost
                    maxRentalDays
                    defaultRentalLength
                    allowOneWay
                    allowPickupAndDropoff
                    availableAggregateVehicleGroups {
                      name
                      description
                    }
                  }
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.BUSINESS_RULES.SET.SUCCESS, types.BUSINESS_RULES.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* createItGroup(actions) {
  const allowedEmpty = _.isEmpty(actions.payload.itCodes.allowed);
  const deniedEmpty = _.isEmpty(actions.payload.itCodes.denied);
  const emptyArr = JSON.stringify([]);
  const allowed = allowedEmpty ? emptyArr : JSON.stringify(actions.payload.itCodes.allowed.map(e => e.code));
  const denied = deniedEmpty ? '["*"]' : JSON.stringify(actions.payload.itCodes.denied.map(e => e.code));
  const freshITGroups = freshITGroupsConfig(actions);
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation CreateITCodeGroups {
          CreateITCodeGroups(
              name: "${actions.payload.name}"
              itCodes: { allowed: ${allowed}, denied: ${denied} }
              active: true
          ) {
              _id
              name
              active
          }
      }
      `,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse;
  try {
    const callback = () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, freshITGroups);
        }
        return res
      })
    callResponse = yield call(callApi, callback, [types.IT_GROUP.CREATE.SUCCESS, types.IT_GROUP.CREATE.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors") || _.isEmpty(callResponse)) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", "The IT Code Group has been created"))
  }
}

export function* setItGroup(actions) {
  const itCodes = _.isEmpty(actions.payload.itCodes)
    ? JSON.stringify(actions.payload.itGroup.itCodes.allowed)
    : JSON.stringify(actions.payload.itCodes);
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation UpdateITCodeGroups {
          UpdateITCodeGroups(
              _id: "${actions.payload.itGroup._id}"
              itCodes: { allowed: ${itCodes} }
              name: "${actions.payload.itGroup.name}"
          ) {
              _id
              name
              itCodes {
                  allowed {
                      code
                      name
                      deniedLocations
                      deniedVehicleModels
                      meta {
                          key
                          value
                      }
                  }
                  denied {
                      code
                      name
                      deniedLocations
                      deniedVehicleModels
                      meta {
                          key
                          value
                      }
                  }
              }
          }
      }`
    }),
  }

  const freshITGroups = freshITGroupsConfig(actions)


  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse;
  try {
    const callback = () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, freshITGroups);
        }
        return res
      })
    callResponse = yield call(callApi, callback, [types.IT_GROUP.SET.SUCCESS, types.IT_GROUP.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors") || _.isEmpty(callResponse)) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", "The IT Code Group has been modified"))
  }
}

function freshITGroupsConfig(actions) {
  return {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `
        query {
          GetITCodeGroups {
            _id
            name
            active
            itCodes {
              allowed {
                code
                name
                deniedLocations
                deniedVehicleModels
                meta {
                  key
                  value
                }
              }
              denied {
                code
                name
                deniedLocations
                deniedVehicleModels
                meta {
                  key
                  value
                }
              }
            }
          }
        }
    `})
  }
}

export function* disableItGroup(actions) {
  const freshITGroups = freshITGroupsConfig(actions);
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation UpdateITCodeGroups {
          UpdateITCodeGroups(_id: "${actions.payload.itGroup._id}", active: false) {
              _id
              name
              active
          }
      }
      `,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse
  try {
    const callback = () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, freshITGroups);
        }
        return res
      })
    callResponse = yield call(callApi, callback, [types.IT_GROUP.DISABLE.SUCCESS, types.IT_GROUP.DISABLE.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors") || _.isEmpty(callResponse)) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", "The IT Code Group has been disabled"))
  }
}

export function* enableItGroup(actions) {
  const freshITGroups = freshITGroupsConfig(actions);
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation UpdateITCodeGroups {
          UpdateITCodeGroups(_id: "${actions.payload.itGroup._id}", active: true) {
              _id
              name
              active
          }
      }
      `,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse
  try {
    const callback = () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, freshITGroups);
        }
        return res
      })
    callResponse = yield call(callApi, callback, [types.IT_GROUP.ENABLE.SUCCESS, types.IT_GROUP.ENABLE.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors") || _.isEmpty(callResponse)) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", "The IT Code Group has been enabled"))
  }
}

export function* deleteItGroup(actions) {
  const groupIDs = JSON.stringify([actions.payload.itGroup._id]);
  const freshITGroups = freshITGroupsConfig(actions);
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation DeleteITCodeGroups {
          DeleteITCodeGroups(GroupIDs: ${groupIDs}) {
              message
          }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let callResponse;
  try {
    callResponse = yield call(callApi, () => fetch(uri, config)
      .then((res) => {
        if (!_.has(res, "errors")) {
          return fetch(uri, freshITGroups);
        }
        return res
      }), [types.IT_GROUP.DELETE.SUCCESS, types.IT_GROUP.DELETE.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }

  if (_.has(callResponse, "errors") || _.isEmpty(callResponse)) {
    yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
  } else {
    yield put(appActions.showSuccessMessage("Success", "The IT Code Group has been deleted"))
  }
}

export function* setItCodes(actions) {
  const itCodeWRiders = actions.payload.itCodes.map(e => {
    return {
      itCode: e.code,
      name: e.name,
      riderName: e.riderName
    }
  })
  const itCodes =
    actions.payload.itCodes.length != 0
      ? `itCodes: ` + JSON.stringify(itCodeWRiders).replace(/\"([^(\")"]+)\":/g, "$1:")
      : ``

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation UpdateServiceSettings {
          UpdateServiceSettings(${itCodes}) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
                  meta {
                      key
                      value
                  }
              }
          }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")
  let result;
  try {
    result = yield call(callApi, () => fetch(uri, config), [types.IT_CODES.SET.SUCCESS, types.IT_CODES.SET.FAILURE])
  } catch (error) {
    const message = _.isEmpty(error.message) ? "Something went wrong. Please contact support" : error.message
    yield put(appActions.showMessage("Request Error", message, "danger"))
  }
  if (_.has(result, "errors")) {
    yield put({ type: types.IT_CODES.SET.FAILURE });
    yield put(appActions.showMessage("Request Error", result.errors[0].exceptions[0], "danger"))
  }
}

export function* getItCodes(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `query getItCodes{
                OBT {
                  businessRules {
                    itCodes {
                      itCode
                      name
                      riderName
                    }
                  }
                  serviceRules {
                    itCodes {
                      itCode
                      name
                    }
                  }
                  success
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.IT_CODES.GET.SUCCESS, types.IT_CODES.GET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* updateNotifications(actions) {
  const notifications =
    actions.payload.notifications.length > 0
      ? `rentalErrorMessageHistory: ` +
      JSON.stringify(actions.payload.notifications).replace(/\"([^(\")"]+)\":/g, "$1:")
      : `rentalErrorMessageHistory: []`
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        OBT(UpdateUserSettings: {
          ${notifications}
        })
        {
          user {
            rentalErrorMessageHistory{
              referenceId
              error
            }
        }
      }
    }`,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.NOTIFICATION.SET.SUCCESS, types.NOTIFICATION.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Login Error:", error.message, "danger"))
  }
}

export function* setAllowOneWay(actions) {
  const allowOneWay = actions.payload.allowOneWay
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
          UpdateServiceSettings(allowOneWay: ${allowOneWay}) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
                  meta {
                      key
                      value
                  }
              }
          }
      }
      `,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.ALLOW_ONE_WAY.SET.SUCCESS, types.ALLOW_ONE_WAY.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error ", error.message, "danger"))
  }
}

export function* setAllowPickupAndDropoff(actions) {
  const allowPickupAndDropoff = actions.payload.allowPickupAndDropoff

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
          UpdateServiceSettings(allowPickupAndDropoff: ${allowPickupAndDropoff}) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
                  meta {
                      key
                      value
                  }
              }
          }
      }`,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.ALLOW_PICKUP_AND_DROPOFF.SET.SUCCESS,
      types.ALLOW_PICKUP_AND_DROPOFF.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error ", error.message, "danger"))
  }
}

export function* setConfirmationSpielTemplate(actions) {
  const confirmationSpielTemplate = JSON
    .stringify(actions.payload.confirmationSpielTemplate)
    .replace(/\"([^(\")"]+)\":/g, "$1:")

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
          UpdateServiceSettings(confirmationSpielTemplate: ${confirmationSpielTemplate}) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
                  meta {
                      key
                      value
                  }
              }
          }
      }`,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")
  try {
    const callResponse = yield call(callApi, () => fetch(uri, config), [
      types.CONFIRMATION_SPIEL_TEMPLATE.SET.SUCCESS,
      types.CONFIRMATION_SPIEL_TEMPLATE.SET.FAILURE,
    ])
    
    if (_.has(callResponse, "errors")) {
      yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
      yield put(appActions.showSuccessMessage("Success", "Confirmation spiel template has been modified"))
    }
  } catch (error) {
    yield put(appActions.showMessage("Request Error ", error.message, "danger"))
  }
}

export function* setBusinessServiceSettings(actions) {
  const args =
    `Update` +
    `BusinessSettings` +
    `: ` +
    buildMutation(actions.payload.changed, actions.payload.settings).replace(/\"([^(\")"]+)\":/g, "$1:")

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
                OBT(${args}){
                  success 
                  businessRules {
                    allowOneWay
                    allowPickupAndDropoff
                  }
                }
              }`,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.SERVICE_SETTINGS.SET.SUCCESS,
      types.SERVICE_SETTINGS.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error ", error.message, "danger"))
  }
}

export function* setUserItCodeGroups(actions) {
  const itCodeGroups = buildArray(actions.payload.itCodeGroups)

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        OBT( UpdateServiceSettings: {
              updateUser: {
                emailAddress: "${actions.payload.emailAddress}"
                userFilters: {
                  allowedItCodeGroups: ${itCodeGroups}
                }
              }
            }
        ){
            serviceRules {
              serviceManagers{
                username
                emailAddress
                staffId
                userType
                firstName
                lastName
                lastLogin
                allowedLocations
                allowedItCodes
                allowedItCodeGroups
                allowedVehicleGroups
              }
              serviceUsers {
                username
                emailAddress
                staffId
                userType
                firstName
                lastName
                lastLogin
                allowedLocations
                allowedItCodes
                allowedItCodeGroups
                allowedVehicleGroups
              }
            }
          }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.USER_IT_CODE_GROUPS.SET.SUCCESS,
      types.USER_IT_CODE_GROUPS.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setUserLocations(actions) {
  const locations = buildArray(actions.payload.locations)

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        OBT( UpdateServiceSettings: {
              updateUser: {
                emailAddress: "${actions.payload.emailAddress}"
                userFilters: {
                  allowedLocations: ${locations}
                }
              }
            }
        ){
            serviceRules {
              serviceManagers{
                username
                emailAddress
                staffId
                userType
                firstName
                lastName
                lastLogin
                allowedLocations
                allowedItCodes
                allowedItCodeGroups
                allowedVehicleGroups
              }
              serviceUsers {
                username
                emailAddress
                staffId
                userType
                firstName
                lastName
                lastLogin
                allowedLocations
                allowedItCodes
                allowedItCodeGroups
                allowedVehicleGroups
              }
            }
          }
        }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.USER_LOCATIONS.SET.SUCCESS, types.USER_LOCATIONS.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* setAllowedAggregateVehicleGroups(actions) {
  const vehicleCodes = buildArray(actions.payload.allowedVehicleGroups)
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        OBT( UpdateServiceSettings: {
          allowedAggregateVehicleGroups: ${vehicleCodes}
        }){
          success
          serviceRules{
            allowedAggregateVehicleGroups
          }
        }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.SUCCESS,
      types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

function* setAvailableAggregateVehicleGroups(actions) {
  let vehicleGroups = buildArray(actions.payload.availableVehicleGroups)
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation UpdateServiceSettings {
          UpdateServiceSettings(availableAggregateVehicleGroups: ${vehicleGroups}) {
              itcodes {
                  code
                  name
                  deniedLocations
                  deniedVehicleModels
                  meta {
                      key
                      value
                  }
              }
              locations {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              vehicleModels {
                  code
                  name
                  meta {
                      key
                      value
                  }
              }
              meta {
                  key
                  value
              }
              assets {
                  name
                  description
                  url
              }
          }
      }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [
      types.AVAILABLE_AGGREGATE_VEHICLE_GROUPS.SET.SUCCESS,
      types.AVAILABLE_AGGREGATE_VEHICLE_GROUPS.SET.FAILURE,
    ])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setUserVehicles(actions) {
  const vehicles = buildArray(actions.payload.vehicles)

  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
              OBT( UpdateServiceSettings: {
                    updateUser: {
                      emailAddress: "${actions.payload.emailAddress}"
                      userFilters: {
                        allowedVehicleGroups: ${vehicles}
                      }
                    }
                  }
              ){
                  serviceRules {
                    serviceManagers{
                      username
                      emailAddress
                      staffId
                      userType
                      firstName
                      lastName
                      lastLogin
                      allowedLocations
                      allowedItCodes
                      allowedItCodeGroups
                      allowedVehicleGroups
                    }
                    serviceUsers {
                      username
                      emailAddress
                      staffId
                      userType
                      firstName
                      lastName
                      lastLogin
                      allowedLocations
                      allowedItCodes
                      allowedItCodeGroups
                      allowedVehicleGroups
                    }
                  }
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.USER_VEHICLES.SET.SUCCESS, types.USER_VEHICLES.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setUserPassword(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation updatePassword {
                OBT( UpdateUserSettings: {
                      setPassword: "${actions.payload.password}"
                    }
                ){
                  success
                  user {
                    passwordResetRequired
                  }
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.USER_PASSWORD.SET.SUCCESS, types.USER_PASSWORD.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* setResetRequired(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation updateResetRequired {
                OBT( UpdateServiceSettings: {
                  updateUser: {
                    emailAddress: "${actions.payload.emailAddress}"
                    triggerPasswordReset: true
                  }
                }
                ){
                  success
                }
              }`,
    }),
  }

  const uri = url.resolve(API_SERVER_URL, "")

  try {
    yield call(callApi, () => fetch(uri, config), [types.RESET_REQUIRED.SET.SUCCESS, types.RESET_REQUIRED.SET.FAILURE])
  } catch (error) {
    yield put(appActions.showMessage("Request Error", error.message, "danger"))
  }
}

export function* saveEmailTemplates(actions) {
  const config = {
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
    },
    method: "POST",
    body: JSON.stringify({
      query: `mutation {
        UpdateServiceSettings(
            defaultEmailBCCAddresses: ${JSON.stringify(actions.payload.changed.defaultEmailBCCAddresses ? actions.payload.changed.defaultEmailBCCAddresses : [])},
            emailTemplates:{
                createReservation:{
                  subject:${JSON.stringify(
        actions.payload.changed.createReservation && actions.payload.changed.createReservation.subject
          ? actions.payload.changed.createReservation.subject
          : ""
      )}
                  body:${JSON.stringify(
        actions.payload.changed.createReservation && actions.payload.changed.createReservation.body
          ? actions.payload.changed.createReservation.body
          : ""
      )}
                },
                modifyReservation:{
                  subject:${JSON.stringify(
        actions.payload.changed.modifyReservation && actions.payload.changed.modifyReservation.subject
          ? actions.payload.changed.modifyReservation.subject
          : ""
      )}
                  body:${JSON.stringify(
        actions.payload.changed.modifyReservation && actions.payload.changed.modifyReservation.body
          ? actions.payload.changed.modifyReservation.body
          : ""
      )}
                },
                cancelReservation:{
                  subject:${JSON.stringify(
        actions.payload.changed.cancelReservation && actions.payload.changed.cancelReservation.subject
          ? actions.payload.changed.cancelReservation.subject
          : ""
      )}
                  body:${JSON.stringify(
        actions.payload.changed.cancelReservation && actions.payload.changed.cancelReservation.body
          ? actions.payload.changed.cancelReservation.body
          : ""
      )}
                },
                modifyRental:{
                  subject:${JSON.stringify(
        actions.payload.changed.modifyRental && actions.payload.changed.modifyRental.subject
          ? actions.payload.changed.modifyRental.subject
          : ""
      )}
                  body:${JSON.stringify(
        actions.payload.changed.modifyRental && actions.payload.changed.modifyRental.body
          ? actions.payload.changed.modifyRental.body
          : ""
      )}
                }
            }
          
        ){
          itcodes {
            code
            name
            deniedLocations
            deniedVehicleModels
            meta {
                key
                value
            }
          }
          locations {
            code
            name
            meta {
                key
                value
            }
          }
          vehicleModels {
            code
            name
            meta {
                key
                value
            }
          }
          meta {
            key
            value
          }
          assets {
            name
            description
            url
            meta {
                key
                value
            }
          }
        }
      }`,
    }),
  }
  const uri = url.resolve(API_SERVER_URL, "")
  try {
    const callResponse = yield call(callApi, () => fetch(uri, config), ["saveEmailTemplates_SUCCESS", "saveEmailTemplates_FAILURE"])
    if (_.has(callResponse, "errors")) {
      yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
    } else {
      yield put(appActions.showSuccessMessage("Success", "The email templates have been saved."))
    }
  } catch (error) {
    yield put(appActions.showMessage("Request Error ", error.message, "danger"))
  }
}

export function* watchUserInfo() {
  yield all([
    yield takeLatest(types.USER_SETTINGS.CREATE.REQUEST, createUser),
    yield takeLatest(types.USER_SETTINGS.GET.REQUEST, getUserSettings),
    yield takeLatest(types.GET_SERVICE_SETTINGS.GET.REQUEST, getServiceSettings),
    yield takeLatest(types.USER_SETTINGS.SET.REQUEST, setUserSettings),
    yield takeLatest(types.FAVOURITE_LOCATIONS.SET.REQUEST, setFavouriteLocation),
    yield takeLatest(types.IT_GROUP.SET.REQUEST, setItGroup),
    yield takeLatest(types.IT_CODES.SET.REQUEST, setItCodes),
    yield takeLatest(types.IT_CODES.GET.REQUEST, getItCodes),
    yield takeLatest(types.IT_GROUP.CREATE.REQUEST, createItGroup),
    yield takeLatest(types.IT_GROUP.DISABLE.REQUEST, disableItGroup),
    yield takeLatest(types.IT_GROUP.ENABLE.REQUEST, enableItGroup),
    yield takeLatest(types.IT_GROUP.DELETE.REQUEST, deleteItGroup),
    yield takeLatest(types.SERVICE_USER.SET.REQUEST, updateUser),
    yield takeLatest(types.NOTIFICATION.SET.REQUEST, updateNotifications),
    yield takeLatest(types.BUSINESS_RULES.SET.REQUEST, setBusinessRules),
    yield takeLatest(types.SERVICE_SETTINGS.SET.REQUEST, setBusinessServiceSettings),
    yield takeLatest(types.USER_IT_CODE_GROUPS.SET.REQUEST, setUserItCodeGroups),
    yield takeLatest(types.USER_LOCATIONS.SET.REQUEST, setUserLocations),
    yield takeLatest(types.USER_VEHICLES.SET.REQUEST, setUserVehicles),
    yield takeLatest(types.USER_PASSWORD.SET.REQUEST, setUserPassword),
    yield takeLatest(types.RESET_REQUIRED.SET.REQUEST, setResetRequired),
    yield takeLatest(types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.REQUEST, setAllowedAggregateVehicleGroups),
    yield takeLatest(types.AVAILABLE_AGGREGATE_VEHICLE_GROUPS.SET.REQUEST, setAvailableAggregateVehicleGroups),
    yield takeLatest(types.ALLOW_ONE_WAY.SET.REQUEST, setAllowOneWay),
    yield takeLatest(types.ALLOW_PICKUP_AND_DROPOFF.SET.REQUEST, setAllowPickupAndDropoff),
    yield takeLatest(types.CONFIRMATION_SPIEL_TEMPLATE.SET.REQUEST, setConfirmationSpielTemplate),
    yield takeLatest("saveEmailTemplates", saveEmailTemplates),
    yield takeLatest(types.USER_PROFILE.SET.REQUEST, updateUserProfile)
  ])
}
