/* eslint-disable react/prop-types */
import React, { useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { Container, Tabs, Tab } from "@material-ui/core"

import ItCodes from "./ItCodes"
import ITGroups from "../partials/ItGroups"
import Vehicles from "../partials/Vehicles"
import Locations from "../partials/Locations"
import LocationGroups from "../partials/LocationGroups"
import VehicleGroups from "../partials/VehicleGroups"
import InsuranceManagers from "../partials/InsuranceManagers"
import ServiceSettings from "../partials/ServiceSettings"
import UserSettings from "../partials/UserSettings"
import AddUsers from "../partials/AddUsers"
import userGroups from "../partials/userGroups"

import { Route, Switch } from "react-router-dom"
import * as userInfoActions from "../../actions/userInfoActions"
import _ from "lodash"

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    height: "calc(100vh - 64px)"
  },
  mainpane: {
    paddingTop: "20px",
    maxWidth: "100%",
    background: "#f0f0f0"
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`
  }
}))

const ControlPanelTabs = props => {
  const auth = useSelector(state => ({ ...state.auth }))
  const classes = useStyles()
  const [controlPanelTab, setControlPanelTab] = React.useState(
    props.location.pathname
  )
  const dispatch = useDispatch()
  const base = location.pathname.split("/")[1]
  const userType = useSelector(state => _.get(state,"userInfo.settings.user.userType",""))

  function handleControlPanelTabChange(event, newValue) {
    dispatch(push({ pathname: newValue }))
    setControlPanelTab(newValue)
  }

  useEffect(() => {
    dispatch(userInfoActions.getServiceSettings({ token: auth.token }))
  }, [])

  return (
    <div className={classes.root}>
      {userType != "SERVICE_USER" && (
        <React.Fragment>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={controlPanelTab}
            onChange={handleControlPanelTabChange}
            aria-label="Vertical tabs example"
            className={classes.tabs}
          >
            <Tab
              value={"/" + base + "/controlpanel/itcodes"}
              label="IT Codes"
            />
            <Tab
              value={"/" + base + "/controlpanel/itgroups"}
              label="   IT Groups"
            />
            <Tab
              value={"/" + base + "/controlpanel/locations"}
              label="Locations"
            />
            <Tab
              value={"/" + base + "/controlpanel/locationGroups"}
              label="Location Groups"
            />
            <Tab
              value={"/" + base + "/controlpanel/vehicles"}
              label="Vehicles"
            />
            <Tab
              value={"/" + base + "/controlpanel/vehicleGroups"}
              label="Vehicle Groups"
            />
            <Tab
              value={"/" + base + "/controlpanel/adduser"}
              label="Add Users"
            />
            <Tab
              value={"/" + base + "/controlpanel/managers"}
              label="Managers"
            />
            <Tab
              value={"/" + base + "/controlpanel/users"}
              label="Users"
            />            
            <Tab
              value={"/" + base + "/controlpanel/usergroups"}
              label="User Groups"
            />           
            
            <Tab
              value={"/" + base + "/controlpanel/settings"}
              label="Settings"
            />
          </Tabs>
          <Container className={classes.mainpane}>
            <Switch>
              <Route
                exact
                path={"/" + base + "/controlpanel/itcodes"}
                component={ItCodes}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/itgroups"}
                component={ITGroups}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/vehicles"}
                component={Vehicles}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/vehicleGroups"}
                component={VehicleGroups}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/locations"}
                component={Locations}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/locationGroups"}
                component={LocationGroups}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/managers"}
                component={InsuranceManagers}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/settings"}
                component={ServiceSettings}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/usergroups"}
                component={userGroups}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/adduser"}
                component={AddUsers}
              />
              <Route
                exact
                path={"/" + base + "/controlpanel/users"}
                component={UserSettings}
              />
            </Switch>
          </Container>
        </React.Fragment>
      )}
    </div>
  )
}

export default ControlPanelTabs
