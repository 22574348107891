/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useEffect, Fragment } from "react"
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers"
import GoogleMapBox from "../partials/GoogleMapBox"
import PopUpLocationSearch from "./PopUpLocationSearch"
import {
  Backdrop,
  Grid,
  Box,
  Button,
  makeStyles,
  TextField,
  Modal,
  Fade,
  InputAdornment,
  IconButton,
  MenuItem,
} from "@material-ui/core"
import SearchIcon from "@material-ui/icons/Search"
import EventIcon from "@material-ui/icons/Event"
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline"
import _ from "lodash"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import { userLogic } from "../../businesslogic"
import Divider from "@material-ui/core/Divider"
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline"
import CloseIcon from "@material-ui/icons/Close"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import CircularProgress from "@material-ui/core/CircularProgress"
import { HERTZ_YELLOW } from "../../constants/style"
import { useSelector, useDispatch } from "react-redux"
import * as bookingsActions from "../../actions/bookingsActions"
import EstimateCard from "./EstimateCard"
import { i18n } from "../i18n"

const LocationModal = (props) => {
  const classes = useStyles()
  const locations = useSelector((state) => state.locations.locations)
  const dispatch = useDispatch()
  const [highlightedLocation, setHighlightedLocation] = useState(null)
  const [viewingLocation, setViewingLocation] = useState(null)
  const [excludeAptLocations, setExcludeAptLocations] = useState(false)
  const {showSearchLocation, setShowSearchLocation} = props
  const [showFavoriteLocations, setShowFavoriteLocations] = useState(true)
  const labelContext = React.useContext(i18n)
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }

  const handleClose = () => {
    setShowSearchLocation(false)
  }

  function getLocationCodeName(lCode) {
    const location = locations.locations ? locations.locations.filter((l) => l.locationCode.includes(lCode)) : null
    return location ? `(${lCode}) ${location[0].locationName}` : ""
  }

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      {/* Modal */}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showSearchLocation}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500 }}
      >
        <Fade in={props.showSearchLocation} className={classes.paper} style={{ width:"80vw", height: "90vh",  }}>
          <div className={classes.noFocus}>
            {props.selectedRow.PickUp && props.selectedRow.PickUp.DateTime && (
              <div className={classes.root}>
                <Grid container direction="row" justify="flex-end" alignItems="flex-end">
                  <div style={{ cursor: "pointer" }}>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
                  </div>
                </Grid>
                <Grid container direction="row" justify="center" alignItems="flex-start" spacing={3}>
                  <Grid item xs={6} lg={5} style={{ textAlign: "center"}}>
                    <Box style={{overflow: 'auto', maxHeight: '80vh'}}>
                      <PopUpLocationSearch
                        viewingLocation={viewingLocation}
                        setViewingLocation={setViewingLocation}
                        setHighlightedLocation={setHighlightedLocation}
                        highlightedLocation={highlightedLocation}
                        showSearchLocation={showSearchLocation}
                        setShowSearchLocation={setShowSearchLocation}
                        setShowFavoriteLocations={setShowFavoriteLocations}
                        excludeAptLocations={excludeAptLocations}
                        setExcludeAptLocations={setExcludeAptLocations}
                      />
                    </Box>
                    
                  </Grid>
                  <Grid 
                    item xs={6} lg={5}
                    style={{ textAlign: "center"}}
                  >
                    <Box style={{overflow: 'auto', maxHeight: '80vh', position: "relative", height: "80vh", width: "100%"}}>
                      <GoogleMapBox
                        viewingLocation={viewingLocation}
                        setViewingLocation={setViewingLocation}
                        highlightedLocation={highlightedLocation}
                        setHighlightedLocation={setHighlightedLocation}
                        showFavoriteLocations={showFavoriteLocations}
                        setShowSearchLocation={setShowSearchLocation}
                        excludeAptLocations={excludeAptLocations}
                        setExcludeAptLocations={setExcludeAptLocations}
                      />
                    </Box>
                    
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </Fade>
      </Modal>
    </MuiPickersUtilsProvider>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  textField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      display: "none",
    },
    "& .MuiInputBase-input": {
      textAlign: "center",
    },
    maxWidth: 350,
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    "&:focus": {
      outlineColor: "transparent",
    },
    borderRadius: 3,
    width: 1200,
  },
  noFocus: {
    "&:focus": {
      outlineColor: "transparent",
    },
  },
}))

export default LocationModal
