import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import Card from "@material-ui/core/Card"
import CardHeader from "@material-ui/core/CardHeader"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Checkbox from "@material-ui/core/Checkbox"
import Button from "@material-ui/core/Button"
import Divider from "@material-ui/core/Divider"
import * as userInfoActions from "../../actions/userInfoActions"
import { Typography } from "@material-ui/core"
import _ from "lodash"
import AddVehicle from "./AddVehicle"
import { metaConverter } from "../../utils/functions"

const useStyles = makeStyles(theme => ({
  root: {
    margin: "auto"
  },
  cardHeader: {
    padding: theme.spacing(1, 2)
  },
  list: {
    width: 300,
    height: 650,
    backgroundColor: theme.palette.background.paper,
    overflow: "auto"
  },
  button: {
    margin: theme.spacing(0.5, 0)
  }
}))

function not(a, b) {
  return a.filter(value => b.findIndex(e => _.isEqual(e, value)) === -1)
}

function intersection(a, b) {
  return a.filter(value => b.findIndex(e => _.isEqual(e, value)) !== -1)
}

function union(a, b) {
  return Array.from((new Set([...a, ...b])))
}


export default function TransferList() {
  const _ = require("lodash")
  const classes = useStyles()
  const dispatch = useDispatch()
  const auth = useSelector(state => ({ ...state.auth }))
  const user = useSelector(state => state.userInfo.settings.user)
  const settings = useSelector(state => state.userInfo.settings)
  const vehicles = metaConverter(settings.vehicleModels)
  const disallowedAdmin = useSelector(state => metaConverter(state.userInfo.settings.meta
    .find(e => e.key === "disallowedAdminVehicleModels").value)) 
  
  const disallowed = useSelector(state => metaConverter(state.userInfo.settings.meta
    .find(e => e.key === "disallowedVehicleModels").value))
  const [checked, setChecked] = React.useState([])

  const [left, setLeft] = React.useState(vehicles)
  const [right, setRight] = React.useState(disallowed)
  const leftChecked = intersection(checked, left.map(e => e.carClass ?? e.code))
  const rightChecked = intersection(checked, right.map(e => e.carClass ?? e.code))

  const handleToggle = value => () => {
    const currentIndex = checked.findIndex(e => e === value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const numberOfChecked = items => intersection(checked, items).length

  const handleToggleAll = items => () => {
    let allowed = 
      user.userType === "ADMIN" ? items : not(items, disallowedAdmin.map(e => {
        return e.carClass ?? e.code
      }))
    if (numberOfChecked(allowed) === allowed.length) {
      setChecked(not(checked, allowed))
    } else {
      setChecked(union(checked, allowed))
    }
  }

  const handleCheckedRight = () => {
    const deniedCodes = disallowed.map(e => e.carClass ?? e.code)
    const deniedAdminCodes = disallowedAdmin.map(e => e.carClass ?? e.code)
    const changed = {
      disallowedVehicleModels: _.union(_.difference(
        deniedCodes.concat(leftChecked),
        deniedAdminCodes
     ))
   };

    dispatch(userInfoActions.setUserSettings({ token: auth.token }, changed, settings))
    const leftCheckedData = leftChecked.map(e => {
      return vehicles.find(v => {
        const key = v.carClass ?? v.code
        return key === e
      })
    })
    setRight(_.orderBy(right.concat(leftCheckedData)))
    setLeft(not(left, leftCheckedData))
    setChecked(not(checked, leftChecked))
  }

  const handleCheckedLeft = () => {
    const deniedCodes = disallowed.map(e => e.carClass ?? e.code)
    const changed = {
      disallowedVehicleModels: _.union(_.difference(
        deniedCodes,
        rightChecked
     ))
   };

    dispatch(userInfoActions.setUserSettings({ token: auth.token }, changed, settings))
    const rightCheckedData = rightChecked.map(e => {
      return disallowed.find(v => {
        const key = v.carClass ?? v.code
        return key  === e
      })
    })
    setLeft(_.orderBy(left.concat(rightCheckedData)))
    setRight(not(right, rightCheckedData))
    setChecked(not(checked, rightChecked))
  }

  function isBusinessDisabled(code) {
    /* if (user.userType == "ADMIN") {
      return false
    } else if (
      _.intersection(settings.businessRules.disallowedLocations, [locationCode])
        .length > 0
    ) {
      return true
    } else return false */
     return disallowedAdmin.findIndex(e => e.carClass === code || e.code === code) !== -1
  }
  function vehicleList(vehicles, title) {
    const vehicleKeys = vehicles.map(e => {
      return e.carClass?? e.code
    })
    return (<Card>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={handleToggleAll(vehicleKeys)}
            checked={vehicles && numberOfChecked(vehicleKeys) === vehicleKeys.length && vehicleKeys.length !== 0}
            indeterminate={vehicles && numberOfChecked(vehicleKeys) !== vehicleKeys.length && numberOfChecked(vehicleKeys) !== 0}
            disabled={vehicles && vehicles.length === 0}
            inputProps={{ "aria-label": "all items selected" }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(vehicleKeys)}/${vehicles && vehicles.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {vehicles &&
          vehicles.map(vehicle => {
            const carClass = vehicle.carClass ?? vehicle.code
            const country = vehicle.country
            const acriss = vehicle.acriss
            const name = _.isEmpty(vehicle.carClass) ? `(${vehicle.code}) ${vehicle.name}` : `(${acriss}) (${country}) ${vehicle.name}`
            const labelId = `transfer-list-all-item-${carClass}-label`
            return (
              <ListItem key={carClass} role="listitem" button disabled={isBusinessDisabled(carClass)} onClick={handleToggle(carClass)}>
                <ListItemIcon>
                  <Checkbox checked={checked.indexOf(carClass) !== -1} tabIndex={-1} disableRipple inputProps={{ "aria-labelledby": labelId }} />
                </ListItemIcon>
                <ListItemText id={labelId}>{name}</ListItemText>
              </ListItem>
            )
          })}
        <ListItem />
      </List>
    </Card>)
  }

  return (
    <div>
      <h2 style={{ paddingBottom: 0, marginBottom: 0, marginLeft: 5 }}>Vehicles</h2>
      <Typography style={{ color: "grey", fontSize: 13, marginBottom: 5, marginLeft: 5 }}>
        This page is used to control disallowed vehicles site wide. <br />
        Any vehicle that is disallowed will not appear in availablity results.
      </Typography>
      <Grid container direction="row" justify="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item xs={8}>
          <Grid container spacing={2} alignItems="center" direction="row">
            <Grid item>{vehicleList(left, "Allowed")}</Grid>
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedRight}
                  disabled={leftChecked.length === 0}
                  aria-label="move selected right"
                >
                  &gt;
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  className={classes.button}
                  onClick={handleCheckedLeft}
                  disabled={rightChecked.length === 0}
                  aria-label="move selected left"
                >
                  &lt;
                </Button>
              </Grid>
            </Grid>
            <Grid item>{vehicleList(right, "Denied")}</Grid>
          </Grid>
        </Grid>
        {user && user.userType == "ADMIN" && (
          <Grid item xs={4}>
            <AddVehicle />
          </Grid>
        )}
      </Grid>
    </div>
  )
}
