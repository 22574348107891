/* eslint-disable no-useless-escape */
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import {
  Grid,
  Typography,
  makeStyles,
  Card,
  TextField,
  Button,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
} from "@material-ui/core"
import _ from "lodash"
import * as userActions from "../../actions/userActions"
import AddUsersFilter from "./AddUsersFilter"
import CircularProgress from "@material-ui/core/CircularProgress"
import * as userGroupActions from "../../actions/userGroupActions"

const AddUsers = () => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const auth = useSelector((state) => state.auth)
  const [userList, setUserList] = React.useState("")
  const userGroups = useSelector(state => _.get(state, "userGroups.data", []))
  const [pageLoad, setPageLoad] = React.useState(false)
  const [userGroupState, setUserGroupState] = React.useState([]);
  const [checkedUserGroups, setCheckedUserGroups] = React.useState([]);
  const isFetching = useSelector((state) => state.users.isFetching)

  function onSubmit() {
    let emailArray = userList
    const userGroupsArray = checkedUserGroups.map(e => e._id)

    var re = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    if (!Array.isArray(userList)) {
      emailArray = emailArray.split(/[\n, ]/)
    }

    for (let i = 0; i < emailArray.length; i++) {
      if (!re.test(String(emailArray[i]).toLowerCase())) {
        emailArray[i] = "invalid"
      }
    }

    emailArray = _.without(emailArray, "invalid")
    const users = emailArray.map(e => ({emailAddress: e, userGroups: userGroupsArray}))
    for (let i = 0; i < users.length; i++) {
      setTimeout(function timer() {
        dispatch(
          userActions.createUser({ token: auth.token }, users[i])
        )
      }, (i + 1) * 500)
    }
  }

  useEffect(() => {
    if (pageLoad === false) {
      dispatch(userGroupActions.getUserGroups({ token: auth.token }))
      setPageLoad(true)
      // setSelectedRow({
      //   tableData: { id: 0 }
      // })
    }
  }, [])

  useEffect(() => {
    if(!_.isEmpty(userGroups)) {
        setUserGroupState(userGroups)
    }
  }, [userGroups])

  return (
    <Grid container direction="row" justify="flex-start" alignItems="flex-start" spacing={2}>
      <Grid item xs={6} lg={7}>
        <Card className={classes.summaryCardActive} style={{ overflowY: "scroll" }}>
          <div style={{ margin: 20 }}>
            <h2 style={{ display: "inline" }}>Add Bulk Users</h2>
            <Typography style={{ color: "grey", fontSize: 12 }}>
              * Please use a comma, space or new line delimited list of user emails to be created as users
            </Typography>
            <TextField
              id="outlined-multiline-static"
              label="Delimited List of Users"
              multiline
              rows="15"
              variant="outlined"
              fullWidth
              value={userList}
              onChange={(e) => setUserList(e.target.value.toString())}
              style={{ marginTop: 5 }}
            />
          </div>
          <Grid
            container
            direction="row"
            justify="flex-start"
            alignItems="flex-start"
            style={{
              marginLeft: 10,
              minHeight: 250,
              maxHeight: 250,
              overflow: "auto",
            }}
          >
            <Grid item xs={4}>
              <List className={classes.root} subheader={<li />}>
                <ListSubheader component="div" id="nested-list-subheader">
                  Allowed User Groups
                </ListSubheader>
                {checkedUserGroups.length > 0 ? (
                  checkedUserGroups.map((checkedUserGroup, key) => (
                    <ListItem key={key}>
                      <ListItemText primary={checkedUserGroup.name} />
                    </ListItem>
                  ))
                ) : (
                  <ListItem key={`1`}>
                    <ListItemText primary={`None`} />
                  </ListItem>
                )}
              </List>
            </Grid>
          </Grid>
        </Card>
        <Paper style={{ minHeight: 51, width: "100%", marginTop: 10 }}>
          <Grid container direction="row" justify="flex-end" alignItems="flex-end">
            <Button
              style={{
                minWidth: 175,
                marginRight: 10,
                marginTop: 7,
              }}
              variant="contained"
              color="primary"
              onClick={() => setUserList("")}
            >
              Clear
            </Button>
            {!isFetching ? (
              <Button
                style={{
                  minWidth: 175,
                  marginRight: 10,
                  marginTop: 7,
                }}
                variant="contained"
                color="secondary"
                onClick={(e) => onSubmit(e)}
              >
                Add Users
              </Button>
            ) : (
              <Button
                style={{
                  minWidth: 175,
                  marginRight: 10,
                  marginTop: 7,
                }}
                variant="contained"
                color="secondary"
                onClick={(e) => onSubmit(e)}
                disabled
              >
                Creating Users
                <CircularProgress size={16} style={{ marginLeft: 10 }}></CircularProgress>
              </Button>
            )}
          </Grid>
        </Paper>
      </Grid>
      <AddUsersFilter
        checkedUserGroups={checkedUserGroups}
        setCheckedUserGroups={setCheckedUserGroups}
        userGroups={userGroupState}
      />
    </Grid>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
    position: "relative",
    overflow: "auto",
    maxHeight: 300,
  },
  listSection: {
    backgroundColor: "inherit",
  },
  ul: {
    backgroundColor: "inherit",
    padding: 0,
  },
  summaryCard: {
    height: "calc(100vh - 106px)",
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)",
  },
}))

export default AddUsers
