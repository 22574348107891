import * as React from 'react';
import { Divider, Box, Typography } from '@material-ui/core';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useSelector } from "react-redux"
const paper = {
    elevation: 0,
    sx: {
        overflow: 'visible',
        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
        mt: 1.5,
        '& .MuiAvatar-root': {
            width: 32,
            height: 32,
            ml: -0.5,
            mr: 1,
        },
        '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: 'background.paper',
            transform: 'translateY(-50%) rotate(45deg)',
            zIndex: 0,
        },
    },
}

const ProfileMenu = (props) => {
    const open = Boolean(props.anchorEl)
    const userInfo = useSelector((state) => state.userInfo)
    const user = userInfo.settings.user


    return (
        <Menu
            id="user-profile-menu"
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}
            slotProps={{ paper }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >

            <Box style={{ display: "flex", padding: "6px 16px", alignItems: "center" }}>
                <Box>
                    <Typography variant="body1" sx={{ fontWeight: 500, lineHeight: '16px' }}>
                        {user.firstName} {user.lastName}
                    </Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                        {user.emailAddress}
                    </Typography>
                </Box>
            </Box>
            <Divider />
            <MenuItem onClick={props.handleViewProfile}>
                Profile
            </MenuItem>
            <MenuItem onClick={props.handleChangePassword}>
                Change Password
            </MenuItem>
            <Divider />
            <MenuItem onClick={props.handleLogout}>
                Logout
            </MenuItem>
        </Menu>)
}

ProfileMenu.propTypes = {
    open: PropTypes.bool,
    anchorEl: PropTypes.object,
    handleClose: PropTypes.func.isRequired,
    handleChangePassword: PropTypes.func.isRequired,
    handleViewProfile: PropTypes.func.isRequired,
    handleLogout: PropTypes.func.isRequired,
}

export default ProfileMenu