/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment, useEffect } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import { merge } from "../../utils/functions"

import {
    Card,
    Button,
    makeStyles,
    Typography,
    Popover,
    Checkbox
} from "@material-ui/core"
import Grid from '@mui/material/Grid';
import { useDispatch } from "react-redux"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import HelpOutlineIcon from "@material-ui/icons/HelpOutline"
import Paper from "@material-ui/core/Paper"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"

import * as userGroupActions from "../../actions/userGroupActions"

import _ from "lodash"

function CustomPopOver(props) {
    const { classes } = props
    const [anchorEl, setAnchorEl] = React.useState(null)
    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    return (
        <React.Fragment>
            <Popover
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right"
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "left"
                }}
                id="mouse-over-popover"
                className={classes.popover}
                classes={{
                    paper: classes.paper
                }}
                open={open}
                anchorEl={anchorEl}
                onMouseEnter={handlePopoverOpen}
                onClose={handlePopoverClose}
                disableRestoreFocus
                style={{ border: "none" }}
            >
                <Typography>
                    {props.popOverText}
                </Typography>
            </Popover>
            <HelpOutlineIcon
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
                style={{ marginLeft: 15 }}
            ></HelpOutlineIcon>
        </React.Fragment>
    )
}

function GroupTable(props) {
    const { classes } = props
    return <React.Fragment>
        <MaterialTable
            icons={tableIcons}
            title={props.title}
            isLoading={props.isLoading}
            columns={props.columns}
            options={{
                minBodyHeight: "calc(100vh - 350px)",
                maxBodyHeight: "calc(100vh - 350px)",
                pageSize: 10,
                selection: true,
                selectionProps: rowData => ({
                    disabled: rowData.active == false
                })
            }}
            style={{ boxShadow: "none" }}
            data={props.data}
            onSelectionChange={props.onSelectionChange}
        />
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 15 }}
        >
            <CustomPopOver
                popOverText={props.popOverText}
                classes={classes}
            />

            <Grid
                item
                xs={6}
                className={classes.actionButtons}
                style={{ textAlign: "right", marginRight: 10 }}
            >
                <Button
                    color="primary"
                    onClick={props.updateButtonHandler}
                >
                    {props.updateButtonText}
                    <ArrowForwardIosIcon
                        style={{
                            marginLeft: 5,
                            fontSize: 20,
                            marginBottom: 2
                        }}
                    ></ArrowForwardIosIcon>
                </Button>
            </Grid>
        </Grid>
    </React.Fragment>
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`usergroup-tabpanel-${index}`}
            aria-labelledby={`usergroup-tab-${index}`}
            {...other}
        >
            {value === index && (children)}
        </div>
    );
}
const UserGroupSummary = props => {
    const dispatch = useDispatch()
    const classes = useStyles()
    const [selectedTab, setSelectedTab] = React.useState(0)
    const allTotalSubtotalMeta = props.selectedRow.meta ? props.selectedRow.meta.find(e => e.key === "allowTotalSubtotal") : {}
    const defaultAllowTotalSubtotal = _.get(allTotalSubtotalMeta, "value", false)
    const [allowTotalSubtotal, setAllowTotalSubtotal] = React.useState(defaultAllowTotalSubtotal)
    const [selectedITGroups, setSelectedITGroups] = React.useState(props.itGroups)
    const [selectedLocationGroups, setSelectedLocationGroups] = React.useState(props.locationGroups)
    const [selectedVehicleGroups, setSelectedVehicleGroups] = React.useState(props.vehicleGroups)

    useEffect(() => {
        setSelectedITGroups(props.itGroups.map(itGroup => {
            return {
                ...itGroup,
                tableData: {
                    checked: Boolean(props.selectedRow.itCodeGroups.find(e => e._id === itGroup._id))
                }
            }
        }))
        setSelectedLocationGroups(props.locationGroups.map(locationGroup => {
            return {
                ...locationGroup,
                tableData: {
                    checked: Boolean(props.selectedRow.locationGroups.find(e => e._id === locationGroup._id))
                }
            }
        }))
        setSelectedVehicleGroups(props.vehicleGroups.map(vehicleGroup => {
            return {
                ...vehicleGroup,
                tableData: {
                    checked: Boolean(props.selectedRow.vehicleModelGroups.find(e => e._id === vehicleGroup._id))
                }
            }
        }))
        setAllowTotalSubtotal(_.get(props.selectedRow.meta?.find(e => e.key === "allowTotalSubtotal"), "value", false))
    }, [props.selectedRow])


    const handleTabChange = (event, newValue) => {
        setSelectedTab(newValue)
    }


    function checkHelper(rowData, data, setData) {
        if (rowData === 0) {
            setData()
        } else {
            setData(merge(data, rowData, (d, r) => d._id === r._id))
        }
    }

    function onUpdateUserGroup(value) {
        const itCodeGroups = value === "itCodeGroups" ? selectedITGroups.reduce((acc, e) => {
            if (e.tableData.checked) {
                return acc.concat(e._id)
            }

            return acc
        }, []) : props.selectedRow.itCodeGroups.map(e => e._id);
        const locationGroups = value === "locationGroups" ? selectedLocationGroups.reduce((acc, e) => {
            if (e.tableData.checked) {
                return acc.concat(e._id)
            }

            return acc
        }, []) : props.selectedRow.locationGroups.map(e => e._id);
        const vehicleModelGroups = value === "vehicleModelGroups" ? selectedVehicleGroups.reduce((acc, e) => {
            if (e.tableData.checked) {
                return acc.concat(e._id)
            }

            return acc
        }, []) : props.selectedRow.vehicleModelGroups.map(e => e._id);
        const changedMeta = [
            {
                key: "allowTotalSubtotal",
                value: JSON.stringify(allowTotalSubtotal)
            }
        ]
        const meta = value === "meta" ? changedMeta : props.selectedRow.meta
        const userGroup = {
            _id: props.selectedRow._id,
            name: props.selectedRow.name,
            active: props.selectedRow.active,
            itCodeGroups,
            locationGroups,
            vehicleModelGroups,
            meta
        }
        dispatch(userGroupActions.setUserGroup({ token: props.auth.token }, userGroup))
    }

    return (
        <Fragment>
            <Grid item xs={7} lg={7}>
                <Card className={classes.summaryCardActive}>
                    <TabPanel value={selectedTab} index={0}>
                        <GroupTable
                            title="IT Code Groups"
                            isLoading={props.isLoading}
                            columns={[
                                { title: "Name", field: "name" },
                                { title: "_id", field: "_id", hidden: true },
                            ]}
                            data={selectedITGroups}
                            popOverText={
                                `Select IT Groups to be assigned to the user and finalise
                                the process by pressing "UPDATE IT GROUP"`
                            }
                            updateButtonHandler={e => onUpdateUserGroup("itCodeGroups", e)}
                            updateButtonText="Update IT Groups"
                            classes={classes}
                            onSelectionChange={rowData => {
                                checkHelper(rowData, selectedITGroups, setSelectedITGroups)
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={1}>
                        <GroupTable
                            title="Location Groups"
                            isLoading={props.isLoading}
                            columns={[
                                { title: "Name", field: "name" },
                                { title: "_id", field: "_id", hidden: true },
                            ]}
                            data={selectedLocationGroups}
                            popOverText={
                                String.raw`By default, all locations will be be accessible by the
                                user.
                                Assigning Locations will limit the user to only being able
                                to use those selected.`
                            }

                            updateButtonHandler={e => onUpdateUserGroup("locationGroups", e)}
                            updateButtonText="Update Location Groups"
                            classes={classes}
                            onSelectionChange={rowData => {
                                checkHelper(rowData, selectedLocationGroups, setSelectedLocationGroups)
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={2}>
                        <GroupTable
                            title="Vehicle Groups"
                            isLoading={props.isLoading}
                            columns={[
                                { title: "Name", field: "name" },
                                { title: "_id", field: "_id", hidden: true },
                            ]}
                            data={selectedVehicleGroups}
                            popOverText={
                                String.raw`By default, all Vehicles will be be accessible by the
                                user.
                                Assigning Vehicles will limit the user to only being able
                                to use those selected.`
                            }

                            updateButtonHandler={e => onUpdateUserGroup("vehicleModelGroups", e)}
                            updateButtonText="Update Vehicle Groups"
                            classes={classes}
                            onSelectionChange={rowData => {
                                checkHelper(rowData, selectedVehicleGroups, setSelectedVehicleGroups)
                            }}
                        />
                    </TabPanel>
                    <TabPanel value={selectedTab} index={3}>
                        <Grid
                            container
                            marginLeft="16px"
                            direction="row">
                            <h6>Miscellenous Options</h6>
                            <Grid
                                container
                                direction="columns"
                                alignItems="center"
                            >
                                <span>Allow viewing subtotal and total</span>
                                <Checkbox checked={allowTotalSubtotal} onChange={(e) => {
                                    setAllowTotalSubtotal(e.target.checked)
                                }} />
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            style={{ marginTop: 15 }}
                        >
                            <CustomPopOver
                                popOverText="These are the miscellenous for the usergroups"
                                classes={classes}
                            />
                            <Button
                                color="primary"
                                onClick={e => onUpdateUserGroup("meta", e)}
                            >
                                Update Misc Options
                                <ArrowForwardIosIcon
                                    style={{
                                        marginLeft: 5,
                                        fontSize: 20,
                                        marginBottom: 2
                                    }}
                                ></ArrowForwardIosIcon>
                            </Button>
                        </Grid>
                    </TabPanel>
                </Card>

                <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="flex-start"
                    style={{ marginTop: 10 }}
                >
                    <Card style={{ width: "100%", height: "100%" }}>
                        <Grid
                            container
                            direction="row"
                            justifyContent="flex-start"
                            alignItems="flex-start"
                            className={classes.actionButtons}
                        >
                            <Grid
                                container
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                            >
                                <Paper style={{ minHeight: 51, width: "100%", paddingTop: 3 }}>
                                    {props.selectedRow && (
                                        <Tabs
                                            value={selectedTab}
                                            onChange={handleTabChange}
                                            indicatorColor="primary"
                                            textColor="primary"
                                            aria-label="disabled tabs example"
                                            variant="fullWidth"
                                        >
                                            <Tab label="IT GROUPS" />
                                            <Tab label="LOCATIONS" />
                                            <Tab label="VEHICLES" />
                                            <Tab label="MISC" />
                                        </Tabs>
                                    )}
                                </Paper>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Fragment>
    )
}

const cardWidget = {
    textAlign: "center",
    height: "100%",
    width: "100%",
    padding: "20px 0 10px 0",
    position: "relative",
    overflow: "hidden"
}

const useStyles = makeStyles(theme => ({
    root: {
        "& button.MuiButtonBase-root": {
            padding: "5px"
        }
    },
    actionButtons: {
        height: "100%",
        textAlign: "center"
    },
    summaryCard: {
        height: "calc(100vh - 106px)"
    },
    summaryCardActive: {
        height: "calc(100vh - 168px)"
    },
    vehicleCard: cardWidget,
    progress: {
        margin: theme.spacing(2)
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "2px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3)
    },
    customerDetails: {
        "& input": {
            fontSize: "14px",
            color: "#000",
            padding: "5px 0"
        },
        "& label.Mui-disabled": {
            fontSize: "13px",
            color: "#B9BBBE"
        }
    },
    infoCard: {
        ...cardWidget,
        padding: "10px 0 10px 0",
        "& .MuiCardContent-root": {
            paddingTop: "0",
            paddingBottom: "0"
        },
        "& .MuiTextField-root": {
            margin: "0px"
        },
        "& input": {
            fontSize: "14px",
            color: "#000",
            padding: "5px 0"
        },
        "& .MuiInputBase-root": {
            marginTop: "12px"
        },
        "& label.Mui-disabled": {
            fontSize: "13px",
            color: "#B9BBBE"
        }
    },
    popover: {
        pointerEvents: "none"
    }
}))

export default UserGroupSummary