import * as types from "../constants/actionTypes"

export function createUser(params, emailAddress, itGroups, locations, vehicles) {
  return {
    type: types.USER_SETTINGS.CREATE.REQUEST,
    payload: {
      ...params,
      emailAddress,
      itGroups,
      locations,
      vehicles,
    },
  }
}

export function getUserSettings(params) {
  return {
    type: types.USER_SETTINGS.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function getServiceSettings(params) {
  return {
    type: types.GET_SERVICE_SETTINGS.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setUserSettings(params, changed, settings) {
  return {
    type: types.USER_SETTINGS.SET.REQUEST,
    payload: {
      ...params,
      changed,
      settings,
    },
  }
}

export function setBusinessRules(params, changed, settings) {
  return {
    type: types.BUSINESS_RULES.SET.REQUEST,
    payload: {
      ...params,
      changed,
      settings,
    },
  }
}

export function setBusinessServiceSettings(params, changed, settings) {
  return {
    type: types.SERVICE_SETTINGS.SET.REQUEST,
    payload: {
      ...params,
      changed,
      settings,
    },
  }
}

export function setFavouriteLocation(params, favouriteLocations) {
  return {
    type: types.FAVOURITE_LOCATIONS.SET.REQUEST,
    payload: {
      ...params,
      favouriteLocations,
    },
  }
}

export function createItGroup(params, name, itCodes) {
  return {
    type: types.IT_GROUP.CREATE.REQUEST,
    payload: {
      ...params,
      name,
      itCodes,
    },
  }
}

export function setItGroup(params, itGroup, itCodes) {
  return {
    type: types.IT_GROUP.SET.REQUEST,
    payload: {
      ...params,
      itGroup,
      itCodes,
    },
  }
}

export function updateUser(params, _id, emailAddress, userType, itGroups, locations, vehicles) {
  return {
    type: types.SERVICE_USER.SET.REQUEST,
    payload: {
      ...params,
      _id,
      emailAddress,
      userType,
      itGroups,
      locations,
      vehicles,
    },
  }
}

export function setUserItCodeGroups(params, emailAddress, itGroups) {
  return {
    type: types.USER_IT_CODE_GROUPS.SET.REQUEST,
    payload: {
      ...params,
      emailAddress,
      itGroups,
    },
  }
}

export function setUserLocations(params, emailAddress, locations) {
  return {
    type: types.USER_LOCATIONS.SET.REQUEST,
    payload: {
      ...params,
      emailAddress,
      locations,
    },
  }
}

export function setUserVehicles(params) {
  return {
    type: types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setAvailableAggregateVehicleGroups(params, availableVehicleGroups) {
  return {
    type: types.AVAILABLE_AGGREGATE_VEHICLE_GROUPS.SET.REQUEST,
    payload: {
      ...params,
      availableVehicleGroups,
    },
  }
}

export function setAllowedAggregateVehicleGroups(params, allowedVehicleGroups) {
  return {
    type: types.ALLOWED_AGGREGATE_VEHICLE_GROUPS.SET.REQUEST,
    payload: {
      ...params,
      allowedVehicleGroups,
    },
  }
}

export function disableItGroup(params, itGroup) {
  return {
    type: types.IT_GROUP.DISABLE.REQUEST,
    payload: {
      ...params,
      itGroup,
    },
  }
}

export function enableItGroup(params, itGroup) {
  return {
    type: types.IT_GROUP.ENABLE.REQUEST,
    payload: {
      ...params,
      itGroup,
    },
  }
}

export function deleteItGroup(params, itGroup) {
  return {
    type: types.IT_GROUP.DELETE.REQUEST,
    payload: {
      ...params,
      itGroup,
    },
  }
}

export function setItCodes(params, itCodes, userType) {
  return {
    type: types.IT_CODES.SET.REQUEST,
    payload: {
      ...params,
      itCodes,
      userType,
    },
  }
}

export function getItCodes(params) {
  return {
    type: types.IT_CODES.GET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function updateNotifications(params, notifications) {
  return {
    type: types.NOTIFICATION.SET.REQUEST,
    payload: {
      ...params,
      notifications,
    },
  }
}

export function updateProfile(params, user) {
  return {
    type: types.USER_PROFILE.SET.REQUEST,
    payload: {
      ...params,
      user,
    },
  }
}

export function setUserPassword(params, password) {
  return {
    type: types.USER_PASSWORD.SET.REQUEST,
    payload: {
      ...params,
      password,
    },
  }
}

export function setResetRequired(params, emailAddress) {
  return {
    type: types.RESET_REQUIRED.SET.REQUEST,
    payload: {
      ...params,
      emailAddress,
    },
  }
}

export function setAllowOneWay(params) {
  return {
    type: types.ALLOW_ONE_WAY.SET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setAllowPickupAndDropoff(params) {
  return {
    type: types.ALLOW_PICKUP_AND_DROPOFF.SET.REQUEST,
    payload: {
      ...params,
    },
  }
}

export function setConfirmationSpielTemplate(params) {
  return {
    type: types.CONFIRMATION_SPIEL_TEMPLATE.SET.REQUEST,
    payload: {
      ...params,
    },
  }
}
