import React, { useEffect, useState, useContext } from "react"
import { useSelector, useDispatch } from "react-redux"
import FormLabel from "@material-ui/core/FormLabel"
import FormControl from "@material-ui/core/FormControl"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormHelperText from "@material-ui/core/FormHelperText"
import Switch from "@material-ui/core/Switch"

import TreeView from '@material-ui/lab/TreeView'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import TreeItem from '@material-ui/lab/TreeItem'

import { TextField, MenuItem, Grid, Paper, Button, CircularProgress } from "@material-ui/core"
import * as emailLogic from "../../businesslogic/email"
import * as userInfoActions from "../../actions/userInfoActions"
import * as bookingsActions from "../../actions/bookingsActions"
import { i18n } from "../i18n"
import _ from "lodash"

const ServiceSettings = () => {
  const auth = useSelector((state) => ({ ...state.auth }))
  const settings = useSelector((state) => state.userInfo.settings)
  const user = useSelector((state) => state.userInfo.settings.user)
  const userInfo = useSelector((state) => ({ ...state.userInfo }))
  const isFetching = Boolean(userInfo.isFetching)
  const quotation = useSelector((state) => ({ ...state.quotation }))
  const labelContext = useContext(i18n)
  const [template, setTemplate] = useState("createReservation")
  const [bcc, setBCC] = useState(_.get(_.find(userInfo.settings.meta, { "key": "defaultEmailBCCAddresses" }), "value", [])?.join("; "))
  const [templateValues, setTemplateValues] = useState(_.get(_.find(userInfo.settings.meta, { "key": "emailTemplates" }), "value", {}))
  const [confirmationSpielTemplate, setConfirmationSpielTemplate] = useState(_.get(_.find(userInfo.settings.meta, { "key": "confirmationSpielTemplate" }), "value", {}))
  const getLabel = (label, defaultValue) => {
    if (i18n !== undefined) {
      return _.get(labelContext, `labels.${label}`, defaultValue)
    } else {
      return defaultValue
    }
  }
  // const userType = useSelector((state) => state.userInfo.settings.user.userType)
  const allowOneWay = useSelector((state) =>
    _.get(_.find(state.userInfo.settings.meta, { "key": "allowOneWay" }), "value", false)
  )
  const allowPickupAndDropoff = useSelector((state) =>
    _.get(_.find(state.userInfo.settings.meta, { "key": "allowPickupAndDropoff" }), "value", false)
  )
  // ask why these options have to be disabled
  /* const [disabledOneWay] = React.useState(
    !_.get(_.find(userInfo.settings.meta, { "key": "allowOneWay" }), "value", false)
  )
  const [disabledPickupAndDropoff] = React.useState(
    !_.get(_.find(userInfo.settings.meta, { "key": "allowPickupAndDropoff" }), "value", false)
  ) */
  const disabledOneWay = false;
  const disabledPickupAndDropoff = false
  const dispatch = useDispatch()

  useEffect(() => {
    if (_.get(_.find(userInfo.settings.meta, { "key": "allowOneWay" }), "value", false) == false) {
      dispatch(
        bookingsActions.setQuotation({
          dropoffLocation: quotation.pickupLocation,
        })
      )
    }
  }, [_.get(_.find(userInfo.settings.meta, { "key": "allowOneWay" }), "value", false)])

  // const handleChange = (name) => (event) => {
  //   const changed = user.userType == "ADMIN" ? _.cloneDeep(settings.businessRules) : _.cloneDeep(settings.serviceRules)

  //   if (name == "allowPickupAndDropoff") {
  //     changed["allowPickupAndDropoff"] = event.target.checked
  //   } else {
  //     changed["allowOneWay"] = event.target.checked
  //   }

  //   user.userType == "ADMIN"
  //     ? dispatch(userInfoActions.setBusinessServiceSettings({ token: auth.token }, changed, settings.businessRules))
  //     : dispatch(userInfoActions.setUserSettings({ token: auth.token }, changed, settings.serviceRules))
  // }

  const handleTemplateChange = (e) => {
    setTemplateValues({
      ...templateValues,
      [template]: {
        ...templateValues[template],
        [e.target.id]: e.target.value,
      },
    })
  }

  const handleConfirmationSpielTemplateChange = (e) => {
    setConfirmationSpielTemplate({
      ...confirmationSpielTemplate,
      [e.target.name]: e.target.value,
    })
  }

  const handleTestEmail = () => {
    dispatch(
      bookingsActions.downloadConfirmationEmail({
        test: true,
        type: template,
        mode: "reservation",
        data: emailLogic.getMockData(),
        userInfo: { ...userInfo }
      })
    )
  }

  const handleSaveConfirmationSpielTemplate = () => {
    dispatch(userInfoActions.setConfirmationSpielTemplate({token: auth.token, confirmationSpielTemplate}))
  }

  const handleSaveTemplate = () => {
    dispatch({
      type: "saveEmailTemplates",
      payload: {
        token: auth.token,
        changed: {
          ...templateValues,
          defaultEmailBCCAddresses: bcc,
        },
      },
    })
  }

  const handleAllowOneWay = (e) => {
    dispatch(
      userInfoActions.setAllowOneWay({
        token: auth.token,
        allowOneWay: e.target.checked,
        settingsType: _.get(user, "userType", "") == "ADMIN" ? "UpdateBusinessSettings" : "UpdateServiceSettings",
      })
    )
  }

  const handlePickupAndDropoff = (e) => {
    dispatch(
      userInfoActions.setAllowPickupAndDropoff({
        token: auth.token,
        allowPickupAndDropoff: e.target.checked,
        settingsType: _.get(user, "userType", "") == "ADMIN" ? "UpdateBusinessSettings" : "UpdateServiceSettings",
      })
    )
  }

  const renderTree = (nodes, label) => (
    <TreeItem key={label} nodeId={label} label={label}>
      {_.isObject(nodes) && Object.keys(nodes).length > 0
        ? _.isArray(nodes)
          ? renderTree(nodes[0], '[...]')
          : Object.keys(nodes).map(k => renderTree(nodes[k], k))
        : null
      }
    </TreeItem>
  )

  return (
    <div>
      {settings && (
        <Grid container>
          <Grid container item>
            <FormControl component="fieldset">
              <FormLabel component="legend">
                {" "}
                {getLabel("servicesettings.serviceSettings", "Service Settings")}
              </FormLabel>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={allowPickupAndDropoff}
                      disabled={disabledPickupAndDropoff}
                      onChange={(e) => handlePickupAndDropoff(e)}
                      value="allowPickupAndDropoff"
                    />
                  }
                  label="Allow Delivery and Collection"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={allowOneWay}
                      disabled={disabledOneWay}
                      onChange={(e) => handleAllowOneWay(e)}
                      value="allowOneWay"
                    />
                  }
                  label="Allow Oneway"
                />
                <TextField
                  fullWidth
                  id="onewayerrormsg"
                  label={getLabel("servicesettings.oneway", "OneWay Error Message")}
                  defaultValue="Error: Oneways not allowed"
                  margin="normal"
                  variant="outlined"
                />
              </FormGroup>
              <FormHelperText>{getLabel("servicesettings.extraNote", "some extra notes here.")}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid container item>
            <Paper style={{ padding: "10px", marginTop: "10px", width: "100%" }}>
              <Grid container>
                <Grid container item spacing={1} justify="flex-end" xs={8}>
                  <Grid container item>
                    <TextField
                      id="emailtemplates"
                      select
                      label="Email Templates"
                      value={template}
                      onChange={(e) => setTemplate(e.target.value)}
                      helperText="Please select email Template"
                      margin="normal"
                      variant="outlined"
                    >
                      {Object.keys(templateValues).map((key, i) => (
                        <MenuItem key={i} value={key} name={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid container item>
                    <TextField
                      style={{ width: "100%" }}
                      id="bcc"
                      label="BCC"
                      defaultValue={bcc}
                      onChange={(e) => setBCC(e.target.value.split(/[ ;,]+/))}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      style={{ width: "100%" }}
                      id="subject"
                      label="Subject"
                      margin="normal"
                      variant="outlined"
                      onChange={handleTemplateChange}
                      value={templateValues[template] ? templateValues[template].subject : ""}
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      style={{ width: "100%" }}
                      rows={5}
                      rowsMax={15}
                      id="body"
                      label="Body"
                      margin="normal"
                      variant="outlined"
                      onChange={handleTemplateChange}
                      value={templateValues[template] ? templateValues[template].body : ""}
                      multiline
                    />
                  </Grid>
                  <Grid container item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "5px" }}
                      disabled={isFetching}
                      onClick={handleSaveTemplate}
                    >
                      Save {isFetching && (
                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                    <Button variant="outlined" color="primary" style={{ marginLeft: "5px" }} onClick={handleTestEmail}>
                      Test Email
                    </Button>
                  </Grid>
                </Grid>
                <Grid container item spacing={1} xs={4} style={{ padding: "10px 10px", marginLeft: "10px" }}>
                  <TreeView
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpanded={['Template Variables']}
                    defaultExpandIcon={<ChevronRightIcon />}
                  >
                    {renderTree(emailLogic.getMockData(), 'Template Variables')}
                  </TreeView>
                </Grid>
              </Grid>
            </Paper>
          </Grid>

          <Grid container item>
            <Paper style={{ padding: "10px", marginTop: "10px", width: "100%" }}>
              <Grid container>
                <Grid container item spacing={1} justify="flex-end">                  
                  <Grid container item>
                    
                    <h2>Confirmation Spiel</h2>
                  </Grid>
                  <Grid container item>
                    <TextField
                      style={{ width: "100%" }}
                      id="confirmationSpielTitle"
                      label="Title"
                      name="title"
                      defaultValue={confirmationSpielTemplate.title}
                      onChange={handleConfirmationSpielTemplateChange}
                      margin="normal"
                      variant="outlined"
                    />
                  </Grid>
                  <Grid container item>
                    <TextField
                      style={{ width: "100%" }}
                      rows={5}
                      rowsMax={15}
                      id="body"
                      label="Body"
                      margin="normal"
                      variant="outlined"
                      name="body"
                      onChange={handleConfirmationSpielTemplateChange}
                      value={confirmationSpielTemplate.body}
                      multiline
                    />
                  </Grid>
                  <Grid container item>
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ marginRight: "5px" }}
                      disabled={isFetching}
                      onClick={handleSaveConfirmationSpielTemplate}
                    >
                      Save {isFetching && (
                        <CircularProgress size={15} style={{ marginLeft: "10px" }} />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default ServiceSettings
