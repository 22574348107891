import { takeLatest, put, call } from "redux-saga/effects"
import callApi from "./helpers"
import * as types from "../constants/actionTypes"
import * as appActions from "../actions/appActions"
import url from "url"
import { API_SERVER_URL } from "../config"
import _ from "lodash"

export function* changePassword(actions) {
    
    
    const config = {
        mode: "cors",
        headers: {
            "Content-Type": "application/json",
            Authorization: actions.payload.token != "" ? `Bearer ${actions.payload.token}` : null,
        },
        method: "POST",
        body: JSON.stringify({
            query: `mutation ChangePassword {
                        ChangePassword(
                            oldPassword: "${actions.payload.credentials.oldPassword}",
                            newPassword: "${actions.payload.credentials.newPassword}",
                            cpassword: "${actions.payload.credentials.cPassword}"
                        ) {
                            username
                        }
                    }`,
        }),
    }

    const uri = url.resolve(API_SERVER_URL, "")

    try {
        const callResponse = yield call(callApi, () => fetch(uri, config), [types.CHANGE_PASSWORD.SET.SUCCESS, types.CHANGE_PASSWORD.SET.FAILURE])
        if (_.has(callResponse, "errors")) {
            yield put(appActions.showMessage("Request Error", callResponse.errors[0].exceptions[0], "danger"))
        } else {
            yield put(appActions.showSuccessMessage("Success", "The password hass been changed"))
        }
    } catch (error) {
        yield put(appActions.showMessage("Request Error", error.message, "danger"))
    }
}

export function* watchChangePasswordRequest() {
    yield takeLatest(types.CHANGE_PASSWORD.SET.REQUEST, changePassword);
}
