import _ from "lodash"

export const getMockData = () => {
  return {
    ReservationStatus: "open",
    ReservationNumber: "RESNO",
    ReservationConfirmed: true,
    ConfirmationNumber: "CONFNUMBER",
    RateClass: "H",
    PackageName: "Test Package Name",
    Options: null,
    ClaimNumber: "CLAIMNUMBER123",
    PolicyNumber: "POLICYNUBER123",
    StaffId: "",
    VoucherClass: "H",
    DesiredOptions: "",
    SourceCode: "",
    MainNote: "",
    Notes: [],
    DobOfYoungestDriver: "1999-05-31",
    Pickup: {
      DateTime: "2020-06-12T14:00",
      locationName: "MELBOURNE DT",
      locationCode: "MEL60",
      address1: "114 FLINDERS ST",
      address2: "",
      address3: "",
      city: "MELBOURNE, VIC",
      state: "VI",
      postCode: "3000",
      phone: "0396500245",
      latitude: -37.81619,
      longitude: 144.970864,
      franchiseType: "LCP",
      locationType: "LDT",
      commercial: true,
    },
    DropOff: {
      DateTime: "2020-06-13T14:00",
      locationName: "MELBOURNE DT",
      locationCode: "MEL60",
      address1: "114 FLINDERS ST",
      address2: "",
      address3: "",
      city: "MELBOURNE, VIC",
      state: "VI",
      postCode: "3000",
      phone: "0396500245",
      latitude: -37.81619,
      longitude: 144.970864,
      franchiseType: "LCP",
      locationType: "LDT",
      commercial: true,
    },
    Renter: {
      Title: "MR.",
      FirstName: "FIRSTNAME",
      LastName: "LASTNAME",
      LicenseNumber: "DU123456789",
      LicenseExpiry: "2030-01-24",
      LicenseIssuer: "NZ",
      Street: "STREET ADDRESS",
      StateOrProvCode: "VI",
      PostalCode: "3000",
      CountryCode: "AU",
      City: "MELBOURNE, VIC",
      Email: "EMAIL@DOMAIN.COM",
      HomeTelephoneNumber: "+(64)123456789",
      CellTelephoneNumber: "+(64)123456789",
    },
    AdditionalDriverNotes: {
      FirstName: "",
      LastName: "",
      Phone: "",
      Mobile: "",
    },
    Billing: {
      Total: 0,
      CurrencyCode: "AUD",
      Options: [
        {
          Code: "DROP",
          Quantity: 0,
          Description: "One Way Rental Fee",
          SubTotal: 0,
          Total: 0,
          Tax: 0,
          Included: false,
        },
        {
          Code: "LOC",
          Quantity: 1,
          Description: "Location Service Charge",
          SubTotal: 0,
          Total: 0,
          Tax: 0,
          Included: false,
        },
      ],
      TimeCharges: [
        {
          Quantity: 1,
          Description: "Tier 1 DAYS",
          RateExclTax: 0,
          SubTotal: 0,
          Total: 0,
          Tax: 0,
        },
      ],
      Distance: {
        RateExclTax: 0,
        RateInclTax: 0,
        Tax: 0,
        Included: 0,
        Unit: "kilometres",
        Unlimited: true,
      },
    },
  }
}

export const transformSchema = (data, context) => {
  _.merge(data, data.Insurance)
  _.merge(data, {
    Pickup: context.locations.filter((l) => l.locationCode == data.Pickup.Location.locationCode)[0],
    DropOff: context.locations.filter((l) => l.locationCode == data.DropOff.Location.locationCode)[0],
    VoucherClass: data.VoucherRate.Class,
  })

  _.merge(data.Renter, {
    LicenseNumber: data.Renter.DrivingLicense.Number,
    LicenseExpiry: data.Renter.DrivingLicense.ExpiryDate,
    LicenseIssuer: data.Renter.DrivingLicense.Issuer,
  })
  _.merge(data.Renter, data.Renter.Address)
  _.merge(data.Billing, data.Billing.Customer)

  delete data.Success
  delete data.ReferenceId
  delete data.Renter.Address
  delete data.Renter.DrivingLicense
  delete data.Insurance
  delete data.Billing.Customer
  delete data.Billing.Voucher
  delete data.Pickup.Location
  delete data.DropOff.Location
  delete data.VoucherRate
  
  const Distance = data.Billing.Distance === null || data.Billing.Distance === undefined ? {
    RateExclTax: 0,
    RateInclTax: 0,
    Tax: 0,
    Included: 0,
    Unit: "kilometres",
    Unlimited: false
  } : {
    ...data.Billing.Distance,
    RateExclTax: (Math.round(data.Billing.Distance.RateExclTax * 100) / 100).toFixed(2),
    RateInclTax: (Math.round(data.Billing.Distance.RateInclTax * 100) / 100).toFixed(2),
    Tax: (Math.round(data.Billing.Distance.Tax * 100) / 100).toFixed(2),
    Included: (Math.round(data.Billing.Distance.Included * 100) / 100).toFixed(2),
  }

  data.Billing = {
    ...data.Billing,
    Total: (Math.round(data.Billing.Total * 100) / 100).toFixed(2),
    Options: data.Billing.Options.map(option => ({
      ...option,
      SubTotal: (Math.round(option.SubTotal * 100) / 100).toFixed(2),
      Total: (Math.round(option.Total * 100) / 100).toFixed(2),
      Tax: (Math.round(option.Tax * 100) / 100).toFixed(2),
    })),
    TimeCharges: data.Billing.TimeCharges.map(timeCharge => ({
      ...timeCharge,
      RateExclTax: (Math.round(timeCharge.RateExclTax * 100) / 100).toFixed(2),
      SubTotal: (Math.round(timeCharge.SubTotal * 100) / 100).toFixed(2),
      Total: (Math.round(timeCharge.Total * 100) / 100).toFixed(2),
      Tax: (Math.round(timeCharge.Tax * 100) / 100).toFixed(2)
    })),
    Distance
  }

  return data
}
