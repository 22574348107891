import moment from "moment"

export default {
  app: {
    messages: [],
  },
  analytics: {
    loading: false,
    allOpenReservations: false,
    allOpenRentals: false,
    allOverdueRentals: false,
    reservationsCreated: [],
    reservationsPerAgent: [],
    rentalsStartPerStatus: [],
    rentalsOverdue: [],
  },
  auth: {
    token: "",
    username: "",
    roles: [],
    error: "",
    isFetching: false,
    expiry: "",
  },
  locationGroups: {
    isFetching: false,
    data: [],
  },
  vehicleGroups: {
    isFetching: false,
    data: [],
  },
  userGroups: {
    isFetching: false,
    data: [],
  },
  users: {
    isFetching: false,
    data: [],
  },
  changePassword: {
    isFetching: false,
    credentials: {
      oldPassword: "",
      newPassword: "",
      cPassword: ""
    },
    success: null
  },
  forgotPassword: {
    isFetching: false,
    success: null
  },
  resetPassword: {
    isFetching: false,
    data: {},
    errors: null,
    success: null
  },
  locations: {
    isFetching: false,
    locations: null,
    addressSuggestions: [],
  },
  serviceManagers: {
    isFetching: false,
    data: [],
  },
  bookings: {
    referenceId: "",
    confirmationNumber: "",
    reservationConfirmed: false,
    reservationNumber: "",
    reservationStatus: "",
    estimate: null,
    rates: [],
    reservations: [],
    vehicles: [],
    isFetching: false,
    isFetchingRates: false,
    isFetchingOptions: false,
    isFetchingEstimate: false,
    isCreatingReservation: false,
    isEditingReservation: false,
    isDownloadingEmailTemplate: false,
    modifyIndex: 1,
    failedExtensions: [],
    failedCancellations: [],
    successfulExtensions: [],
    bulkExtendFinished: true,
    errors: "",
    itinerary: null,
    originalItinerary: null,
  },
  quotation: {
    cleared: true,
    pickupLocation: "",
    dropoffLocation: "",
    pickupDate: moment().add(10, "days").format("DD-MM-YYYY"),
    pickupTime: moment()
      .add(30 - (moment().minute() % 30), "minutes")
      .format("HH:mm"),
    dropoffDate: moment().add(11, "days").format("DD-MM-YYYY"),
    dropoffTime: moment()
      .add(30 - (moment().minute() % 30), "minutes")
      .format("HH:mm"),
    age: "21",
    deliveryRequired: false,
    customerToReceive: "H",
    insurerChargedAs: "H",
    vehicleClass: "H",
    options: [],
    pickUpSearch: false,
    dropOffSearch: false,
    itcode: "ITHCOSUN",
    claimnumber: "",
    policynumber: "",
    customer: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      mobile: "",
      address: "",
      postcode: "",
      city: "",
      country: "AU",
      state: "",
      license: "",
      issuer: "AU",
      expiry: "",
      adfirstname: "",
      adlastname: "",
      adphone: "",
      admobile: "",
    },
  },
  userInfo: {
    isFetching: false,
    settings: {
      businessRules: {
        serviceName: "",
        itCodes: [],
      },
      serviceRules: {
        serviceName: "",
        itCodes: [],
        serviceUsers: [],
      },
    },
  },
  message: {
    title: null,
    text: null,
    success: false,
    show: false,
  },
}
