// export const AUTH_SERVER_URL = 'https://agatho-prod.hertz.asiapac.io/auth';
// export const API_SERVER_URL = 'https://agatho-prod.hertz.asiapac.io/api';

const authServerURLS = {
  dev: "https://agatho-supergraph-dev.hertz.asiapac.io/auth",
  staging: "https://agatho-supergraph-staging.hertz.asiapac.io/auth",
  prod: "https://agatho-supergraph-prod.hertz.asiapac.io/auth"
};

const apiServerURLs = {
  dev: "https://agatho-supergraph-dev.hertz.asiapac.io/api",
  staging: "https://agatho-supergraph-staging.hertz.asiapac.io/api",
  prod: "https://agatho-supergraph-prod.hertz.asiapac.io/api"
};

export const AUTH_SERVER_URL = authServerURLS[process.env.ENDPOINT];
export const API_SERVER_URL =apiServerURLs[process.env.ENDPOINT];
//replace with api url when finished
export const STATIC_JSON = "https://p6kgn84ca2.execute-api.ap-southeast-2.amazonaws.com/dev/auth";
