/* eslint-disable react/prop-types */
import React from "react"
import { Grid, makeStyles, Paper, Divider } from "@material-ui/core"
import { useSelector } from "react-redux"
import { HERTZ_YELLOW, BLUE } from "../../constants/style"

const EstimateCard = props => {
  const classes = useStyles()
  const reservation = useSelector(state => state.bookings.reservation)
  const bookings = useSelector(state => state.bookings)

  const getVehicleModelImage = bookings => {
    let vehicle = bookings.vehicles.find(e => {
      const eLength = e.code.length
      const maxLength = eLength - 3 >= 1 ? eLength - 3 : eLength 
      return e.code.substring(0, maxLength) === bookings.reservation.RateClass
    })

    return "https://images.hertz.com/vehicles/220x128/" + vehicle?.image
  }

  return (
    <div>
      <Grid container alignItems="stretch">
        <Grid
          item
          xs
          style={{
            alignText: "center"
          }}
        >
          <Paper className={classes.vehicleCard} style={{ height: "100%" }}>
            <div
              style={{
                background: props.type === "CUSTOMER" ? HERTZ_YELLOW : BLUE,
                width: "100%",
                height: "20px",
                position: "absolute",
                top: "0px",
                fontSize: "10px",
                lineHeight: "20px"
              }}
            >
              <strong>{props.prefix}</strong> {props.type.toUpperCase()} CHARGES
            </div>
            <img
              style={{
                marginTop: "5px",
                height: "60px"
              }}
              src={getVehicleModelImage(bookings)}
            />
            <div style={{ fontSize: "20px" }}>
              {props.type == "CUSTOMER" ? (
                <React.Fragment>
                  {props.prefix == "NEW" ? (
                    <React.Fragment>${bookings.estimate.Customer.Total}</React.Fragment>
                  ) : (
                    <React.Fragment>${bookings.reservation.Billing.Customer.Total}</React.Fragment>
                  )}
                </React.Fragment>
              ) : (
                <React.Fragment>
                  {props.prefix == "NEW" ? (
                    <React.Fragment>${bookings.estimate.Voucher.Total}</React.Fragment>
                  ) : (
                    <React.Fragment>${bookings.reservation.Billing.Voucher.Total}</React.Fragment>
                  )}
                </React.Fragment>
              )}
              {(props.type == "CUSTOMER" && props.prefix == "NEW" && bookings.estimate.Customer.Total - reservation.Billing.Customer.Total != 0) ||
              (props.type == "INSURER" && props.prefix == "NEW" && bookings.estimate.Voucher.Total - reservation.Billing.Voucher.Total != 0) ? (
                <span
                  style={{
                    color:
                      (props.type == "CUSTOMER" && bookings.estimate.Customer.Total < reservation.Billing.Customer.Total) ||
                      (props.type == "INSURER" && bookings.estimate.Voucher.Total < reservation.Billing.Voucher.Total)
                        ? "red"
                        : "green",
                    fontSize: 12
                  }}
                >
                  &nbsp;(
                  {(props.type == "CUSTOMER" && bookings.estimate.Customer.Total < reservation.Billing.Customer.Total) ||
                  (props.type == "INSURER" && bookings.estimate.Voucher.Total < reservation.Billing.Voucher.Total) ? (
                    <span>-</span>
                  ) : (
                    <span>+</span>
                  )}
                  {props.type == "CUSTOMER" ? (
                    <span>${(bookings.estimate.Customer.Total - reservation.Billing.Customer.Total).toFixed(2)})</span>
                  ) : (
                    <span>${(bookings.estimate.Voucher.Total - reservation.Billing.Voucher.Total).toFixed(2)})</span>
                  )}
                </span>
              ) : (
                <div />
              )}
            </div>
            <Divider variant="middle" style={{ margin: "0 0 5px 0" }} />
            {/* Charges */}
            <div style={{ padding: "0 10px" }}>
              <Grid container spacing={1} justify="center" style={{ fontSize: "10px" }}>
                <Grid item xs style={{ textAlign: "left" }}>
                  DESCRIPTION
                </Grid>
                <Grid item xs style={{ textAlign: "right" }}>
                  QTY
                </Grid>
                <Grid item xs style={{ textAlign: "right" }}>
                  SUBTOTAL
                </Grid>
              </Grid>
              {props.type == "CUSTOMER" &&
                props.prefix == "NEW" &&
                bookings.estimate.Customer.TimeCharges &&
                bookings.estimate.Customer.TimeCharges.map((tc, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      {tc.Description}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {tc.Quantity}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {tc.Total}
                    </Grid>
                  </Grid>
                ))}
              {props.type == "CUSTOMER" &&
                props.prefix == "OLD" &&
                bookings.reservation.Billing.Customer.TimeCharges &&
                bookings.reservation.Billing.Customer.TimeCharges.map((tc, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      {tc.Description}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {tc.Quantity}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {tc.Total}
                    </Grid>
                  </Grid>
                ))}
              {props.type == "INSURER" &&
                props.prefix == "NEW" &&
                bookings.estimate.Voucher.TimeCharges &&
                bookings.estimate.Voucher.TimeCharges.map((tc, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      {tc.Description}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {tc.Quantity}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {tc.Total}
                    </Grid>
                  </Grid>
                ))}
              {props.type == "INSURER" &&
                props.prefix == "OLD" &&
                bookings.reservation.Billing.Voucher.TimeCharges &&
                bookings.reservation.Billing.Voucher.TimeCharges.map((tc, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    <Grid item xs={6} style={{ textAlign: "left" }}>
                      {tc.Description}
                    </Grid>
                    <Grid item xs={2} style={{ textAlign: "right" }}>
                      {tc.Quantity}
                    </Grid>
                    <Grid item xs={4} style={{ textAlign: "right" }}>
                      {tc.Total}
                    </Grid>
                  </Grid>
                ))}
              {props.type == "CUSTOMER" &&
                props.prefix == "NEW" &&
                bookings.estimate.Customer.Options &&
                bookings.estimate.Customer.Options.map((o, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    {o.Quantity > 0 && (
                      <React.Fragment>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {o.Description}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          {o.Quantity}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {o.Total}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ))}
              {/* {props.type == "CUSTOMER" &&
                props.prefix == "OLD" &&
                bookings.reservation.Billing.Customer.Options &&
                bookings.reservation.Billing.Customer.Options.map((o, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    {o.Quantity > 0 && (
                      <React.Fragment>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {o.Description}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          {o.Quantity}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {o.Total}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ))} */}
              {props.type == "INSURER" &&
                props.prefix == "NEW" &&
                bookings.estimate.Voucher.Options &&
                bookings.estimate.Voucher.Options.map((o, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    {o.Quantity > 0 && (
                      <React.Fragment>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {o.Description}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          {o.Quantity}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {o.Total}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ))}
              {/* {props.type == "INSURER" &&
                props.prefix == "OLD" &&
                bookings.reservation.Billing.Voucher.Options &&
                bookings.reservation.Billing.Voucher.Options.map((o, i) => (
                  <Grid container spacing={1} justify="center" key={i} style={{ fontSize: "10px" }}>
                    {o.Quantity > 0 && (
                      <React.Fragment>
                        <Grid item xs={6} style={{ textAlign: "left" }}>
                          {o.Description}
                        </Grid>
                        <Grid item xs={2} style={{ textAlign: "right" }}>
                          {o.Quantity}
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: "right" }}>
                          {o.Total}
                        </Grid>
                      </React.Fragment>
                    )}
                  </Grid>
                ))} */}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden"
}

const useStyles = makeStyles(() => ({
  vehicleCard: cardWidget
}))

export default EstimateCard
