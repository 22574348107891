import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import TopBar from "../partials/TopBar"
import Grid from "@mui/material/Grid"
import * as analyticsActions from "../../actions/analyticsActions"
import DashboardCard from "../partials/DashboardCard"
import { push } from "connected-react-router"

const Dashboard = () => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => ({ ...state.auth }))
  const analytics = useSelector((state) => ({ ...state.analytics }))
  const base = location.pathname.split("/")[1]
  
  useEffect(() => {
    dispatch(analyticsActions.getReservationAnalytics({ token: auth.token }, "WEEK"))
  }, [])

  return (
    <React.Fragment>
      <TopBar />
      <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} style={{ height: "90vh" }}>
        <Grid item md={3} xs={12} sx={{ cursor: "pointer" }}>
          <DashboardCard value={analytics.allOpenReservations} cardHandler={() => dispatch(push({ pathname: `/${base}/bookings/manage/reservations` }))} title={"Open Reservations"} color="yellow" />
        </Grid>
        <Grid item md={3} xs={12}>
          <DashboardCard value={analytics.allOpenRentals} cardHandler={() => dispatch(push({ pathname: `/${base}/bookings/manage/rentals` }))} title={"Open Rentals"} color="blue" />
        </Grid>
        <Grid item md={3} xs={12}>
          <DashboardCard value={analytics.allOverdueRentals} cardHandler={() => dispatch(push({ pathname: `/${base}/bookings/manage/rentals`, search: '?overdue=true', }))} title={"Overdue Rentals"} color="purple" />
        </Grid>
      </Grid>
      
      {/*Reservations Created by day
       <ul>
        {analytics.reservationsCreated.map((value, index) => (
          <li key={index}>
            {value.date} {value.value}
          </li>
        ))}
      </ul>
      Reservations By Agent
      <ul>
        {analytics.reservationsPerAgent.map((value, index) => (
          <li key={index}>
            {value.date}
            {analytics.reservationsPerAgent[index].value.map((innerValue, innerIndex) => (
              <p key={innerIndex}>
                {innerValue.value} {innerValue.agent}
              </p>
            ))}
          </li>
        ))}
      </ul>
      Rentals Per Status
      <ul>
        {analytics.rentalsStartPerStatus.map((value, index) => (
          <li key={index}>
            {value.date}{" "}
            {analytics.rentalsStartPerStatus[index].value.map((innerValue, innerIndex) => (
              <p key={innerIndex}>
                {innerValue.value} {innerValue.status}
              </p>
            ))}
          </li>
        ))}
      </ul> */}
    </React.Fragment>
  )
}

export default Dashboard
