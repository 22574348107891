/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
import React, { Fragment } from "react"
import MaterialTable from "material-table"
import tableIcons from "../../utils/helper"
import { merge, codeSortHelper, metaConverter } from "../../utils/functions"

import { Card, Grid, Button, makeStyles, Modal, Fade, TextField } from "@material-ui/core"

import { useSelector, useDispatch } from "react-redux"
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"
import LockOutlinedIcon from "@material-ui/icons/LockOutlined"
import CheckOutlinedIcon from "@material-ui/icons/CheckOutlined"
import EditIcon from "@material-ui/icons/Edit"

import * as vehicleGroupActions from "../../actions/vehicleGroupActions"
import _ from "lodash"

const VehicleGroupSummary = (props) => {
  const dispatch = useDispatch()
  const auth = useSelector((state) => state.auth)
  const classes = useStyles()
  const userType = useSelector((state) => _.get(state, "userInfo.settings.user.userType", ""))
  const vehicleGroups = useSelector((state) => state.vehicleGroups.data)
  const allowed = metaConverter(props.selectedRow?.vehicleModels.allowed ?? [])
  const denied = metaConverter(props.selectedRow?.vehicleModels.denied ?? [])
  const isLoading = useSelector(state => _.get(state, "vehicleGroups.isFetching", false))
  const [selectedVehicle, setSelectedVehicle] = React.useState()
  const [open, setOpen] = React.useState(false)
  const [vehicleGroupName, setVehicleGroupName] = React.useState("")
  const [data, setData] = React.useState()

  React.useEffect(() => {
    setData(props.selectedRow
      ? [
          ...allowed.map((rowData) => ({
            ...rowData,
            tableData: {
              checked: true
            }
          })),
          ...denied.map((rowData) => ({
            ...rowData,
            tableData: {
              checked: false
            }
          }))
      ].sort(codeSortHelper)
      : [])
  }, [props.selectedRow])
  
  function updateVehicleModels() {
    const allowed = data.reduce((acc, e) => {
      if (e.tableData.checked === true) {        
        return acc.concat(e.carClass);        
      }
      return acc;      
    }, [])
    const group  = {
      ...props.vehicleGroups[props.selectedRow.tableData.id],
      vehicleModels: {
        allowed
      }
    }
    dispatch(vehicleGroupActions.setVehicleGroup({ token: auth.token }, group))
  }

  function checkRow(rowData) {
    if (rowData.length === 0) {
      setData(data.map(e => ({...e, tableData: {...e.tableData, checked: false}})))
    } else {
      setData(merge(data, rowData, (d, r) => d.code === r.code))
    }
  }

  function onDisableGroupButton() {
    const allowedCodes = allowed.map(e => e.carClass);

    const group = {
      _id: vehicleGroups[props.selectedRow.tableData.id]._id,
      name: vehicleGroups[props.selectedRow.tableData.id].name,
      vehicleModels: {
        allowed: allowedCodes,
        denied: ["*"],
      },
      active: false
    };

    dispatch(vehicleGroupActions.setVehicleGroup({ token: auth.token }, group))
  }

  function onEnableGroupButton() {
    const allowedCodes = allowed.map(e => e.carClass);

    const group = {
      _id: vehicleGroups[props.selectedRow.tableData.id]._id,
      name: vehicleGroups[props.selectedRow.tableData.id].name,
      vehicleModels: {
        allowed: allowedCodes,
        denied: ["*"],
      },
      active: true
    };
    dispatch(vehicleGroupActions.setVehicleGroup({ token: auth.token }, group))
  }

  function handleClose() {
    setOpen(false)
  }

  function buttonsDisabled() {
    if (vehicleGroups && vehicleGroups.length > 0) {
      return false
    }

    return true
  }

  function updateVehicleGroupName() {
    const allowed = data.reduce((acc, e) => {
      if (e.tableData.checked === true) {        
        return acc.concat(e.carClass);        
      }
      return acc;      
    }, [])

    let group = {
      _id: vehicleGroups[props.selectedRow.tableData.id]._id,
      name: vehicleGroupName,
      vehicleModels: {
        allowed
      },
      active: vehicleGroups[props.selectedRow.tableData.id].active,
    }

    dispatch(vehicleGroupActions.setVehicleGroup({ token: auth.token }, group))
    setOpen(false)
    setVehicleGroupName("")
  }

  return (
    <Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2 id="transition-modal-title">Change IT Group Name</h2>
            <p id="transition-modal-description">
              <TextField
                onChange={(e) => setVehicleGroupName(e.target.value)}
                value={vehicleGroupName}
                label={"It Group Name"}
                variant="outlined"
                margin="dense"
                fullWidth
              ></TextField>
            </p>
            <div style={{ textAlign: "center" }}>
              <Button
                variant="contained"
                size="medium"
                color="secondary"
                style={{ margin: "5px" }}
                onClick={updateVehicleGroupName}
              >
                Change
              </Button>
              <Button variant="contained" size="medium" color="primary" onClick={handleClose} style={{ margin: "5px" }}>
                Cancel
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
      <Grid item xs={7} lg={5}>
        <Card className={classes.summaryCardActive}>
          {props.vehicleGroups && props.vehicleGroups && props.selectedRow && vehicleGroups && vehicleGroups.length > 0 && (
            <MaterialTable
              icons={tableIcons}
              title="Vehicle Models"
              isLoading={isLoading}
              columns={[
                {
                  title: "Class",
                  field: "code"
                },
                {
                  title: "Acriss",
                  field: "acriss",
                  render: (rowData) => rowData.acriss ?? "-"
                },
                {
                  title: "Description",
                  field: "name"
                },
                {
                  title: "Country",
                  field: "country",
                  render: (rowData) => rowData.country ?? "-"
                },
                {
                  title: "carClass",
                  field: "carClass",
                  hidden: true
                },
                // { title: "active", field: "active" }
                // { title: "ID", field: "id", hidden: "true" }
                /* { title: 'Allowed', field: 'allowed' , type: 'boolean'}, */
              ]}
              options={{
                minBodyHeight: "calc(100vh - 224px)",
                maxBodyHeight: "calc(100vh - 224px)",
                pageSize: 300,
                paging: true,
                paginationType: "normal",
                tableLayout: "auto",
                rowStyle: (rowData) => ({
                  backgroundColor:
                    selectedVehicle && selectedVehicle.tableData.id === rowData.tableData.id ? "#EEE" : "#FFF",
                }),
                selection: true,
              }}
              data={data ? data : []}
              onRowClick={(event, rowData) => {
                setSelectedVehicle(rowData)
              }}
              onSelectionChange={(rowData) => {
                checkRow(rowData)
              }}
            />
          )}
        </Card>
        <Grid container={true} direction="row" justifyContent="flex-start" alignItems="flex-start" style={{ marginTop: 10 }}>
          <Card style={{ width: "100%", height: "100%" }} className={classes.actionButtonsCard}>
            <Grid container={true}  direction="row" justifyContent="space-between" alignItems="center">
              {props.selectedRow &&
                (userType == "SERVICE_MANAGER" || userType == "ADMIN") &&
                vehicleGroups[props.selectedRow.tableData.id] &&
                vehicleGroups[props.selectedRow.tableData.id].active && (
                  <Grid item xs={4} style={{ textAlign: "left", paddingLeft: 15 }}>
                    {props.selectedRow && (
                      <Button color="primary" onClick={onDisableGroupButton}>
                        <LockOutlinedIcon
                          style={{
                            marginRight: 5,
                            fontSize: 20,
                            marginBottom: 2,
                          }}
                        ></LockOutlinedIcon>
                        Disable Vehicle Group
                      </Button>
                    )}
                  </Grid>
                )}
              {props.selectedRow &&
                (userType == "SERVICE_MANAGER" || userType == "ADMIN") &&
                vehicleGroups[props.selectedRow.tableData.id] &&
                !vehicleGroups[props.selectedRow.tableData.id].active && (
                  <Grid item xs={6} style={{ textAlign: "left", paddingLeft: 15 }}>
                    {props.selectedRow && (
                      <Button color="primary" onClick={onEnableGroupButton}>
                        <CheckOutlinedIcon
                          style={{
                            marginRight: 5,
                            fontSize: 20,
                            marginBottom: 2,
                          }}
                        ></CheckOutlinedIcon>
                        Enable Vehicle Group
                      </Button>
                    )}
                  </Grid>
                )}
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {props.selectedRow && (userType == "SERVICE_MANAGER" || userType == "ADMIN") && (
                  <Button color="primary" onClick={() => setOpen(true)} disabled={buttonsDisabled()}>
                    <EditIcon
                      style={{
                        marginRight: 10,
                        fontSize: 20,
                        marginBottom: 2,
                      }}
                    ></EditIcon>
                    EDIT GROUP NAME
                  </Button>
                )}
              </Grid>
              <Grid item xs={4} style={{ paddingRight: 15 }}>
                {props.selectedRow && (userType == "SERVICE_MANAGER" || userType == "ADMIN") && (
                  <Button color="primary" onClick={updateVehicleModels} disabled={buttonsDisabled()}>
                    Update Vehicles
                    <ArrowForwardIosIcon style={{ marginLeft: 5, fontSize: 20, marginBottom: 2 }}></ArrowForwardIosIcon>
                  </Button>
                )}
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Fragment>
  )
}

const cardWidget = {
  textAlign: "center",
  height: "100%",
  width: "100%",
  padding: "20px 0 10px 0",
  position: "relative",
  overflow: "hidden",
}

const useStyles = makeStyles((theme) => ({
  root: {
    "& button.MuiButtonBase-root": {
      padding: "5px",
    },
  },
  actionButtonsCard: {
    paddingTop: 7,
    minHeight: 51,
    height: "100%",
    width: "100%",
    textAlign: "right",
  },
  summaryCard: {
    height: "calc(100vh - 106px)",
  },
  summaryCardActive: {
    height: "calc(100vh - 168px)",
  },
  vehicleCard: cardWidget,
  progress: {
    margin: theme.spacing(2),
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "none",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    minWidth: "25%",
    minHeight: "25%",
  },
  customerDetails: {
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: "none",
  },
  infoCard: {
    ...cardWidget,
    padding: "10px 0 10px 0",
    "& .MuiCardContent-root": {
      paddingTop: "0",
      paddingBottom: "0",
    },
    "& .MuiTextField-root": {
      margin: "0px",
    },
    "& input": {
      fontSize: "14px",
      color: "#000",
      padding: "5px 0",
    },
    "& .MuiInputBase-root": {
      marginTop: "12px",
    },
    "& label.Mui-disabled": {
      fontSize: "13px",
      color: "#B9BBBE",
    },
  },
}))

export default VehicleGroupSummary
